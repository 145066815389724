import { useState, useEffect, useContext, useMemo } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import {
  Box,
  Typography,
  useTheme,
  Dialog,
  DialogContent,
  DialogTitle,
  DialogContentText,
  DialogActions,
  Grid,
  Fade,
  Alert,
  AlertTitle,
} from "@mui/material";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import { tokens } from "../../theme";
import Header from "../../components/Header";
import axios from "axios";
import LoadingScreen from "../global/LoadingScreen";
import { AuthContext } from "../../context/authContext";
import { useTranslation } from "react-i18next";

const CompareScenario = () => {
  const { t } = useTranslation();
  const { idSimulation } = useParams();
  const [isLoading, setIsLoading] = useState(false);
  const [isDone, setIsDone] = useState(false);
  const [defaults, setDefaults] = useState(null);
  const [project, setProject] = useState([]);
  const [generalError, setGeneralError] = useState(false);
  const [sessionError, setSessionError] = useState(false);
  const [numberHoterway, setNumberHoterway] = useState(null);
  const [comparisonObject, setComparisonObject] = useState({});
  const [numberRecirculation, setNumberRecirculation] = useState(null);
  const [waitingTime, setWaitingTime] = useState("0");
  const [waitingTimeValue, setWaitingTimeValue] = useState(0);
  let general_wasted_money = 0;
  let number_hoterway = 0;
  let number_recirculation = 0;
  let wait_times = 0;
  let index = 0;
  let connections_data = {};
  let divs_data = {};
  let table_data = {};

  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  const navigate = useNavigate();

  const { currentUser, verifyUser, logout } = useContext(AuthContext);
  const [currentLicense, setCurrentLicense] = useState(null);

  const config = {
    headers: {
      "access-token": process.env.REACT_APP_ACCESS_TOKEN,
      "Content-Type": "application/json", // Adjust the content type if needed
    },
    withCredentials: true,
  };

  const verifyLicense = async () => {
    try {
      const response = await axios.get(process.env.REACT_APP_API_CALL + `licenses/email/${currentUser?.email}`, config);

      if (response.data.success === 0) {
        setCurrentLicense(0);
      } else if (response.data.data[0].time === 0) {
        setCurrentLicense(1);
      } else if (response.data.data[0].active === 1) {
        setCurrentLicense(2);
      }
    } catch (error) {
      if (error.response.data.auth === false) {
        setSessionError(true);
      } else {
        setGeneralError(true);
        console.log(error);
        logout();
      }
    }
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    verifyUser(currentUser);
    if (!currentUser) {
      navigate("/login");
    } else {
      verifyLicense();
      if (currentLicense !== null && currentLicense !== 2) {
        navigate("/login");
      }
      // else if (currentUser.user_type === 1 || currentUser.user_type === 2 || currentUser.user_type === 3) {
      //   navigate("/");
      // }
    }
    getProject();
    getDefaults();
    // calculateDivsAttributes();
    // calculateConnectionsAttributes();
  }, [currentLicense, currentUser]);

  const getDefaults = async () => {
    try {
      const response = await axios.get(process.env.REACT_APP_API_CALL + "defaults", config).then();
      setDefaults(response.data.data[0].default_values);
    } catch (error) {
      if (error.response.data.auth === false) {
        setSessionError(true);
      } else {
        setGeneralError(true);
        console.log(error);
      }
    }
  };

  const getProject = async () => {
    try {
      const response = await axios.get(process.env.REACT_APP_API_CALL + `scenario/simulation/${idSimulation}`, config);
      setProject(response.data.data);
    } catch (error) {
      if (error.response.data.auth === false) {
        setSessionError(true);
      } else {
        setGeneralError(true);
        console.log(error);
      }
    }
  };

  function calculatePerdaTermica(insolation_width, tubo_stats, cct, ccppt, tubo_width, running_time) {
    switch (insolation_width) {
      case 0:
        return (tubo_stats.perda_termica_1 * cct * ccppt * tubo_width * running_time) / 24;
      case 1:
        return (tubo_stats.perda_termica_2 * cct * ccppt * tubo_width * running_time) / 24;
      case 2:
        return (tubo_stats.perda_termica_3 * cct * ccppt * tubo_width * running_time) / 24;
      default:
        return parseFloat((tubo_stats.perda_termica * tubo_width).toFixed(2));
    }
  }

  const getChildren = (connectionStart, data) => {
    if (!project || !data?.pepc_connections) return "cenas";
    var firstString = JSON.stringify(data?.pepc_connections);
    var secondString = firstString
      .replaceAll("\\", "")
      .replaceAll("'{", "{")
      .replaceAll("}'", "}")
      .replaceAll('"{', "{")
      .replaceAll('}"', "}")
      .replaceAll('"true"', "true")
      .replaceAll('"false"', "false");
    var newArray1 = JSON.parse(secondString);
    let res = [];
    if (connectionStart in newArray1)
      for (let connectionEnd of Object.keys(newArray1[connectionStart])) {
        const children = getChildren(connectionEnd, data);
        res = [...res, ...children];
      }
    else return [connectionStart];
    return res;
  };

  const getDivTotalCaudal = (children, data) => {
    if (!project || !data.pepc_layout) return null;
    var firstString = JSON.stringify(data?.pepc_layout);
    var secondString = firstString
      .replaceAll("\\", "")
      .replaceAll("'{", "{")
      .replaceAll("}'", "}")
      .replaceAll('"{', "{")
      .replaceAll('}"', "}")
      .replaceAll('"true"', "true")
      .replaceAll('"false"', "false");
    var newArray1 = JSON.parse(secondString);
    const div_key = Object.keys(newArray1).find(
      (key) => (newArray1[key]["nickname"] ? newArray1[key]["nickname"] : newArray1[key]["name"]) === children
    );
    let res = 0;
    if (div_key) {
      if (isNaN(parseFloat(newArray1[div_key]["caudal_total"]))) console.log("error:" + newArray1[div_key]["caudal_total"]);
      else res += parseFloat(newArray1[div_key]["caudal_total"]);
    }

    return res;
  };

  const getAdjustedDiameter = (caudal, tipo_tubo) => {
    if (defaults && tipo_tubo in defaults?.tipos_tubagem) {
      for (let stats of defaults?.tipos_tubagem[tipo_tubo]) {
        if (stats.max_caudal >= caudal) return stats;
      }
    }
    return null;
  };

  const getTubeStatsByDiamenter = (diametro, tipo_tubo) => {
    if (defaults && tipo_tubo in defaults?.tipos_tubagem) {
      for (let stats of defaults?.tipos_tubagem[tipo_tubo]) {
        if (stats.diametro === diametro) return stats;
      }
    }
    return null;
  };

  const findPathToDiv = (start, end, data) => {
    if (!project || !data.pepc_connections) return [];
    var firstString = JSON.stringify(data?.pepc_connections);
    var secondString = firstString
      .replaceAll("\\", "")
      .replaceAll("'{", "{")
      .replaceAll("}'", "}")
      .replaceAll('"{', "{")
      .replaceAll('}"', "}")
      .replaceAll('"true"', "true")
      .replaceAll('"false"', "false");
    var newArray1 = JSON.parse(secondString);

    let res = [];
    if (!(start in newArray1)) return [];
    if (end in newArray1[start]) return [[start, end]];
    for (let next_point of Object.keys(newArray1[start])) {
      const path_found = findPathToDiv(next_point, end, data);
      if (path_found.length !== 0) {
        res.push([start, next_point]);
        res = [...res, ...path_found];
      }
    }
    return res;
  };

  const getDivMinCaudal = (children, data) => {
    if (!project || !data.pepc_layout) return null;
    var firstString = JSON.stringify(data?.pepc_layout);
    var secondString = firstString
      .replaceAll("\\", "")
      .replaceAll("'{", "{")
      .replaceAll("}'", "}")
      .replaceAll('"{', "{")
      .replaceAll('}"', "}")
      .replaceAll('"true"', "true")
      .replaceAll('"false"', "false");
    var newArray1 = JSON.parse(secondString);
    const div_key = Object.keys(newArray1).find(
      (key) => (newArray1[key]["nickname"] ? newArray1[key]["nickname"] : newArray1[key]["name"]) === children
    );
    let res = 100000;
    if (div_key) {
      res = newArray1[div_key]["caudal_minimo"];
    }
    return res;
  };

  const calculateConnectionsAttributes = (data) => {
    if (!project || !data.pepc_connections) return [];
    var firstString = JSON.stringify(data?.pepc_connections);
    var secondString = firstString
      .replaceAll("\\", "")
      .replaceAll("'{", "{")
      .replaceAll("}'", "}")
      .replaceAll('"{', "{")
      .replaceAll('}"', "}")
      .replaceAll('"true"', "true")
      .replaceAll('"false"', "false");
    var newArray1 = JSON.parse(secondString);

    const res = [];

    let agua_soma = 0;

    for (let connectionStart of Object.keys(newArray1)) {
      for (let connectionEnd of Object.keys(newArray1[connectionStart])) {
        const connectionEndChildren = getChildren(connectionEnd, data);
        const current_res = {
          ligacao: "",
          comprimento: 0,
          coef_simultaniedade: 0,
          caudal: 0,
          diametro_ajustado: 0,
          agua_estagnada: 0,
          perda_termica: 0,
          recirculacao: "",
        };
        current_res.ligacao = connectionStart + " -> " + connectionEnd;
        current_res.coef_simultaniedade = 1 - 0.1 * (connectionEndChildren.length - 1);
        if (current_res.coef_simultaniedade < 0.4) current_res.coef_simultaniedade = 0.4;
        let caudal_total = 0;
        for (let child of connectionEndChildren) {
          caudal_total += getDivTotalCaudal(child, data);
        }
        current_res.caudal = current_res.coef_simultaniedade * caudal_total;
        let tubo_stats = getAdjustedDiameter(current_res.caudal, data.tipo_tubo);
        let tubo_width = 0;
        if (typeof newArray1[connectionStart][connectionEnd] === "number") {
          tubo_width = newArray1[connectionStart][connectionEnd];
          current_res.diametro_ajustado = tubo_stats.diametro;
        } else {
          current_res.diametro_ajustado = newArray1[connectionStart][connectionEnd].adjustedDiameter;
          tubo_stats = getTubeStatsByDiamenter(current_res.diametro_ajustado, data.tipo_tubo);
          tubo_width = newArray1[connectionStart][connectionEnd].width;
        }

        current_res.comprimento = tubo_width;

        if (tubo_stats) {
          if (newArray1[connectionStart][connectionEnd].recirculacao === 0) {
            current_res.agua_estagnada = parseFloat((tubo_stats.agua_estagnada * tubo_width).toFixed(2));

            number_recirculation += 1;
            setNumberRecirculation(number_recirculation);

            if (numberHoterway === null) {
              current_res.perda_termica = 0;
            } else {
              current_res.perda_termica = calculatePerdaTermica(
                data?.insolation_width,
                tubo_stats,
                data?.cct,
                data?.ccppt,
                tubo_width,
                data?.running_time_hoterway
              );
            }

            current_res.recirculacao = "Não";
          } else if (newArray1[connectionStart][connectionEnd].recirculacao === 1) {
            current_res.perda_termica = calculatePerdaTermica(
              data?.insolation_width,
              tubo_stats,
              data?.cct,
              data?.ccppt,
              tubo_width,
              data?.running_time
            );

            current_res.recirculacao = "Sim";
          } else {
            current_res.recirculacao = "Erro";
          }
          if (tubo_width === 0) {
            current_res.comprimento = 0;
            current_res.coef_simultaniedade = 1;
            current_res.caudal = 0;
            current_res.diametro_ajustado = 0;
            current_res.agua_estagnada = 0;
            current_res.perda_termica = 0;
            current_res.recirculacao = "";
          }

          res.push(current_res);

          // setComparisonObject((prevObject) => ({
          //   ...prevObject,
          //   [data.id]: {
          //     ...prevObject[data.id],
          //     connections_data: current_res,
          //   },
          // }));
          // // setComparisonObject({
          // //   [data.id]: {
          // //     connections_data: current_res,
          // //   },
          // // });
          // let object = {
          //   [data.id]: {
          //     connections_data: current_res,
          //   },
          // };

          if (!connections_data[data.id]) {
            connections_data[data.id] = {};
          }

          connections_data[data.id].connections_data = res;
        }
      }
    }

    // return res;
  };

  const calculateDivsAttributes = (data) => {
    if (!project || !data.pepc_layout || !data.pepc_layout2) return [];

    number_hoterway = 0;

    var firstString = JSON.stringify(data?.pepc_layout);
    var secondString = firstString
      .replaceAll("\\", "")
      .replaceAll("'{", "{")
      .replaceAll("}'", "}")
      .replaceAll('"{', "{")
      .replaceAll('}"', "}")
      .replaceAll('"true"', "true")
      .replaceAll('"false"', "false");
    var newLayout = JSON.parse(secondString);

    var thirdString = JSON.stringify(data?.pepc_layout2);
    var forthString = thirdString
      .replaceAll("\\", "")
      .replaceAll("'{", "{")
      .replaceAll("}'", "}")
      .replaceAll('"{', "{")
      .replaceAll('}"', "}")
      .replaceAll('"true"', "true")
      .replaceAll('"false"', "false");
    var newLayout2 = JSON.parse(forthString);

    const res = [];

    for (let div of Object.keys(newLayout)) {
      const pathToDiv = findPathToDiv("T0", newLayout[div]["nickname"] ? newLayout[div]["nickname"] : newLayout[div]["name"], data);

      let agua_estagnada = 0;
      let none_div = false;
      for (let connection_to_div of pathToDiv) {
        for (let connection of connections_data[data.id].connections_data) {
          if (connection.ligacao === connection_to_div[0] + " -> " + connection_to_div[1]) {
            agua_estagnada += connection.agua_estagnada;
            if (connection.comprimento === 0) none_div = true;
          }
        }
      }
      if (none_div) agua_estagnada = 0;
      const div_min_pc = getDivMinCaudal(newLayout[div]["nickname"] ? newLayout[div]["nickname"] : newLayout[div]["name"], data);

      if (newLayout2[div]["hoterway"] === "1" || newLayout2[div]["hoterway"] === 1) {
        number_hoterway += 1;
        setNumberHoterway(number_hoterway);
        agua_estagnada = Math.max(agua_estagnada - 10, 0) + data?.volume_aqs;
      } else {
        agua_estagnada = agua_estagnada + data?.volume_aqs;
      }

      const wait_time = agua_estagnada / div_min_pc;
      setWaitingTimeValue(wait_time);

      if (index === 0) {
        index = data.id;
      }

      if (index !== data.id) {
        wait_times = 0;
        // table_data[index].number_hoterway = number_hoterway;
        // number_hoterway = 0;
        index = data.id;
      }

      if (wait_times < wait_time) {
        wait_times = wait_time;
      }

      let wait_time_minutes = "" + Math.floor(wait_times);
      let wait_time_seconds = "" + Math.round((wait_times - parseInt(wait_time_minutes)) * 60);
      if (parseInt(wait_time_minutes) < 10) wait_time_minutes = "0" + wait_time_minutes;
      if (parseInt(wait_time_seconds) < 10) wait_time_seconds = "0" + wait_time_seconds;

      let max_wait_time_minutes = "" + Math.floor(wait_times);
      let max_wait_time_seconds = "" + Math.round((wait_times - parseInt(max_wait_time_minutes)) * 60);
      if (parseInt(max_wait_time_minutes) < 10) max_wait_time_minutes = "0" + max_wait_time_minutes;
      if (parseInt(max_wait_time_seconds) < 10) max_wait_time_seconds = "0" + max_wait_time_seconds;
      setWaitingTime(max_wait_time_minutes + ":" + max_wait_time_seconds);

      if (!table_data[data.id]) {
        table_data[data.id] = {};
      }

      table_data[data.id].waiting_time = max_wait_time_minutes + ":" + max_wait_time_seconds;
      table_data[data.id].number_hoterway = number_hoterway;

      const current_res = {
        nome: newLayout[div]["name"],
        nickname: newLayout[div]["nickname"] || newLayout[div]["name"],
        agua_estagnada: agua_estagnada,
        espera: wait_time_minutes + ":" + wait_time_seconds,
        hoterway: newLayout2[div]["hoterway"] === "1" ? "Sim" : "Não",
      };

      res.push(current_res);

      // setComparisonObject((prevObject) => ({
      //   ...prevObject,
      //   [data.id]: {
      //     ...prevObject[data.id],
      //     divs_data: current_res,
      //   },
      // }));

      if (!divs_data[data.id]) {
        divs_data[data.id] = {};
      }

      divs_data[data.id].divs_data = res;
      table_data[data.id].number_hoterway = number_hoterway;

      //ALTERAR COM DEFAULTS

      if (number_hoterway > 0) {
        table_data[data.id].hoterway_investment = parseFloat(number_hoterway * defaults?.preco_bateria + 330)
          .toFixed(2)
          .toLocaleString()
          .replace(".", ",")
          .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1.");

        // retorno investimento = investimento hoterway / (maior custo energético dos cenários - custo energetico do cenário da linha)
      } else {
        table_data[data.id].hoterway_investment = "N.A";

        // retorno investimento = investimento hoterway / (maior custo energético dos cenários - custo energetico do cenário da linha)
      }
    }
    // return res;
  };

  const getWastes = (data) => {
    let perda_termica_total = 0;
    let comprimento_total = 0;
    let total_agua_estagnada = 0;
    let water_cost = 0;
    let total = 0;
    let consumo_bomba;
    let consumo_bomba_money;
    let formattedConsumoBombaMoney;
    let perda_termica_parcial;
    let perda_termica_money;
    let formattedPerdaTermicaMoney;
    let water_waste = 0;
    let energy_waste;
    // let energy_waste_percentage = 0;
    let money_energy;
    let formattedMoneyEnergy;
    // let tempo_retorno = 0;

    //ÁGUA
    for (let connectionData of divs_data[data.id].divs_data) {
      total_agua_estagnada += connectionData.agua_estagnada;
    }

    total = (total_agua_estagnada * data.total_habitants * data.pe_usage * 365) / 1000;

    if (!table_data[data.id]) {
      table_data[data.id] = {};
    }

    table_data[data.id].wasted_water = total.toFixed(1);

    if (data?.water_price === undefined || data.water_price === null) {
      water_cost = (4.25 * total).toFixed(2);
    } else {
      water_cost = (data.water_price * total).toFixed(2);
    }

    table_data[data.id].wasted_water_money = water_cost;

    //ENERGIA

    for (let connection of connections_data[data.id].connections_data) {
      perda_termica_total += connection.perda_termica;
      comprimento_total += connection.comprimento;
    }

    const energy_bomba_recirculacao = 0.544 * data.average_power * perda_termica_total * 24 * 365 * 10 ** -6;

    consumo_bomba = (energy_bomba_recirculacao * data?.running_time) / 24;

    let min_perda_termica = 1000;

    if (data?.insolation_width === 0) {
      for (let tube_size of defaults.tipos_tubagem[data?.tipo_tubo])
        if (tube_size.perda_termica_1 < min_perda_termica) min_perda_termica = tube_size.perda_termica_1;
    } else if (data?.insolation_width === 1) {
      for (let tube_size of defaults.tipos_tubagem[data?.tipo_tubo])
        if (tube_size.perda_termica_2 < min_perda_termica) min_perda_termica = tube_size.perda_termica_2;
    } else {
      for (let tube_size of defaults.tipos_tubagem[data?.tipo_tubo])
        if (tube_size.perda_termica_3 < min_perda_termica) min_perda_termica = tube_size.perda_termica_3;
    }

    perda_termica_parcial = (perda_termica_total * 1.7).toFixed(1);
    perda_termica_money = (perda_termica_parcial * data.price_thermal * 1000).toFixed(2);
    formattedPerdaTermicaMoney = perda_termica_money.replace(".", ",").replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1.");

    energy_waste = (parseFloat(perda_termica_parcial) + parseFloat(consumo_bomba)).toFixed(2);

    consumo_bomba_money = (consumo_bomba * data.price_energy * 1000).toFixed(2);

    money_energy = (parseFloat(perda_termica_money) + parseFloat(consumo_bomba_money)).toFixed(2).toLocaleString();
    formattedMoneyEnergy = money_energy.replace(".", ",").replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1.");

    table_data[data.id].wasted_energy = energy_waste;
    table_data[data.id].wasted_energy_money = money_energy;

    if (parseFloat(general_wasted_money) < parseFloat(money_energy)) {
      general_wasted_money = money_energy;
    }

    //ENERGIA
  };

  function convertToYearsAndMonths(totalValue) {
    const valueWithDot = totalValue.replace(",", "."); // Replace comma with dot for parsing
    const numericValue = parseFloat(valueWithDot);
    const years = Math.floor(numericValue);
    const months = Math.round((numericValue - years) * 12);
    return { years, months };
  }

  const getReturnInvestment = (data) => {
    if (!table_data[data.id]) {
      table_data[data.id] = {};
    }

    if (table_data[data.id].number_hoterway > 0) {
      table_data[data.id].return_investment = parseFloat(
        (table_data[data.id].number_hoterway * defaults?.preco_bateria + 330) /
          (general_wasted_money - table_data[data.id].wasted_energy_money)
      )
        .toFixed(2)
        .toLocaleString()
        .replace(".", ",")
        .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1.");

      // retorno investimento = investimento hoterway / (maior custo energético dos cenários - custo energetico do cenário da linha)
    } else {
      table_data[data.id].return_investment = "N.A";

      // retorno investimento = investimento hoterway / (maior custo energético dos cenários - custo energetico do cenário da linha)
    }
  };

  useEffect(() => {
    setIsLoading(true);
    for (let i = 0; i < project?.length; i++) {
      calculateConnectionsAttributes(project[i]);
    }
    if (project?.length === Object.keys(connections_data).length) {
      for (let i = 0; i < project?.length; i++) {
        calculateDivsAttributes(project[i]);
      }
    }
    if (project?.length === Object.keys(divs_data).length) {
      for (let i = 0; i < project?.length; i++) {
        getWastes(project[i]);
      }
    }
    if (project?.length === Object.keys(divs_data).length) {
      for (let i = 0; i < project?.length; i++) {
        getReturnInvestment(project[i]);
      }
    }
    if (Object.keys(table_data).length === Object.keys(divs_data).length) {
      setComparisonObject(table_data);
    }
    setIsLoading(false);
  }, [project]);

  var columns;

  if (project?.building_type === 0) {
    columns = [
      {
        field: "id",
        headerName: t("compare_scenarios.table_fields.id"),
        minWidth: 70,
        maxWidth: 80,
        flex: 1,
      },
      {
        field: "scenario_name",
        headerName: t("compare_scenarios.table_fields.scenario_name"),
        minWidth: 200,
        maxWidth: 280,
        flex: 1,
      },
      {
        field: "waiting_time",
        headerName: t("compare_scenarios.table_fields.waiting_time"),
        minWidth: 100,
        maxWidth: 150,
        flex: 1,
        renderCell: (params) => {
          return comparisonObject[params.row.id]?.waiting_time;
        },
      },
      {
        field: "wasted_water",
        headerName: t("compare_scenarios.table_fields.wasted_water"),
        minWidth: 170,
        maxWidth: 200,
        flex: 1,
        renderCell: (params) => {
          return comparisonObject[params.row.id]?.wasted_water;
        },
      },
      {
        field: "wasted_water_money",
        headerName: t("compare_scenarios.table_fields.wasted_water_money"),
        minWidth: 160,
        maxWidth: 200,
        flex: 1,
        renderCell: (params) => {
          return comparisonObject[params.row.id]?.wasted_water_money;
        },
      },
      {
        field: "wasted_energy",
        headerName: t("compare_scenarios.table_fields.wasted_energy"),
        minWidth: 190,
        maxWidth: 200,
        flex: 1,
        renderCell: (params) => {
          return comparisonObject[params.row.id]?.wasted_energy;
        },
      },
      {
        field: "wasted_energy_money",
        headerName: t("compare_scenarios.table_fields.wasted_energy_money"),
        minWidth: 170,
        maxWidth: 200,
        flex: 1,
        renderCell: (params) => {
          return comparisonObject[params.row.id]?.wasted_energy_money.replace(".", ",").replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1.");
        },
      },
      {
        field: "number_hoterway",
        headerName: t("compare_scenarios.table_fields.number_hoterway"),
        minWidth: 170,
        maxWidth: 200,
        flex: 1,
        renderCell: (params) => {
          return comparisonObject[params.row.id]?.number_hoterway;
        },
      },
      {
        field: "hoterway_investment",
        headerName: t("compare_scenarios.table_fields.hoterway_investment"),
        minWidth: 150,
        maxWidth: 200,
        flex: 1,
        renderCell: (params) => {
          return comparisonObject[params.row.id]?.hoterway_investment;
        },
      },
      {
        field: "return_investment",
        headerName: t("compare_scenarios.table_fields.return_investment.title"),
        minWidth: 165,
        maxWidth: 200,
        flex: 1,
        renderCell: (params) => {
          if (comparisonObject[params.row.id]?.return_investment === undefined) {
            return;
          }
          if (
            comparisonObject[params.row.id]?.return_investment == Number.POSITIVE_INFINITY ||
            comparisonObject[params.row.id]?.return_investment == Number.NEGATIVE_INFINITY ||
            comparisonObject[params.row.id]?.return_investment == "N.A"
          ) {
            return "N.A";
          } else {
            const valueWithDot = comparisonObject[params.row.id]?.return_investment.replace(",", "."); // Replace comma with dot for parsing
            const numericValue = parseFloat(valueWithDot);
            const years = Math.floor(numericValue);
            const months = Math.round((numericValue - years) * 12);
            // const { years, months } = convertToYearsAndMonths(comparisonObject[params.row.id]?.return_investment);
            if (years === 1 && months === 1) {
              return (
                years +
                ` ${t("compare_scenarios.table_fields.return_investment.year_and_singular")} ` +
                months +
                ` ${t("compare_scenarios.table_fields.month_singular")}`
              );
            } else if (years !== 1 && months === 1) {
              return years + ` ${t("compare_scenarios.table_fields.return_investment.year_and_plural")} ` + months + ` ${t("compare_scenarios.table_fields.month_singular")}`;
            } else if (years === 1 && months !== 1) {
              return (
                years +
                ` ${t("compare_scenarios.table_fields.return_investment.year_and_singular")} ` +
                months +
                ` ${t("compare_scenarios.table_fields.month_plural")}`
              );
            } else {
              return years + ` ${t("compare_scenarios.table_fields.return_investment.year_and_plural")} ` + months + ` ${t("compare_scenarios.table_fields.month_plural")}`;
            }
          }
        },
      },
    ];
  } else {
    columns = [
      {
        field: "id",
        headerName: t("compare_scenarios.table_fields.id"),
        minWidth: 70,
        maxWidth: 80,
        flex: 1,
      },
      {
        field: "scenario_name",
        headerName: t("compare_scenarios.table_fields.scenario_name"),
        minWidth: 200,
        maxWidth: 280,
        flex: 1,
      },
      {
        field: "waiting_time",
        headerName: t("compare_scenarios.table_fields.waiting_time"),
        minWidth: 100,
        maxWidth: 150,
        flex: 1,
        renderCell: (params) => {
          return comparisonObject[params.row.id]?.waiting_time;
        },
      },
      {
        field: "wasted_energy",
        headerName: t("compare_scenarios.table_fields.wasted_energy"),
        minWidth: 190,
        maxWidth: 200,
        flex: 1,
        renderCell: (params) => {
          return comparisonObject[params.row.id]?.wasted_energy;
        },
      },
      {
        field: "wasted_energy_money",
        headerName: t("compare_scenarios.table_fields.wasted_energy_money"),
        minWidth: 170,
        maxWidth: 200,
        flex: 1,
        renderCell: (params) => {
          return comparisonObject[params.row.id]?.wasted_energy_money.replace(".", ",").replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1.");
        },
      },
      {
        field: "number_hoterway",
        headerName: t("compare_scenarios.table_fields.number_hoterway"),
        minWidth: 170,
        maxWidth: 200,
        flex: 1,
        renderCell: (params) => {
          return comparisonObject[params.row.id]?.number_hoterway;
        },
      },
      {
        field: "hoterway_investment",
        headerName: t("compare_scenarios.table_fields.hoterway_investment"),
        minWidth: 150,
        maxWidth: 200,
        flex: 1,
        renderCell: (params) => {
          return comparisonObject[params.row.id]?.hoterway_investment;
        },
      },
      {
        field: "return_investment",
        headerName: t("compare_scenarios.table_fields.return_investment.title"),
        minWidth: 165,
        maxWidth: 200,
        flex: 1,
        renderCell: (params) => {
          if (comparisonObject[params.row.id]?.return_investment === undefined) {
            return;
          }
          if (
            comparisonObject[params.row.id]?.return_investment == Number.POSITIVE_INFINITY ||
            comparisonObject[params.row.id]?.return_investment == Number.NEGATIVE_INFINITY ||
            comparisonObject[params.row.id]?.return_investment == "N.A"
          ) {
            return "N.A";
          } else {
            const valueWithDot = comparisonObject[params.row.id]?.return_investment.replace(",", "."); // Replace comma with dot for parsing
            const numericValue = parseFloat(valueWithDot);
            const years = Math.floor(numericValue);
            const months = Math.round((numericValue - years) * 12);
            // const { years, months } = convertToYearsAndMonths(comparisonObject[params.row.id]?.return_investment);
            if (years === 1 && months === 1) {
              return (
                years +
                ` ${t("compare_scenarios.table_fields.return_investment.year_and_singular")} ` +
                months +
                ` ${t("compare_scenarios.table_fields.month_singular")}`
              );
            } else if (years !== 1 && months === 1) {
              return (
                years +
                ` ${t("compare_scenarios.table_fields.return_investment.year_and_plural")} ` +
                months +
                ` ${t("compare_scenarios.table_fields.month_singular")}`
              );
            } else if (years === 1 && months !== 1) {
              return (
                years +
                ` ${t("compare_scenarios.table_fields.return_investment.year_and_singular")} ` +
                months +
                ` ${t("compare_scenarios.table_fields.month_plural")}`
              );
            } else {
              return (
                years +
                ` ${t("compare_scenarios.table_fields.return_investment.year_and_plural")} ` +
                months +
                ` ${t("compare_scenarios.table_fields.month_plural")}`
              );
            }
          }
        },
      },
    ];
  }

  // EXCEL PARA EXPORTAR/COPIAR VALORES

  return (
    <>
      {isLoading && comparisonObject !== null && comparisonObject !== undefined ? (
        <LoadingScreen />
      ) : (
        <>
          <Box m="20px">
            <Header title={t("compare_scenarios.title")} subtitle={t("compare_scenarios.subtitle")} />

            <Fade
              in={generalError}
              timeout={{ enter: 500, exit: 500 }}
              unmountOnExit={true}
              addEndListener={() => {
                window.scrollTo(0, 0);
                setTimeout(() => {
                  setGeneralError(false);
                }, 3000);
              }}
            >
              <Alert variant="outlined" sx={{ fontSize: "16px" }} severity="error">
                <AlertTitle sx={{ fontSize: "16px" }}>{t("compare_scenarios.alert_fields.general_error.title")}</AlertTitle>
                {t("compare_scenarios.alert_fields.general_error.description")}
              </Alert>
            </Fade>

            <Fade
              in={sessionError}
              timeout={{ enter: 500, exit: 500 }}
              unmountOnExit={true}
              addEndListener={() => {
                window.scrollTo(0, 0);
                setTimeout(() => {
                  setSessionError(false);
                  navigate("/login");
                  logout();
                }, 3000);
              }}
            >
              <Alert variant="outlined" sx={{ fontSize: "16px" }} severity="error">
                <AlertTitle sx={{ fontSize: "16px" }}>{t("compare_scenarios.alert_fields.session_error.title")}</AlertTitle>
                {t("compare_scenarios.alert_fields.session_error.description")}
              </Alert>
            </Fade>

            {comparisonObject && comparisonObject !== null && comparisonObject !== undefined ? (
              <>
                <Box
                  m="40px 0 0 0"
                  height="75vh"
                  sx={{
                    "& .MuiDataGrid-root": {
                      border: "none",
                    },
                    "& .MuiDataGrid-cell": {
                      borderBottom: "none",
                    },
                    "& .name-column--cell": {
                      color: colors.greenAccent[300],
                    },
                    "& .MuiDataGrid-columnHeaders": {
                      backgroundColor: colors.blueAccent[700],
                      borderBottom: "none",
                    },
                    "& .MuiDataGrid-virtualScroller": {
                      backgroundColor: colors.primary[400],
                    },
                    "& .MuiDataGrid-footerContainer": {
                      borderTop: "none",
                      backgroundColor: colors.blueAccent[700],
                    },
                    "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
                      color: `${colors.greenAccent[100]} !important`,
                    },
                    "& .MuiButtonBase-root .MuiButton-root .MuiButton-text .MuiButton-textPrimary .MuiButton-sizeMedium .MuiButton-textSizeMedium .MuiButton-root .MuiButton-text .MuiButton-textPrimary .MuiButton-sizeMedium .MuiButton-textSizeMedium .css-13qp4b7-MuiButtonBase-root-MuiButton-root":
                      {
                        color: `${colors.blueAccent[700]} !important`,
                      },
                    "& .MuiCheckbox-root": {
                      color: `${colors.greenAccent[200]} !important`,
                    },
                  }}
                >
                  <DataGrid
                    rowsPerPageOptions={[50, 100, 250, 500, 1000]}
                    hideFooterSelectedRowCount
                    componentsProps={{
                      pagination: {
                        labelRowsPerPage: t("table_custom_translation.label_rows_per_page"),
                      },
                    }}
                    localeText={{
                      MuiTablePagination: {
                        labelDisplayedRows: ({ from, to, count }) => `${count} ` + t("table_custom_translation.label_displayed_rows"),
                      },
                      // Root
                      noRowsLabel: t("table_custom_translation.no_rows_label"),
                      noResultsOverlayLabel: t("table_custom_translation.no_results_overlay_label"),
                      errorOverlayDefaultLabel: t("table_custom_translation.error_overlay_default_label"),
                      // Density selector toolbar button text
                      toolbarDensity: t("table_custom_translation.toolbar_density"),
                      toolbarDensityLabel: t("table_custom_translation.toolbar_density_label"),
                      toolbarDensityCompact: t("table_custom_translation.toolbar_density_compact"),
                      toolbarDensityStandard: t("table_custom_translation.toolbar_density_standard"),
                      toolbarDensityComfortable: t("table_custom_translation.toolbar_density_comfortable"),
                      // Columns selector toolbar button text
                      toolbarColumns: t("table_custom_translation.toolbar_columns"),
                      toolbarColumnsLabel: t("table_custom_translation.toolbar_columns_label"),
                      // Filters toolbar button text
                      toolbarFilters: t("table_custom_translation.toolbar_filters"),
                      toolbarFiltersLabel: t("table_custom_translation.toolbar_filters_label"),
                      toolbarFiltersTooltipHide: t("table_custom_translation.toolbar_filters_tooltip_hide"),
                      toolbarFiltersTooltipShow: t("table_custom_translation.toolbar_filters_tooltip_show"),
                      toolbarFiltersTooltipActive: (count) =>
                        `${count} ${
                          count !== 1
                            ? t("table_custom_translation.toolbar_filters_tooltip_active.filter_plural")
                            : t("table_custom_translation.toolbar_filters_tooltip_active.filter_singular")
                        } ${
                          count !== 1
                            ? t("table_custom_translation.toolbar_filters_tooltip_active.active_plural")
                            : t("table_custom_translation.toolbar_filters_tooltip_active.active_singular")
                        }`,
                      // Quick filter toolbar field
                      toolbarQuickFilterPlaceholder: t("table_custom_translation.toolbar_quick_filter_placeholder"),
                      toolbarQuickFilterLabel: t("table_custom_translation.toolbar_quick_filter_label"),
                      toolbarQuickFilterDeleteIconLabel: t("table_custom_translation.toolbar_quick_filter_delete_icon_label"),
                      // Export selector toolbar button text
                      toolbarExport: t("table_custom_translation.toolbar_export"),
                      toolbarExportLabel: t("table_custom_translation.toolbar_export_label"),
                      toolbarExportCSV: t("table_custom_translation.toolbar_export_CSV"),
                      toolbarExportPrint: t("table_custom_translation.toolbar_export_print"),
                      toolbarExportExcel: t("table_custom_translation.toolbar_export_excel"),
                      // Columns panel text
                      columnsPanelTextFieldLabel: t("table_custom_translation.columns_panel_text_field_label"),
                      columnsPanelTextFieldPlaceholder: t("table_custom_translation.columns_panel_text_field_placeholder"),
                      columnsPanelDragIconLabel: t("table_custom_translation.columns_panel_drag_icon_label"),
                      columnsPanelShowAllButton: t("table_custom_translation.columns_panel_showAll_button"),
                      columnsPanelHideAllButton: t("table_custom_translation.columns_panel_hideAll_button"),
                      // Filter panel text
                      filterPanelAddFilter: t("table_custom_translation.filter_panel_add_filter"),
                      filterPanelDeleteIconLabel: t("table_custom_translation.filter_panel_delete_icon_label"),
                      filterPanelLinkOperator: t("table_custom_translation.filter_panel_link_operator"),
                      filterPanelOperators: t("table_custom_translation.filter_panel_operators"),
                      // TODO v6: rename to filterPanelOperator
                      filterPanelOperatorAnd: t("table_custom_translation.filter_panel_operator_and"),
                      filterPanelOperatorOr: t("table_custom_translation.filter_panel_operator_or"),
                      filterPanelColumns: t("table_custom_translation.filter_panel_columns"),
                      filterPanelInputLabel: t("table_custom_translation.filter_panel_input_label"),
                      filterPanelInputPlaceholder: t("table_custom_translation.filter_panel_input_placeholder"),
                      // Filter operators text
                      filterOperatorContains: t("table_custom_translation.filter_operator_contains"),
                      filterOperatorEquals: t("table_custom_translation.filter_operator_equals"),
                      filterOperatorStartsWith: t("table_custom_translation.filter_operator_starts_with"),
                      filterOperatorEndsWith: t("table_custom_translation.filter_operator_ends_with"),
                      filterOperatorIs: t("table_custom_translation.filter_operator_is"),
                      filterOperatorNot: t("table_custom_translation.filter_operator_not"),
                      filterOperatorAfter: t("table_custom_translation.filter_operator_after"),
                      filterOperatorOnOrAfter: t("table_custom_translation.filter_operator_on_or_after"),
                      filterOperatorBefore: t("table_custom_translation.filter_operator_before"),
                      filterOperatorOnOrBefore: t("table_custom_translation.filter_operator_on_or_before"),
                      filterOperatorIsEmpty: t("table_custom_translation.filter_operator_is_empty"),
                      filterOperatorIsNotEmpty: t("table_custom_translation.filter_operator_is_not_empty"),
                      filterOperatorIsAnyOf: t("table_custom_translation.filter_operator_is_any_of"),
                      // Filter values text
                      filterValueAny: t("table_custom_translation.filter_value_any"),
                      filterValueTrue: t("table_custom_translation.filter_value_true"),
                      filterValueFalse: t("table_custom_translation.filter_value_false"),
                      // Column menu text
                      columnMenuLabel: t("table_custom_translation.column_menu_label"),
                      columnMenuShowColumns: t("table_custom_translation.column_menu_show_columns"),
                      columnMenuFilter: t("table_custom_translation.column_menu_filter"),
                      columnMenuHideColumn: t("table_custom_translation.column_menu_hide_column"),
                      columnMenuUnsort: t("table_custom_translation.column_menu_unsort"),
                      columnMenuSortAsc: t("table_custom_translation.column_menu_sort_asc"),
                      columnMenuSortDesc: t("table_custom_translation.column_menu_sort_desc"),
                      // Column header text
                      columnHeaderFiltersTooltipActive: (count) =>
                        `${count} ${
                          count !== 1
                            ? t("table_custom_translation.column_header_filters_tooltip_active.filter_plural")
                            : t("table_custom_translation.column_header_filters_tooltip_active.filter_singular")
                        } ${
                          count !== 1
                            ? t("table_custom_translation.column_header_filters_tooltip_active.active_plural")
                            : t("table_custom_translation.column_header_filters_tooltip_active.active_singular")
                        }`,
                      columnHeaderFiltersLabel: t("table_custom_translation.column_header_filters_label"),
                      columnHeaderSortIconLabel: t("table_custom_translation.column_header_sort_icon_label"),
                      // Rows selected footer text
                      footerRowSelected: (count) =>
                        count !== 1
                          ? `${count.toLocaleString()} +` + t("table_custom_translation.footer_row_selected.plural")
                          : `${count.toLocaleString()} ` + t("table_custom_translation.footer_row_selected.singular"),
                      // Total row amount footer text
                      footerTotalRows: t("table_custom_translation.footer_total_rows"),
                      // Total visible row amount footer text
                      footerTotalVisibleRows: (visibleCount, totalCount) =>
                        `${visibleCount.toLocaleString()} ` +
                        t("table_custom_translation.footer_total_visible_rows") +
                        `${totalCount.toLocaleString()}`,
                      // Checkbox selection text
                      checkboxSelectionHeaderName: t("table_custom_translation.checkbox_selection_header_name"),
                      checkboxSelectionSelectAllRows: t("table_custom_translation.checkbox_selection_select_all_rows"),
                      checkboxSelectionUnselectAllRows: t("table_custom_translation.checkbox_selection_unselect_all_rows"),
                      checkboxSelectionSelectRow: t("table_custom_translation.checkbox_selection_select_row"),
                      checkboxSelectionUnselectRow: t("table_custom_translation.checkbox_selection_unselect_row"),
                      // Boolean cell text
                      booleanCellTrueLabel: t("table_custom_translation.boolean_cell_true_label"),
                      booleanCellFalseLabel: t("table_custom_translation.boolean_cell_false_label"),
                      // Actions cell more text
                      actionsCellMore: t("table_custom_translation.actions_cell_more"),
                      // Column pinning text
                      pinToLeft: t("table_custom_translation.pin_to_left"),
                      pinToRight: t("table_custom_translation.pin_to_right"),
                      unpin: t("table_custom_translation.unpin"),
                      // Tree Data
                      treeDataGroupingHeaderName: t("table_custom_translation.tree_data_grouping_header_name"),
                      treeDataExpand: t("table_custom_translation.tree_data_expand"),
                      treeDataCollapse: t("table_custom_translation.tree_data_collapse"),
                      // Grouping columns
                      groupingColumnHeaderName: t("table_custom_translation.grouping_column_header_name"),
                      groupColumn: (name) => t("table_custom_translation.group_column") + ` ${name}`,
                      unGroupColumn: (name) => t("table_custom_translation.un_group_column") + ` ${name}`,
                      // Master/detail
                      detailPanelToggle: t("table_custom_translation.detail_panel_toggle"),
                      expandDetailPanel: t("table_custom_translation.expand_detail_panel"),
                      collapseDetailPanel: t("table_custom_translation.collapse_detail_panel"),
                      // Row reordering text
                      rowReorderingHeaderName: t("table_custom_translation.row_reordering_header_name"),
                      // Aggregation
                      // aggregationMenuItemHeader: 'Aggregation',
                      // aggregationFunctionLabelSum: 'sum',
                      // aggregationFunctionLabelAvg: 'avg',
                      // aggregationFunctionLabelMin: 'min',
                      // aggregationFunctionLabelMax: 'max',
                      // aggregationFunctionLabelSize: 'size',
                    }}
                    rows={project}
                    columns={columns}
                    components={{ Toolbar: GridToolbar }}
                    loading={isLoading}
                    autoHeight={false}
                  />
                </Box>
              </>
            ) : (
              <></>
            )}
          </Box>
        </>
      )}
    </>
  );
};

export default CompareScenario;
