import { createContext, useState, useEffect } from "react";
import axios from "axios";

export const AuthContext = createContext();

const config = {
    headers: {
        "access-token": process.env.REACT_APP_ACCESS_TOKEN,
        "Content-Type": "application/json",
    },
    withCredentials: true,
};

export const AuthContextProvider = ({ children }) => {
    const [currentUser, setCurrentUser] = useState(JSON.parse(localStorage.getItem("user")) || null)
    // const [licenseExpiration, setLicenseExpiration] = useState("");
    const [remainingTime, setRemainingTime] = useState("");

    const login = async (values) => {

        const response = await axios.post(
            process.env.REACT_APP_API_CALL + "users/login",
            values,
            config
        )

        setCurrentUser(response.data)
    }

    const logout = async (values) => {
        const response = await axios.post(
            process.env.REACT_APP_API_CALL + "users/logout",
            values,
            config)
        setCurrentUser(null)
        localStorage.removeItem("user");
        localStorage.removeItem("editor_image");
        localStorage.removeItem("cenarioBase");
        localStorage.removeItem("cenarioHoterwayIN");
        localStorage.removeItem("cenarioHoterwayACTIVE");
        localStorage.removeItem("cenarioRetorno");
        localStorage.removeItem("connections");
    }

    const verifyUser = async (values) => {
        try {
            const response = await axios.get(
                process.env.REACT_APP_API_CALL + `users/${values.email}`,
                config);

            if ((response.data.data[0].user_type !== currentUser.user_type) || currentUser.user_type === undefined || currentUser.user_type === null) {
                logout();
                setCurrentUser(null);
            }
        } catch (error) {
            console.log(error)
            logout();
            setCurrentUser(null);
        }
    };

    const calculateRemainingTime = async (values) => {
        const response = await axios.get(
            process.env.REACT_APP_API_CALL + `licenses/email/${values.email}`
            , config);

        const now = new Date();
        const expiry = new Date(response.data.data[0].time);
        const timeDifference = expiry - now;

        if (timeDifference <= 0) {
            let values = {
                id: response.data.data[0].id,
                license: response.data.data[0].license,
                date: response.data.data[0].date,
                type: response.data.data[0].type,
                email: response.data.data[0].email,
                time: 0,
                active: 0,
            }
            axios.patch(
                process.env.REACT_APP_API_CALL + "licenses",
                values,
                config
            );
            setRemainingTime("License Expired");
            logout();
        } else {
            const days = Math.floor(timeDifference / (1000 * 60 * 60 * 24));
            const hours = Math.floor(
                (timeDifference % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
            );
            const minutes = Math.floor(
                (timeDifference % (1000 * 60 * 60)) / (1000 * 60)
            );
            const seconds = Math.floor((timeDifference % (1000 * 60)) / 1000);

            setRemainingTime(
                `${days} dias, ${hours} horas, ${minutes} min., ${seconds} seg.`
            );
        }
    };

    useEffect(() => {
        localStorage.setItem("user", JSON.stringify(currentUser));
    }, [currentUser])

    // useEffect(() => {
    //     calculateRemainingTime();

    //     const intervalId = setInterval(calculateRemainingTime, 1000);

    //     return () => clearInterval(intervalId);
    // }, [licenseExpiration]);

    return (
        <AuthContext.Provider value={{ currentUser, login, logout, verifyUser, remainingTime, calculateRemainingTime }}>{children}</AuthContext.Provider>
    )
}