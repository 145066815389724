import { deepCopy } from "@firebase/util";
import Konva from "konva";
import React, { useEffect, useRef, useState } from "react";
import { Stage, Layer, Group, Rect, Text, Circle, Arrow, Line } from "react-konva";
import {
  calculateStageScaleAndPosition,
  extractJointPoints,
  fullDiagramDescription,
  inteceptCircleLineSeg,
  intersect,
} from "../utils/konvaUtils";
import { Box, Typography, useTheme, Grid } from "@mui/material";
import PreviewOutlinedIcon from "@mui/icons-material/PreviewOutlined";
import { tokens } from "../theme";
import { TFunction } from "i18next";

const stageHeight = window.innerHeight / 1.74;
const circleRadius = 25;
function getTextWidth(text: string) {
  // re-use canvas object for better performance
  const canvas = document.createElement("canvas");
  const context = canvas.getContext("2d");
  const metrics = context.measureText(text);
  return metrics.width;
}

const ColoredRect = ({
  index,
  x,
  y,
  text,
  type,
  color,
  arrowStart,
  setArrowStart,
  currentArrows,
  setCurrentArrows,
  jointPoints,
  setJointPoints,
  width,
  stageScaleAndPosition,
  alignmentGuide,
  setAlignmentGuide,
  shapeRefs,
  sourceTitle,
  onDragStart,
  disabled,
}: {
  index: number;
  x: number;
  y: number;
  text: string;
  type: string;
  color: string;
  arrowStart: Konva.Group;
  setArrowStart: React.Dispatch<React.SetStateAction<Konva.Group>>;
  currentArrows: { start: Konva.Group; end: Konva.Group }[];
  setCurrentArrows: React.Dispatch<React.SetStateAction<{ start: Konva.Group; end: Konva.Group }[]>>;
  jointPoints: { index: number; x: number; y: number }[];
  setJointPoints: React.Dispatch<React.SetStateAction<{ index: number; x: number; y: number }[]>>;
  width: number;
  stageScaleAndPosition: { stageScale: number; stageX: number; stageY: number };
  alignmentGuide: { p1: Konva.Group; p2: Konva.Group; xDistance: number; yDistance: number }[] | null;
  setAlignmentGuide: React.Dispatch<
    React.SetStateAction<{ p1: Konva.Group; p2: Konva.Group; xDistance: number; yDistance: number }[] | null>
  >;
  shapeRefs: React.MutableRefObject<Konva.Group[]>;
  sourceTitle: string;
  onDragStart: (e: Konva.KonvaEventObject<DragEvent>) => void;
  disabled?: boolean;
}) => {
  let rect_width = 2 * (getTextWidth(text) + 5);
  const shapeRef = useRef<Konva.Group>();
  const [coords, setCoords] = useState<{ x: number; y: number }>({
    x: x,
    y: y,
  });
  if (rect_width < 30) rect_width = 30;
  if (type === "circle") rect_width = 50;

  const shapeClick = (evt: Konva.KonvaEventObject<MouseEvent>) => {
    if (disabled) return;
    if (evt.evt.ctrlKey && shapeRef.current && !arrowStart && shapeRef.current.children[0] instanceof Konva.Circle) {
      const bubbleID = parseInt(shapeRef.current.children[1].attrs.text.substring(1));
      let arrayCopy = [...jointPoints];
      for (let i = 0; i < jointPoints.length; i++) {
        if (jointPoints[i].index === bubbleID) {
          arrayCopy.splice(i, 1);
          break;
        }
      }
      setJointPoints(arrayCopy);
      shapeRef.current.destroy();
    }
    if (
      shapeRef.current &&
      arrowStart &&
      arrowStart.attrs.x === shapeRef.current.attrs.x &&
      arrowStart.attrs.y === shapeRef.current.attrs.y
    ) {
      setArrowStart(null);
      return;
    }
    if (arrowStart != null && shapeRef.current) {
      let arrayCopy = deepCopy(currentArrows);
      let points = {
        start: arrowStart,
        children: [],
        end: shapeRef.current,
      };
      if (arrowStart.children[0] instanceof Konva.Rect && arrowStart.children[1].attrs.text !== sourceTitle) {
        // TODO can't connect rect to circle
      } else if (
        arrowStart.children[1].attrs.text === sourceTitle ||
        (!arrayCopy.find((e) => e.start === points.start && e.end === points.end) && arrowStart.children[0] instanceof Konva.Circle)
      ) {
        arrayCopy.push(points);
        setCurrentArrows(arrayCopy);
      }
      setArrowStart(null);
    }
  };
  const shapeDoubleClick = () => {
    if (disabled) return;
    if (arrowStart) setArrowStart(undefined);
    else setArrowStart(shapeRef.current);
  };
  return (
    <Group
      draggable={!disabled}
      onDragStart={onDragStart}
      ref={(e) => {
        shapeRef.current = e;
        shapeRefs.current[index] = e;
      }}
      x={coords.x}
      y={coords.y}
      onDragMove={() => {
        calculateAndSetAlignmentAid(shapeRef, shapeRefs, setAlignmentGuide);
      }}
      onDragEnd={() => {
        alignShape(shapeRef, alignmentGuide, setAlignmentGuide);
      }}
      onTap={shapeClick}
      onClick={shapeClick}
      onDblTap={shapeDoubleClick}
      onDblClick={shapeDoubleClick}
    >
      {type === "rect" && <Rect width={rect_width} height={50} fill={arrowStart ? "rgba(0,0,0,.2)" : color} shadowBlur={5} />}
      {type === "circle" && (
        <Circle offset={{ x: -12.5, y: -12.5 }} radius={circleRadius} fill={arrowStart ? "rgba(0,0,0,.2)" : color} shadowBlur={5} />
      )}
      {arrowStart && shapeRef.current && shapeRef.current === arrowStart && type === "circle" && (
        <Group
          onClick={() => {
            const bubbleID = parseInt(shapeRef.current.children[2].attrs.text.substring(1));
            let arrayCopy = [...jointPoints];
            for (let i = 0; i < jointPoints.length; i++) {
              if (jointPoints[i].index === bubbleID) {
                arrayCopy.splice(i, 1);
                break;
              }
            }
            setJointPoints(arrayCopy);
            shapeRef.current.destroy();
            setArrowStart(null);
          }}
          onTap={() => {
            const bubbleID = parseInt(shapeRef.current.children[2].attrs.text.substring(1));
            let arrayCopy = [...jointPoints];
            for (let i = 0; i < jointPoints.length; i++) {
              if (jointPoints[i].index === bubbleID) {
                arrayCopy.splice(i, 1);
                break;
              }
            }
            setJointPoints(arrayCopy);
            shapeRef.current.destroy();
            setArrowStart(null);
          }}
        >
          <Text
            text="X"
            offset={{
              x: -20,
              y: 10,
            }}
            fontSize={20}
            verticalAlign="middle"
            align="center"
            width={20}
            fill={"#FFF"}
          />
        </Group>
      )}
      <Text
        text={text}
        offset={{
          y: type === "rect" ? -20 : -7,
          x: type === "rect" ? 0 : 10,
        }}
        align="center"
        fontSize={type === "rect" ? 16 : 13}
        verticalAlign="center"
        width={rect_width}
        // opacity={text === sourceTitle ? 1 : 0}
        fill={"#FFF"}
      />
    </Group>
  );
};

function findFreeId(array) {
  const sortedArray = array
    .slice() // Make a copy of the array.
    .sort(function (a, b) {
      return a.index - b.index;
    }); // Sort it.
  let previousId = 0;
  for (let element of sortedArray) {
    if (element.index !== previousId + 1) {
      // Found a gap.
      return previousId + 1;
    }
    previousId = element.index;
  }
  // Found no gaps.
  return previousId + 1;
}

const KonvaEditor = ({
  refEditor,
  sourceTitle,
  floors_count,
  floorsNames,
  pePcLayout,
  currentArrows,
  setCurrentArrows,
  pePcConnections,
  setPePcConnections,
  width,
  loadFromSavedObject,
  canEdit,
  project,
  setProject,
  t,
  pePcConnectionsAdjustedDiameter,
  defaults,
  pePcConnectionsRe,
  setPePcConnectionsRe,
  disabled = false,
  setPePcConnectionsAdjustedDiameter,
}: {
  refEditor: React.MutableRefObject<Konva.Stage>;
  sourceTitle: string;
  floors_count: number;
  floorsNames: string[];
  pePcLayout: { [key: string]: { [key: string]: string } };
  pePcConnections: any;
  setPePcConnections: React.Dispatch<React.SetStateAction<{ [key: string]: { [key: string]: number } }>>;
  width: number;
  loadFromSavedObject?: {
    pePcLayout: { [key: string]: { [key: string]: string } };
    pePcConnections: {
      start: string;
      children: { x: number; y: number }[];
      end: string;
    }[];
  };
  currentArrows: {
    start: Konva.Group;
    children: { x: number; y: number }[];
    end: Konva.Group;
  }[];
  setCurrentArrows: React.Dispatch<React.SetStateAction<{ start: Konva.Group; children: { x: number; y: number }[]; end: Konva.Group }[]>>;
  canEdit: boolean;
  project: any;
  setProject: (project) => void;
  t: TFunction<"translation", undefined, "translation">;
  pePcConnectionsAdjustedDiameter: any;
  defaults: any;
  pePcConnectionsRe: any;
  setPePcConnectionsRe: (newPePcConnectionsRe) => void;
  disabled?: boolean;
  setPePcConnectionsAdjustedDiameter: (newPePcConnectionsAdjustedDiameter) => void;
}) => {
  // Stage is a div container
  // Layer is actual canvas element (so you may have several canvases in the stage)
  // And then we have canvas shapes inside the Layer

  const theme = useTheme();

  const colors = tokens(theme.palette.mode);
  const [stageScaleAndPosition, setStageScaleAndPosition] = useState({
    stageScale: 1,
    stageX: 0,
    stageY: 0,
  });

  const [isArrowPopupOpen, setIsArrowPopupOpen] = useState<
    | {
        arrowStart: string;
        arrowEnd: string;
      }
    | undefined
  >(undefined);
  useEffect(() => {
    if (refEditor.current && loadFromSavedObject)
      setStageScaleAndPosition(
        calculateStageScaleAndPosition(
          refEditor.current,
          {
            pePcLayout: loadFromSavedObject.pePcLayout,
            pePcConnections: currentArrows.map((arrow) => {
              if (!arrow.start.children[1] || !arrow.end.children[1])
                return {
                  start: "",
                  children: [],
                  end: "",
                };
              return {
                start: arrow.start.children[1].attrs.text,
                children: arrow.children.map((child) => {
                  return {
                    x: child.x,
                    y: child.y,
                  };
                }),
                end: arrow.end.children[1].attrs.text,
              };
            }),
          },
          width
        )
      );
    else
      setStageScaleAndPosition(calculateStageScaleAndPosition(refEditor.current, fullDiagramDescription(refEditor, currentArrows), width));
  }, [width]);

  const [isZooming, setIsZooming] = useState(false);

  const [arrowStart, setArrowStart] = useState<Konva.Group | null>();
  const [alignmentGuide, setAlignmentGuide] = useState<
    { p1: Konva.Group; p2: Konva.Group; xDistance: number; yDistance: number }[] | null
  >();
  const [jointPoints, setJointPoints] = useState<{ index: number; x: number; y: number }[]>(
    loadFromSavedObject ? extractJointPoints(loadFromSavedObject.pePcLayout) : []
  );

  const shapeRefs = useRef([]);
  useEffect(() => {
    if (
      loadFromSavedObject &&
      shapeRefs.current.length !== 0 &&
      currentArrows.length === 0 &&
      shapeRefs.current.filter((e) => {
        return !e || !e.children || e.children.length === 0;
      }).length === 0
    ) {
      const importedArrows = loadFromSavedObject.pePcConnections;
      let res = [];
      for (let i = 0; i < importedArrows.length; i++) {
        const arrow = importedArrows[i];
        const start = shapeRefs.current.find((e) => e && e.children && e.children[1].attrs.text === arrow.start);
        const end = shapeRefs.current.find((e) => e && e.children && e.children[1].attrs.text === arrow.end);
        if (!start || !end) continue;
        res.push({ start, children: arrow.children ?? [], end });
      }
      setCurrentArrows(res);
      // change the coordinates of the rect groups
      for (let i = 0; i < shapeRefs.current.length; i++) {
        if (!shapeRefs.current[i]) continue;
        const shape = shapeRefs.current[i];
        const shapeType = shape.children[0].className;
        const shapeText = shape.children[1].attrs.text;
        const shapeCoordsKey =
          Object.keys(loadFromSavedObject.pePcLayout).find((e) => e === shapeText) !== undefined
            ? shapeText
            : loadFromSavedObject.pePcLayout.source.nickname === shapeText
            ? "source"
            : null;

        if (!shapeCoordsKey) continue;
        if (shapeType === "Rect") {
          shape.x(loadFromSavedObject.pePcLayout[shapeCoordsKey].x);
          shape.y(loadFromSavedObject.pePcLayout[shapeCoordsKey].y);
        }
      }

      setStageScaleAndPosition(calculateStageScaleAndPosition(refEditor.current, loadFromSavedObject, width));
    }
  }, []);

  useEffect(() => {
    shapeRefs.current = shapeRefs.current.slice(0, 1 + Object.keys(pePcLayout).length + jointPoints.length);
  }, [pePcLayout, jointPoints.length]);

  // useEffect(() => {
  //   let res = {};
  //   for (let arrow of currentArrows) {
  //     if (arrow.start.children.length > 0) {
  //       if (!(arrow.start.children[1].attrs.text in res)) {
  //         res[arrow.start.children[1].attrs.text] = {};
  //       }
  //       console.log(arrow.start.children[1].attrs.text);
  //       console.log(arrow.end.children[1].attrs.text);
  //       res[arrow.start.children[1].attrs.text][arrow.end.children[1].attrs.text] = 1;
  //     } else {
  //       // Handle case where arrow.start.children.length <= 0
  //       console.log("Start has no children.");
  //     }
  //   }
  //   if (Object.keys(res).length > 0) {
  //     setPePcConnections(res);
  //   } else {
  //     // Handle case where res remains empty
  //     console.log("No connections were found.");
  //   }
  // }, [currentArrows]);

  useEffect(() => {
    let res = {};
    for (let arrow of currentArrows) {
      // Check if arrow.start.children has at least two elements
      if (arrow.start.children.length > 1 && arrow.end.children.length > 1) {
        const startText = arrow.start.children[1]?.attrs?.text;
        const endText = arrow.end.children[1]?.attrs?.text;

        if (startText && endText) {
          if (!(startText in res)) {
            res[startText] = {};
          }
          res[startText][endText] = 1;
        }
      } else {
        // Handle the case where children array is too short
        console.log("Start or end has fewer than 2 children.");
      }
    }
    if (Object.keys(res).length > 0) {
      setPePcConnections(res);
    } else {
      console.log("No connections were found.");
    }
  }, [currentArrows]);


  const createJoint = async (evt: Konva.KonvaEventObject<MouseEvent>) => {
    if (disabled) return;
    if (!(evt.target as Konva.Stage).children) return;
    if (!arrowStart)
      if (!((evt.target as Konva.Stage).children[0] instanceof Konva.Rect))
        if (!((evt.target as Konva.Stage).children[0] instanceof Konva.Circle)) {
          let arrayCopy = [...jointPoints];
          arrayCopy.push({
            index: findFreeId(jointPoints),
            x: (evt.evt.layerX - stageScaleAndPosition.stageX) / stageScaleAndPosition.stageScale - circleRadius / 2,
            y: (evt.evt.layerY - stageScaleAndPosition.stageY) / stageScaleAndPosition.stageScale - circleRadius / 2,
          });
          setJointPoints(arrayCopy);
        }
  };
  const cancelArrow = () => {
    if (arrowStart) setArrowStart(null);
  };

  const calculateShapeBorders = (arrow_coords: { start: Konva.Group; children: { x: number; y: number }[]; end: Konva.Group }) => {
    let startPos = {
      x: arrow_coords.start.attrs.x,
      y: arrow_coords.start.attrs.y,
    };
    let endPos = { x: arrow_coords.end.attrs.x, y: arrow_coords.end.attrs.y };
    let startShape = arrow_coords.start.children[0];
    let endShape = arrow_coords.end.children[0];
    let endOffset = { x: 0, y: 0 };
    let startOffset = { x: 0, y: 0 };
    if (startPos.x >= endPos.x) endOffset = { x: Number.MIN_VALUE, y: Number.MIN_VALUE };
    else endOffset = { x: Number.MAX_VALUE, y: Number.MAX_VALUE };

    if (startShape instanceof Konva.Rect) {
      startOffset = {
        x: startShape.attrs.width / 2,
        y: startShape.attrs.height / 2,
      };
    } else if (startShape instanceof Konva.Circle) {
      startOffset = {
        x: startShape.attrs.radius / 2,
        y: startShape.attrs.radius / 2,
      };
    }
    if (endShape instanceof Konva.Rect) {
      const intersecStart =
        arrow_coords.children.length === 0
          ? {
              x: startPos.x + startOffset.x,
              y: startPos.y + startOffset.y,
            }
          : {
              x: arrow_coords.children[arrow_coords.children.length - 1].x,
              y: arrow_coords.children[arrow_coords.children.length - 1].y,
            };
      let point: false | { x: number; y: number } = false;
      if (!point)
        point = intersect(
          intersecStart.x,
          intersecStart.y,
          endPos.x + endShape.attrs.width / 2,
          endPos.y + endShape.attrs.height / 2,
          endPos.x,
          endPos.y,
          endPos.x,
          endPos.y + endShape.attrs.height
        );

      if (!point)
        point = intersect(
          intersecStart.x,
          intersecStart.y,
          endPos.x + endShape.attrs.width / 2,
          endPos.y + endShape.attrs.height / 2,
          endPos.x,
          endPos.y,
          endPos.x + endShape.attrs.width,
          endPos.y
        );

      if (!point)
        point = intersect(
          intersecStart.x,
          intersecStart.y,
          endPos.x + endShape.attrs.width / 2,
          endPos.y + endShape.attrs.height / 2,
          endPos.x,
          endPos.y + endShape.attrs.height,
          endPos.x + endShape.attrs.width,
          endPos.y + endShape.attrs.height
        );
      if (!point)
        point = intersect(
          intersecStart.x,
          intersecStart.y,
          endPos.x + endShape.attrs.width / 2,
          endPos.y + endShape.attrs.height / 2,
          endPos.x + endShape.attrs.width,
          endPos.y,
          endPos.x + endShape.attrs.width,
          endPos.y + endShape.attrs.height
        );
      if (point) {
        endOffset.x = point.x;
        endOffset.y = point.y;
      } else {
        endOffset = {
          x: endPos.x + endShape.attrs.width / 2,
          y: endPos.y + endShape.attrs.height / 2,
        };
      }
    } else if (endShape instanceof Konva.Circle) {
      const interception = inteceptCircleLineSeg(
        {
          center: {
            x: endPos.x + endShape.attrs.radius / 2,
            y: endPos.y + endShape.attrs.radius / 2,
          },
          radius: endShape.attrs.radius + 5,
        },
        {
          p1: {
            x: startPos.x + startOffset.x,
            y: startPos.y + startOffset.y,
          },
          p2: {
            x: endPos.x + endShape.attrs.radius / 2,
            y: endPos.y + endShape.attrs.radius / 2,
          },
        }
      );
      if (interception.length > 1) endOffset = interception[0];
      else
        endOffset = {
          x: endPos.x + endShape.attrs.radius / 2,
          y: endPos.y + endShape.attrs.radius / 2,
        };
    }
    return {
      startPos: {
        x: startPos.x + startOffset.x,
        y: startPos.y + startOffset.y,
      },
      endPos: {
        x: endOffset.x,
        y: endOffset.y,
      },
    };
  };

  const handleWheel = (e) => {
    e.evt.preventDefault();

    const scaleBy = 1.02;
    const stage = e.target.getStage();
    const oldScale = stage.scaleX();
    const mousePointTo = {
      x: stage.getPointerPosition().x / oldScale - stage.x() / oldScale,
      y: stage.getPointerPosition().y / oldScale - stage.y() / oldScale,
    };

    const newScale = e.evt.deltaY > 0 ? oldScale * scaleBy : oldScale / scaleBy;
    setStageScaleAndPosition({
      stageScale: newScale,
      stageX: -(mousePointTo.x - stage.getPointerPosition().x / newScale) * newScale,
      stageY: -(mousePointTo.y - stage.getPointerPosition().y / newScale) * newScale,
    });
  };

  function getCenter(p1, p2) {
    return {
      x: (p1.x + p2.x) / 2,
      y: (p1.y + p2.y) / 2,
    };
  }

  function getDistance(p1, p2) {
    return Math.sqrt(Math.pow(p2.x - p1.x, 2) + Math.pow(p2.y - p1.y, 2));
  }

  var lastCenter = null;
  var lastDist = 0;

  const handleMultiTouch = (e) => {
    e.evt.preventDefault();

    var touch1 = e.evt.touches[0];
    var touch2 = e.evt.touches[1];
    const stage = e.target.getStage();

    if (touch1 && touch2) {
      setIsZooming(true);

      var p1 = {
        x: touch1.clientX,
        y: touch1.clientY,
      };
      var p2 = {
        x: touch2.clientX,
        y: touch2.clientY,
      };

      if (!lastCenter) {
        lastCenter = getCenter(p1, p2);
        return;
      }
      var newCenter = getCenter(p1, p2);

      var dist = getDistance(p1, p2);

      if (!lastDist) {
        lastDist = dist;
      }

      // local coordinates of center point
      var pointTo = {
        x: (newCenter.x - stage.x()) / stage.scaleX(),
        y: (newCenter.y - stage.y()) / stage.scaleX(),
      };

      var scale = stage.scaleX() * (dist / lastDist);

      stage.scaleX(scale);
      stage.scaleY(scale);

      // calculate new position of the stage
      var dx = newCenter.x - lastCenter.x;
      var dy = newCenter.y - lastCenter.y;

      var newPos = {
        x: newCenter.x - pointTo.x * scale + dx,
        y: newCenter.y - pointTo.y * scale + dy,
      };

      stage.position(newPos);
      stage.batchDraw();

      lastDist = dist;
      lastCenter = newCenter;
    }
  };

  const multiTouchEnd = () => {
    lastCenter = null;
    lastDist = 0;
    setIsZooming(false);
    setStageScaleAndPosition({
      ...stageScaleAndPosition,
      stageX: refEditor.current.attrs.x,
      stageY: refEditor.current.attrs.y,
    });
  };

  const handleDragStart = (e) => {
    const stage = e.target.getStage();

    if (isZooming) {
      stage.stopDrag();
    }
  };
  const outterDiv = useRef(null);


  useEffect(() => {
    outterDiv.current?.addEventListener("contextmenu", (e) => {
      e.preventDefault();
    });
  }, []);
  useEffect(() => {
    const handleEscapeKey = (event) => {
      if (event.key === "Escape") {
        setIsArrowPopupOpen(undefined);
      }
    };

    window.addEventListener("keydown", handleEscapeKey);

    return () => {
      window.removeEventListener("keydown", handleEscapeKey);
    };
  }, [setIsArrowPopupOpen]);
  return (
    <div
      style={{
        display: "flex",
        position: "relative",
      }}
      ref={outterDiv}
    >
      <Stage
        width={width}
        height={stageHeight}
        onClick={() => cancelArrow()}
        onTap={() => cancelArrow()}
        onDblClick={createJoint}
        onDblTap={createJoint}
        ref={refEditor}
        onWheel={handleWheel}
        onTouchMove={handleMultiTouch}
        onTouchEnd={multiTouchEnd}
        draggable={!isZooming}
        onDragEnd={(e) => {
          //update stage position
          if (e.target.nodeType !== "Stage") return;
          setStageScaleAndPosition({
            ...stageScaleAndPosition,
            stageX: e.target.x(),
            stageY: e.target.y(),
          });
        }}
        scaleX={stageScaleAndPosition.stageScale}
        scaleY={stageScaleAndPosition.stageScale}
        x={stageScaleAndPosition.stageX}
        y={stageScaleAndPosition.stageY}
      >
        {alignmentGuide && (
          <Layer>
            {alignmentGuide.map((line, index) => {
              const xDistance = Math.abs(line.p2.attrs.x - line.p1.attrs.x);
              let p1 = { x: line.p1.attrs.x, y: line.p1.attrs.y };
              if (line.p1.attrs.width) {
                p1.x += line.p1.attrs.width / 2;
                p1.y += line.p1.attrs.height / 2;
              } else if (line.p1.children[0] instanceof Konva.Circle) {
                p1.x += line.p1.children[0].attrs.radius / 2;
                p1.y += line.p1.children[0].attrs.radius / 2;
              } else {
                p1.x += line.p1.children[0].attrs.width / 2;
                p1.y += line.p1.children[0].attrs.height / 2;
              }
              let p2 = { x: line.p2.attrs.x, y: line.p2.attrs.y };
              if (line.p2.children[0] instanceof Konva.Circle) {
                p2.x += line.p2.children[0].attrs.radius / 2;
                p2.y += line.p2.children[0].attrs.radius / 2;
              } else {
                p2.x += line.p2.children[0].attrs.width / 2;
                p2.y += line.p2.children[0].attrs.height / 2;
              }
              const line_points = [];
              if (xDistance < 50) {
                line_points.push(p2.x, p1.y, p2.x, p2.y);
              } else {
                line_points.push(p1.x, p2.y, p2.x, p2.y);
              }
              return <Line key={"helping_guide_" + index} points={line_points} dash={[10, 5]} strokeWidth={10} stroke="rgb(40, 44, 52)" />;
            })}
          </Layer>
        )}
        {floors_count > 1 ? (
          <Layer>
            {Array.from(Array(floors_count).keys()).map((index) => {
              const floor_y = (index * stageHeight) / floors_count;
              return (
                <React.Fragment key={"floor_" + index}>
                  <Line
                    strokeWidth={40 / floors_count}
                    points={[
                      (0 - stageScaleAndPosition.stageX) / stageScaleAndPosition.stageScale,
                      floor_y,
                      (width - stageScaleAndPosition.stageX) / stageScaleAndPosition.stageScale,
                      floor_y,
                    ]}
                    stroke="rgb(40, 44, 52)"
                  />
                  <Text
                    text={floorsNames[floorsNames.length - 1 - index]}
                    offset={{ x: -10, y: -floor_y + -20 }}
                    fontSize={16}
                    fontStyle="italic"
                    verticalAlign="bottom"
                    textDecoration="underline"
                    fill={"#FFF"}
                  />
                </React.Fragment>
              );
            })}
          </Layer>
        ) : (
          <Layer>
            {Array.from(Array(floors_count).keys()).map((index) => {
              const floor_y = (index * stageHeight) / floors_count;
              return (
                <React.Fragment key={"floor_" + index}>
                  <Text
                    text={floorsNames[floorsNames.length - 1 - index]}
                    offset={{ x: -10, y: -floor_y + -20 }}
                    fontSize={16}
                    fontStyle="italic"
                    verticalAlign="bottom"
                    textDecoration="underline"
                    fill={"#FFF"}
                  />
                </React.Fragment>
              );
            })}
          </Layer>
        )}
        <Layer>
          {currentArrows.map((arrow_coords, index) => {
            const clickBehavior = (evt: Konva.KonvaEventObject<MouseEvent>) => {
              if (disabled) return;
              if (evt.evt.ctrlKey) {
                let arrayCopy = deepCopy(currentArrows);
                const deletedItem = arrayCopy.splice(index, 1)[0];
                // arrayCopy.splice(index, 1);
                setCurrentArrows(arrayCopy);
              } else {
                //Implement breakableArrows
                if (evt.evt.button === 2) {
                  setIsArrowPopupOpen({
                    arrowStart: arrow_coords.start.children[1].attrs.text,
                    arrowEnd: arrow_coords.end.children[1].attrs.text,
                  });
                } else {
                  let arrowsCopy = [...currentArrows];
                  const newChild = {
                    x: (evt.evt.layerX - stageScaleAndPosition.stageX) / stageScaleAndPosition.stageScale,
                    y: (evt.evt.layerY - stageScaleAndPosition.stageY) / stageScaleAndPosition.stageScale,
                  };
                  arrowsCopy[index].children.push(newChild);
                  setCurrentArrows(arrowsCopy);
                }
              }
            };
            const shapeBounds = calculateShapeBorders(arrow_coords);
            if (
              !arrow_coords.start.children[1] ||
              !arrow_coords.end.children[1] ||
              arrow_coords.start.children[1].attrs.text === undefined ||
              arrow_coords.end.children[1].attrs.text === undefined ||
              !pePcConnections ||
              !(arrow_coords.start.children[1].attrs.text in pePcConnections) ||
              !(arrow_coords.end.children[1].attrs.text in pePcConnections[arrow_coords.start.children[1].attrs.text])
            ) {
              return <></>; // Return an empty fragment or handle the case when the necessary data is not available.
            }
            const text1 =
              "L-" +
              (pePcConnections[arrow_coords.start.children[1].attrs.text][arrow_coords.end.children[1].attrs.text].width ?? 5) + // Use 5 as the default value
              " D-" +
              (pePcConnectionsAdjustedDiameter
                ? pePcConnectionsAdjustedDiameter[arrow_coords.start.children[1].attrs.text]
                  ? pePcConnectionsAdjustedDiameter[arrow_coords.start.children[1].attrs.text][arrow_coords.end.children[1].attrs.text]
                    ? pePcConnectionsAdjustedDiameter[arrow_coords.start.children[1].attrs.text][arrow_coords.end.children[1].attrs.text]
                    : 16
                  : 16
                : 16);
            const text2 =
              "R-" +
              (arrow_coords.start.children[1].attrs.text in pePcConnectionsRe &&
              arrow_coords.end.children[1].attrs.text in pePcConnectionsRe[arrow_coords.start.children[1].attrs.text]
                ? Number(pePcConnectionsRe[arrow_coords.start.children[1].attrs.text][arrow_coords.end.children[1].attrs.text])
                  ? pePcConnectionsRe[arrow_coords.start.children[1].attrs.text][arrow_coords.end.children[1].attrs.text]
                  : pePcConnectionsRe[arrow_coords.start.children[1].attrs.text][arrow_coords.end.children[1].attrs.text].recirculacao
                  ? pePcConnectionsRe[arrow_coords.start.children[1].attrs.text][arrow_coords.end.children[1].attrs.text].recirculacao
                  : 0
                : 0);
            if (!currentArrows[index].children || currentArrows[index].children.length === 0) {
              //pePcConnectionsAdjustedDiameter
              const isConnectionVertical =
                Math.abs(shapeBounds.endPos.y - shapeBounds.startPos.y) > Math.abs(shapeBounds.endPos.x - shapeBounds.startPos.x);
              let text1Pos = { x: 0, y: 0 };
              let text2Pos = { x: 0, y: 0 };
              if (isConnectionVertical) {
                text1Pos = {
                  x: Math.max(shapeBounds.endPos.x, shapeBounds.startPos.x) - Math.abs(shapeBounds.endPos.x - shapeBounds.startPos.x) + 10,
                  y:
                    Math.max(shapeBounds.endPos.y, shapeBounds.startPos.y) -
                    Math.abs(shapeBounds.endPos.y - shapeBounds.startPos.y) / 2 -
                    20,
                };
                text2Pos = {
                  x: Math.max(shapeBounds.endPos.x, shapeBounds.startPos.x) - Math.abs(shapeBounds.endPos.x - shapeBounds.startPos.x) + 10,
                  y:
                    Math.max(shapeBounds.endPos.y, shapeBounds.startPos.y) -
                    Math.abs(shapeBounds.endPos.y - shapeBounds.startPos.y) / 2 +
                    5,
                };
              } else {
                text1Pos = {
                  x:
                    Math.max(shapeBounds.endPos.x, shapeBounds.startPos.x) -
                    Math.abs(shapeBounds.endPos.x - shapeBounds.startPos.x) / 2 -
                    getTextWidth(text1),
                  y:
                    Math.max(shapeBounds.endPos.y, shapeBounds.startPos.y) -
                    Math.abs(shapeBounds.endPos.y - shapeBounds.startPos.y) / 2 -
                    20,
                };
                text2Pos = {
                  x:
                    Math.max(shapeBounds.endPos.x, shapeBounds.startPos.x) -
                    Math.abs(shapeBounds.endPos.x - shapeBounds.startPos.x) / 2 -
                    getTextWidth(text2),
                  y:
                    Math.max(shapeBounds.endPos.y, shapeBounds.startPos.y) -
                    Math.abs(shapeBounds.endPos.y - shapeBounds.startPos.y) / 2 +
                    5,
                };
              }
              return (
                <>
                  <Arrow
                    points={[shapeBounds.startPos.x, shapeBounds.startPos.y, shapeBounds.endPos.x, shapeBounds.endPos.y]}
                    key={"arrow_" + index}
                    strokeWidth={5}
                    stroke="rgb(255,0,0)"
                    onTap={clickBehavior}
                    onClick={clickBehavior}
                  />
                  <Text text={text1} x={text1Pos.x} y={text1Pos.y} fontSize={16} verticalAlign="bottom" fill={"#FFF"} />
                  {canEdit && <Text text={text2} x={text2Pos.x} y={text2Pos.y} fontSize={16} verticalAlign="bottom" fill={"#FFF"} />}
                </>
              );
            } else {
              let longestConnectionWidth = 0;
              let longestConnection = {
                start: {
                  x: 0,
                  y: 0,
                },
                end: {
                  x: 0,
                  y: 0,
                },
              };
              for (let i = 0; i < currentArrows[index].children.length; i++) {
                let currentConnection = 0;
                if (i === 0)
                  currentConnection =
                    Math.abs(currentArrows[index].children[i].x - shapeBounds.startPos.x) +
                    Math.abs(currentArrows[index].children[i].y - shapeBounds.startPos.y);
                if (currentConnection > longestConnectionWidth) {
                  longestConnectionWidth = currentConnection;
                  longestConnection.start = { ...currentArrows[index].start.attrs };
                  longestConnection.end = {
                    x: currentArrows[index].children[i].x + 20,
                    y: currentArrows[index].children[i].y + 20,
                  };
                }
                if (i === currentArrows[index].children.length - 1) {
                  currentConnection =
                    Math.abs(currentArrows[index].children[i].x - shapeBounds.endPos.x) +
                    Math.abs(currentArrows[index].children[i].y - shapeBounds.endPos.y);
                  if (currentConnection > longestConnectionWidth) {
                    longestConnectionWidth = currentConnection;
                    longestConnection.start = {
                      x: currentArrows[index].children[i].x + 30,
                      y: currentArrows[index].children[i].y + 30,
                    };
                    longestConnection.end = { ...currentArrows[index].end.attrs };
                  }
                } else {
                  if (i > 0 && currentArrows[index].children[i] && currentArrows[index].children[i - 1]) {
                    currentConnection =
                      Math.abs(currentArrows[index].children[i].x - currentArrows[index].children[i - 1].x) +
                      Math.abs(currentArrows[index].children[i].y - currentArrows[index].children[i - 1].y);
                  } else {
                    console.error("Invalid index or undefined children for currentArrows:", i);
                  }
                  if (currentConnection > longestConnectionWidth) {
                    longestConnectionWidth = currentConnection;
                    longestConnection.start = {
                      x: currentArrows[index].children[i].x + 30,
                      y: currentArrows[index].children[i].y + 30,
                    };
                    longestConnection.end = currentArrows[index].children[i - 1];
                  }
                }
              }

              const isConnectionVertical =
                Math.abs(longestConnection.end.y - longestConnection.start.y) >
                Math.abs(longestConnection.end.x - longestConnection.start.x);
              let text1Pos = { x: 0, y: 0 };
              let text2Pos = { x: 0, y: 0 };
              if (isConnectionVertical) {
                text1Pos = {
                  x:
                    Math.max(longestConnection.end.x, longestConnection.start.x) -
                    Math.abs(longestConnection.end.x - longestConnection.start.x) +
                    35,
                  y:
                    Math.max(longestConnection.end.y, longestConnection.start.y) -
                    Math.abs(longestConnection.end.y - longestConnection.start.y) / 2 +
                    15,
                };
                text2Pos = {
                  x:
                    Math.max(longestConnection.end.x, longestConnection.start.x) -
                    Math.abs(longestConnection.end.x - longestConnection.start.x) +
                    35,
                  y:
                    Math.max(longestConnection.end.y, longestConnection.start.y) -
                    Math.abs(longestConnection.end.y - longestConnection.start.y) / 2 +
                    40,
                };
              } else {
                text1Pos = {
                  x:
                    Math.max(longestConnection.end.x, longestConnection.start.x) -
                    Math.abs(longestConnection.end.x - longestConnection.start.x) / 2 -
                    getTextWidth(text1),
                  y:
                    Math.max(longestConnection.end.y, longestConnection.start.y) -
                    Math.abs(longestConnection.end.y - longestConnection.start.y) / 2 -
                    20,
                };
                text2Pos = {
                  x:
                    Math.max(longestConnection.end.x, longestConnection.start.x) -
                    Math.abs(longestConnection.end.x - longestConnection.start.x) / 2 -
                    getTextWidth(text2),
                  y:
                    Math.max(longestConnection.end.y, longestConnection.start.y) -
                    Math.abs(longestConnection.end.y - longestConnection.start.y) / 2 +
                    5,
                };
              }

              return currentArrows[index].children.map((child, child_i) => {
                const shapeRef = React.createRef<Konva.Rect>();
                return (
                  <Group key={child_i + "_helper_ " + index}>
                    {child_i === 0 && (
                      <Line
                        points={[shapeBounds.startPos.x, shapeBounds.startPos.y, child.x + 10 / 2, child.y + 10 / 2]}
                        strokeWidth={5}
                        stroke="rgb(255,0,0)"
                        onTap={clickBehavior}
                        onClick={clickBehavior}
                      />
                    )}
                    {child_i < currentArrows[index].children.length - 1 && (
                      <Line
                        points={[
                          child.x + 10 / 2,
                          child.y + 10 / 2,
                          currentArrows[index].children[child_i + 1].x,
                          currentArrows[index].children[child_i + 1].y,
                        ]}
                        strokeWidth={5}
                        stroke="rgb(255,0,0)"
                        onTap={clickBehavior}
                        onClick={clickBehavior}
                      />
                    )}
                    {child_i === currentArrows[index].children.length - 1 && (
                      <>
                        <Arrow
                          points={[child.x + 10 / 2, child.y + 10 / 2, shapeBounds.endPos.x, shapeBounds.endPos.y]}
                          strokeWidth={5}
                          stroke="rgb(255,0,0)"
                          onTap={clickBehavior}
                          onClick={clickBehavior}
                        />
                        <Text text={text1} x={text1Pos.x} y={text1Pos.y} fontSize={16} verticalAlign="bottom" fill={"#FFF"} />
                        {canEdit && <Text text={text2} x={text2Pos.x} y={text2Pos.y} fontSize={16} verticalAlign="bottom" fill={"#FFF"} />}
                      </>
                    )}

                    <Rect
                      x={child.x}
                      className="helpSquare"
                      y={child.y}
                      onClick={(evt: Konva.KonvaEventObject<MouseEvent>) => {
                        if (disabled) return;
                        if (evt.evt.ctrlKey) {
                          let arrayCopy = deepCopy(currentArrows);
                          arrayCopy[index].children.splice(child_i, 1);
                          setCurrentArrows(arrayCopy);
                        }
                      }}
                      ref={shapeRef}
                      width={10}
                      height={10}
                      fill={arrowStart ? "rgba(0,0,0,.2)" : "rgb(255,0,0)"}
                      shadowBlur={5}
                      draggable
                      onDragMove={(evt: Konva.KonvaEventObject<DragEvent>) => {
                        const arrowsCopy = [...currentArrows];
                        arrowsCopy[index].children[child_i] = {
                          x: shapeRef.current.attrs.x,
                          y: shapeRef.current.attrs.y,
                        };
                        setCurrentArrows(arrowsCopy);
                        calculateAndSetAlignmentAid(shapeRef, shapeRefs, setAlignmentGuide);
                      }}
                      onDragEnd={() => {
                        alignShape(shapeRef, alignmentGuide, setAlignmentGuide);
                        const arrowsCopy = [...currentArrows];
                        arrowsCopy[index].children[child_i] = {
                          x: shapeRef.current.attrs.x,
                          y: shapeRef.current.attrs.y,
                        };
                        setCurrentArrows(arrowsCopy);
                      }}
                    />
                  </Group>
                );
              });
            }
          })}
        </Layer>
        <Layer>
          <ColoredRect
            sourceTitle={sourceTitle}
            index={0}
            shapeRefs={shapeRefs}
            stageScaleAndPosition={stageScaleAndPosition}
            alignmentGuide={alignmentGuide}
            setAlignmentGuide={setAlignmentGuide}
            width={width}
            type="rect"
            currentArrows={currentArrows}
            setCurrentArrows={setCurrentArrows}
            jointPoints={jointPoints}
            setJointPoints={setJointPoints}
            arrowStart={arrowStart}
            setArrowStart={setArrowStart}
            x={150}
            y={20}
            text={sourceTitle}
            color={"rgba(95,95,95,255)"}
            onDragStart={handleDragStart}
            disabled={disabled}
          />
          {Object.keys(pePcLayout).map((value, index: number) => {
            return (
              <ColoredRect
                sourceTitle={sourceTitle}
                index={index + 1}
                shapeRefs={shapeRefs}
                stageScaleAndPosition={stageScaleAndPosition}
                alignmentGuide={alignmentGuide}
                setAlignmentGuide={setAlignmentGuide}
                width={width}
                key={"room_" + index}
                type="rect"
                currentArrows={currentArrows}
                setCurrentArrows={setCurrentArrows}
                jointPoints={jointPoints}
                setJointPoints={setJointPoints}
                arrowStart={arrowStart}
                setArrowStart={setArrowStart}
                x={index * 100 + 150 < width ? index * 100 + 150 : 150}
                y={100}
                text={pePcLayout[value]["nickname"]}
                color={"rgba(27,130,153,255)"}
                onDragStart={handleDragStart}
                disabled={disabled}
              />
            );
          })}
          {jointPoints.map(({ index, x, y }) => {
            return (
              <ColoredRect
                sourceTitle={sourceTitle}
                index={index + 1 + Object.keys(pePcLayout).length}
                shapeRefs={shapeRefs}
                stageScaleAndPosition={stageScaleAndPosition}
                alignmentGuide={alignmentGuide}
                setAlignmentGuide={setAlignmentGuide}
                width={width}
                key={"joint_ " + index}
                type="circle"
                currentArrows={currentArrows}
                setCurrentArrows={setCurrentArrows}
                jointPoints={jointPoints}
                setJointPoints={setJointPoints}
                arrowStart={arrowStart}
                setArrowStart={setArrowStart}
                x={x}
                y={y}
                text={"D" + index}
                color={"rgba(191,48,18,255)"}
                onDragStart={handleDragStart}
                disabled={disabled}
              />
            );
          })}
        </Layer>
      </Stage>
      {isArrowPopupOpen && (
        <ArrowPopup
          connectionStart={isArrowPopupOpen.arrowStart}
          connectionEnd={isArrowPopupOpen.arrowEnd}
          pepc_connections={pePcConnections}
          canEdit={canEdit}
          defaults={defaults}
          pePcConnectionsAdjustedDiameter={pePcConnectionsAdjustedDiameter}
          pePcConnectionsRe={pePcConnectionsRe}
          project={project}
          setPePcConnectionsRe={setPePcConnectionsRe}
          setProject={setProject}
          t={t}
          setIsArrowPopupOpen={setIsArrowPopupOpen}
          setPePcConnectionsAdjustedDiameter={setPePcConnectionsAdjustedDiameter}
        />
      )}
      {/* <Box display="flex" justifyContent="flex-end">
        {
          // @ts-ignore
          <Box
            maxWidth="250px"
            paddingRight={2}
            marginLeft={5}
            p="5px"
            display="flex"
            borderRadius="4px"
            backgroundColor={colors.blueAccent[600]}
            alignSelf="end"
            sx={{
              cursor: "pointer",
              textDecoration: "none",
            }}
            onClick={() => {
              setStageScaleAndPosition(
                calculateStageScaleAndPosition(refEditor.current, fullDiagramDescription(refEditor.current, currentArrows), width)
              );
            }}
          >
            <PreviewOutlinedIcon sx={{ textDecoration: "none", color: colors.grey[100] }} />

            <Typography color={colors.grey[100]} sx={{ ml: "5px", textDecoration: "none", mt: "1px" }}>
              Reset zoom
            </Typography>
          </Box>
        }
      </Box> */}



      <Box

        position="absolute" // Position the component relative to the parent container (the stage)
        top={window.innerHeight / 2.1} // Adjust top and left values as needed
        left={window.innerWidth / 1.4}
        maxWidth="250px"
        padding="5px"
        display="flex"
        borderRadius="4px"
        backgroundColor={colors.blueAccent[600]}
        zIndex="1000" // Set a high z-index to ensure it's on top
        // alignSelf="end"
        // marginLeft={window.innerWidth}
        // maxWidth="250px"
        // p="5px 7px 5px 5px"
        // display="flex"
        // justifyContent="flex-end"
        // alignItems="center"
        // borderRadius="4px"
        // backgroundColor={colors.blueAccent[600]}
        sx={{
          cursor: "pointer",
          textDecoration: "none",
        }}
        onClick={() => {
          setStageScaleAndPosition(
            calculateStageScaleAndPosition(refEditor.current, fullDiagramDescription(refEditor.current, currentArrows), width)
          );
        }}
      >
        <PreviewOutlinedIcon
          sx={{
            textDecoration: "none",
            color: colors.grey[100],
            justifyContent: "center",
          }}
        />
        <Typography
          variant="h3"
          justifyContent="center"
          color={colors.grey[100]}
          sx={{
            ml: "5px",
            textDecoration: "none",
            fontWeight: "bold",
            justifyContent: "center",
            marginTop: "1px",
          }}
        >
          {t("components.konva.reset_zoom")}
        </Typography>
      </Box>
    </div>
  );
};

export default KonvaEditor;

const ArrowPopup = ({
  connectionStart,
  connectionEnd,
  canEdit,
  project,
  setProject,
  t,
  pepc_connections,
  pePcConnectionsAdjustedDiameter,
  defaults,
  pePcConnectionsRe,
  setPePcConnectionsRe,
  setPePcConnectionsAdjustedDiameter,
  setIsArrowPopupOpen,
}: {
  connectionStart: string;
  connectionEnd: string;
  canEdit: boolean;
  project: any;
  setProject: (project) => void;
  t: TFunction<"translation", undefined, "translation">;
  pepc_connections: any;
  pePcConnectionsAdjustedDiameter: any;
  defaults: any;
  pePcConnectionsRe: any;
  setPePcConnectionsRe: (newPePcConnectionsRe) => void;
  setPePcConnectionsAdjustedDiameter: (newPePcConnectionsAdjustedDiameter) => void;
  setIsArrowPopupOpen: (newIsArrowPopupOpen) => void;
}) => {
  const popupWidthPercentages = 0.25;
  const popupHeightPercentages = 0.75;

  return (
    <div
      style={{
        display: "flex",
        position: "absolute",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        left: ((1 - popupWidthPercentages) / 2) * 100 + "%",
        bottom: ((1 - popupHeightPercentages) / 2) * 100 + "%",
        width: "25%",
        height: stageHeight * 0.75,
        backgroundColor: "black",
      }}
    >
      <div
        style={{
          display: "flex",
          position: "relative",
          width: "100%",
        }}
      >
        <button
          style={{
            position: "absolute",
            right: "1%",
            top: "2%",
            borderRadius: 50,
            backgroundColor: "transparent",
          }}
          onClick={() => setIsArrowPopupOpen(undefined)}
        >
          <p
            style={{
              color: "white",
              fontSize: 20,
              margin: 0,
            }}
          >
            x
          </p>
        </button>
      </div>
      <p
        style={{
          color: "white",
          textAlign: "center",
        }}
      >
        {connectionStart + "->" + connectionEnd}
      </p>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <input
          disabled={!canEdit}
          className="editProjectFieldInput"
          style={{
            height: 30,
          }}
          value={
            pepc_connections &&
            pepc_connections[connectionStart] &&
            pepc_connections[connectionStart][connectionEnd] &&
            pepc_connections[connectionStart][connectionEnd].width != null
              ? pepc_connections[connectionStart][connectionEnd].width
              : 5
          }
          type="number"
          step="0.1"
          onChange={(e) => {
            const connectionsCopy = {
              ...pepc_connections,
            };
            if (!(connectionStart in connectionsCopy)) connectionsCopy[connectionStart] = {};
            if (
              !(connectionEnd in connectionsCopy[connectionStart]) ||
              typeof pepc_connections[connectionStart][connectionEnd] === "number"
            )
              connectionsCopy[connectionStart][connectionEnd] = {};
            connectionsCopy[connectionStart][connectionEnd].width = parseFloat(e.target.value);
            setProject({
              ...project,
              pepc_connections: {
                ...connectionsCopy,
              },
            });
          }}
        />
        <p
          style={{
            paddingLeft: 20,
            margin: 0,
          }}
        >
          {t("components.konva.meters")}
        </p>
      </div>

      <div className="projectFieldContainer">
        <p
          className="projectFieldTitle"
          style={{
            paddingLeft: 20,
            paddingTop: 10,
          }}
        >
          {t("components.konva.adjusted_diameter")}
        </p>
        <div className="projectSectionContainer">
          <select
            disabled={!canEdit}
            className="projectFieldInput"
            value={
              pePcConnectionsAdjustedDiameter
                ? pePcConnectionsAdjustedDiameter[connectionStart]
                  ? pePcConnectionsAdjustedDiameter[connectionStart][connectionEnd]
                    ? pePcConnectionsAdjustedDiameter[connectionStart][connectionEnd]
                    : 16
                  : 16
                : 16
            }
            onChange={(e) => {
              const adjustedDiameterConnectionsCopy = {
                ...pePcConnectionsAdjustedDiameter,
              };
              if (!(connectionStart in adjustedDiameterConnectionsCopy)) adjustedDiameterConnectionsCopy[connectionStart] = {};
              adjustedDiameterConnectionsCopy[connectionStart][connectionEnd] = parseFloat(e.target.value);
              setPePcConnectionsAdjustedDiameter(adjustedDiameterConnectionsCopy);
            }}
          >
            {/* ESCONDIDO */}
            {Object.values(defaults ? defaults.tipos_tubagem[project["tipo_tubo"]] : {}).map((tubo, index) => {
              return <option key={"heat_system_" + index}>{tubo.diametro}</option>;
            })}
          </select>
        </div>

        <div className="projectFieldContainer">
          <p
            className="projectFieldTitle"
            style={{
              paddingLeft: 20,
              paddingTop: 0,
            }}
          >
            {t("components.konva.has_recirculation")}
          </p>
          <div className="projectSectionContainer">
            <select
              className="projectFieldInput"
              value={
                connectionStart in pePcConnectionsRe && connectionEnd in pePcConnectionsRe[connectionStart]
                  ? pePcConnectionsRe[connectionStart][connectionEnd]
                  : 0
              }
              onChange={(e) => {
                const pePcConnectionsReCopy = {
                  ...pePcConnectionsRe,
                };
                pePcConnectionsReCopy[connectionStart][connectionEnd] = parseInt(e.target.value);
                setPePcConnectionsRe(pePcConnectionsReCopy);
              }}
            >
              <option value={1}>{t("general.yes")}</option>
              <option value={0}>{t("general.no")}</option>
            </select>
          </div>
        </div>
      </div>
    </div>
  );
};

const calculateAndSetAlignmentAid = (
  shapeRef: React.MutableRefObject<Konva.Group | Konva.Rect | undefined>,
  shapeRefs: React.MutableRefObject<Konva.Group[]>,
  setAlignmentGuide: React.Dispatch<
    React.SetStateAction<
      {
        p1: Konva.Group;
        p2: Konva.Group;
        xDistance: number;
        yDistance: number;
      }[]
    >
  >
) => {
  const alignmentGuideRes = [];
  const xThreshold = 50;
  const yThreshold = 50;
  if (!shapeRef.current) return;

  const currentCenterX =
    shapeRef.current instanceof Konva.Group
      ? shapeRef.current.x() +
        (shapeRef.current.children[0].attrs.radius
          ? shapeRef.current.children[0].attrs.radius / 2
          : shapeRef.current.children[0].attrs.width / 2)
      : shapeRef.current.attrs.x + (shapeRef.current.attrs.radius ? shapeRef.current.attrs.radius / 2 : shapeRef.current.attrs.width / 2);

  const currentCenterY =
    shapeRef.current instanceof Konva.Group
      ? shapeRef.current.y() +
        (shapeRef.current.children[0].attrs.radius
          ? shapeRef.current.children[0].attrs.radius / 2
          : shapeRef.current.children[0].attrs.height / 2)
      : shapeRef.current.attrs.y + (shapeRef.current.attrs.radius ? shapeRef.current.attrs.radius / 2 : shapeRef.current.attrs.height / 2);

  let closestXDistance = xThreshold; // Track the closest X distance
  let closestYDistance = yThreshold; // Track the closest Y distance
  let closestXObject = null; // Track the closest object in the X direction
  let closestYObject = null; // Track the closest object in the Y direction

  shapeRefs.current.forEach((groupRef) => {
    if (groupRef === shapeRef.current) {
      return; // Skip comparing with itself
    }

    const centerX =
      groupRef.attrs.x + (groupRef.children[0].attrs.radius ? groupRef.children[0].attrs.radius / 2 : groupRef.children[0].attrs.width / 2);
    const centerY =
      groupRef.attrs.y +
      (groupRef.children[0].attrs.radius ? groupRef.children[0].attrs.radius / 2 : groupRef.children[0].attrs.height / 2);
    const xDistance = Math.abs(currentCenterX - centerX);
    const yDistance = Math.abs(currentCenterY - centerY);

    const xDistanceToClosestYObject = Math.abs(
      currentCenterX -
        (closestYObject !== null
          ? closestYObject.attrs.x +
            (closestYObject.children[0].attrs.radius
              ? closestYObject.children[0].attrs.radius / 2
              : closestYObject.children[0].attrs.width / 2)
          : NaN)
    );
    const yDistanceToClosestXObject = Math.abs(
      currentCenterY -
        (closestXObject !== null
          ? closestXObject.attrs.y +
            (closestXObject.children[0].attrs.radius
              ? closestXObject.children[0].attrs.radius / 2
              : closestXObject.children[0].attrs.height / 2)
          : NaN)
    );

    if (xDistance !== 0 && xDistance < xThreshold) {
      if (
        xDistance <= closestXDistance &&
        (closestXObject === null || (!isNaN(yDistanceToClosestXObject) && yDistance < yDistanceToClosestXObject))
      ) {
        closestXDistance = xDistance;
        closestXObject = groupRef;
      }
    }

    if (yDistance !== 0 && yDistance < yThreshold) {
      if (
        yDistance <= closestYDistance &&
        (closestYObject === null || (!isNaN(xDistanceToClosestYObject) && xDistance < xDistanceToClosestYObject))
      ) {
        closestYDistance = yDistance;
        closestYObject = groupRef;
      }
    }
  });

  // Add the closest objects to the alignmentGuideRes array, if they exist
  if (closestXObject) {
    alignmentGuideRes.push({
      p1: shapeRef.current,
      p2: closestXObject,
      xDistance: closestXDistance,
      yDistance: 0, // Set yDistance to 0 as we are aligning in X direction
    });
  }

  if (closestYObject) {
    alignmentGuideRes.push({
      p1: shapeRef.current,
      p2: closestYObject,
      xDistance: 0, // Set xDistance to 0 as we are aligning in Y direction
      yDistance: closestYDistance,
    });
  }

  setAlignmentGuide(alignmentGuideRes);
};

const alignShape = (
  shapeRef: React.MutableRefObject<Konva.Group | Konva.Rect | undefined>,
  alignmentGuide: {
    p1: Konva.Group;
    p2: Konva.Group;
    xDistance: number;
    yDistance: number;
  }[],
  setAlignmentGuide: React.Dispatch<
    React.SetStateAction<
      {
        p1: Konva.Group;
        p2: Konva.Group;
        xDistance: number;
        yDistance: number;
      }[]
    >
  >
) => {
  if (!shapeRef.current) return;
  // the allignment guide is an array of at most 2 objects containing point1 (the same as shapeRef) and point2 the one that is close enought to be aligned with
  // the xDistance and yDistance are the distances between the two points
  // if the xDistance is lower than the yDistance then the two points are aligned in the x axis
  // if the yDistance is lower than the xDistance then the two points are aligned in the y axis

  if (!alignmentGuide) return;
  alignmentGuide.forEach((guide) => {
    if (guide.xDistance < guide.yDistance) {
      if (guide.p1.attrs.width && guide.p2.children[0].attrs) {
        if (guide.p2.children[0].attrs.radius) guide.p1.y(guide.p2.y() + guide.p2.children[0].attrs.radius / 2 - guide.p1.attrs.height / 2);
        else if (guide.p2.children[0].attrs.height)
          guide.p1.y(guide.p2.y() + guide.p2.children[0].attrs.height / 2 - guide.p1.attrs.height / 2);
      } else if (
        (guide.p1.children[0].attrs.height && guide.p2.children[0].attrs.height) ||
        (guide.p1.children[0].attrs.radius && guide.p2.children[0].attrs.radius)
      )
        guide.p1.y(guide.p2.y());
      else if (guide.p1.children[0].attrs.radius && guide.p2.children[0].attrs.height)
        guide.p1.y(guide.p2.y() + guide.p2.children[0].attrs.height / 2 - guide.p1.children[0].attrs.radius / 2);
      else {
        const p2Y = guide.p2.y() + guide.p2.children[0].attrs.radius / 2 - guide.p1.children[0].attrs.height / 2;
        guide.p1.y(p2Y);
      }
    } else {
      if (guide.p1.attrs.width) {
        if (guide.p2.children[0].attrs.radius) guide.p1.x(guide.p2.x() + guide.p2.children[0].attrs.radius / 2 - guide.p1.attrs.width / 2);
        else if (guide.p2.children[0].attrs.width)
          guide.p1.x(guide.p2.x() + guide.p2.children[0].attrs.width / 2 - guide.p1.attrs.width / 2);
      } else if (guide.p1.children[0].attrs.width && guide.p2.children[0].attrs.width)
        guide.p1.x(guide.p2.x() + guide.p2.children[0].attrs.width / 2 - guide.p1.children[0].attrs.width / 2);
      else if (guide.p1.children[0].attrs.radius && guide.p2.children[0].attrs.radius) guide.p1.x(guide.p2.x());
      else if (guide.p1.children[0].attrs.radius && guide.p2.children[0].attrs.width)
        guide.p1.x(guide.p2.x() + guide.p2.children[0].attrs.width / 2 - guide.p1.children[0].attrs.radius / 2);
      else {
        const p2X = guide.p2.x() + guide.p2.children[0].attrs.radius / 2 - guide.p1.children[0].attrs.width / 2;
        guide.p1.x(p2X);
      }
    }
  });
  setAlignmentGuide(null);
};
