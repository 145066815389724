import { Group } from "konva/lib/Group";
import { Stage } from "konva/lib/Stage";
import { useLayoutEffect, useState } from "react";

export function inteceptCircleLineSeg(circle, line) {
  var b, c, d, u1, u2, retP1, retP2, v1, v2;
  var ret: { x: number, y: number }[] = []
  v1 = {};
  v2 = {};
  v1.x = line.p2.x - line.p1.x;
  v1.y = line.p2.y - line.p1.y;
  v2.x = line.p1.x - circle.center.x;
  v2.y = line.p1.y - circle.center.y;
  b = (v1.x * v2.x + v1.y * v2.y);
  c = 2 * (v1.x * v1.x + v1.y * v1.y);
  b *= -2;
  d = Math.sqrt(b * b - 2 * c * (v2.x * v2.x + v2.y * v2.y - circle.radius * circle.radius));
  if (isNaN(d)) { // no intercept
    return [];
  }
  u1 = (b - d) / c;  // these represent the unit distance of point one and two on the line
  u2 = (b + d) / c;
  retP1 = {};   // return points
  retP2 = {}
  ret = []; // return array
  if (u1 <= 1 && u1 >= 0) {  // add point if on the line segment
    retP1.x = line.p1.x + v1.x * u1;
    retP1.y = line.p1.y + v1.y * u1;
    ret[0] = retP1;
  }
  if (u2 <= 1 && u2 >= 0) {  // second add point if on the line segment
    retP2.x = line.p1.x + v1.x * u2;
    retP2.y = line.p1.y + v1.y * u2;
    ret[ret.length] = retP2;
  }
  return ret;
}


// line intercept math by Paul Bourke http://paulbourke.net/geometry/pointlineplane/
// Determine the intersection point of two line segments
// Return FALSE if the lines don't intersect
export function intersect(x1, y1, x2, y2, x3, y3, x4, y4) {

  // Check if none of the lines are of length 0
  if ((x1 === x2 && y1 === y2) || (x3 === x4 && y3 === y4)) {
    return false
  }

  let denominator = ((y4 - y3) * (x2 - x1) - (x4 - x3) * (y2 - y1))

  // Lines are parallel
  if (denominator === 0) {
    return false
  }

  let ua = ((x4 - x3) * (y1 - y3) - (y4 - y3) * (x1 - x3)) / denominator
  let ub = ((x2 - x1) * (y1 - y3) - (y2 - y1) * (x1 - x3)) / denominator

  // is the intersection along the segments
  if (ua < 0 || ua > 1 || ub < 0 || ub > 1) {
    return false
  }

  // Return a object with the x and y coordinates of the intersection
  let x = x1 + ua * (x2 - x1)
  let y = y1 + ua * (y2 - y1)
  if (isNaN(x) || isNaN(y)) return false;
  return { x, y }
}

export const fullDiagramDescription = (
  editorRef: Stage,
  currentArrows: {
    start: Group;
    children: { x: number; y: number }[];
    end: Group;
  }[]
) => {
  const pepc_layout_res = {}
  // the stage has 3 layers: the floors, the arrows and, on top, the divs and circles as groups of shapes and text
  if(!editorRef.children) return {
    pePcLayout: {},
    pePcConnections: [],
  }
  const divsAndCirclesLayer = editorRef.children[editorRef.children.length - 1];
  // in each layer a children is a group of both the shape and the text. add all circles to the pepc_layout_res
  let circle_i = 0;
  divsAndCirclesLayer.children.forEach((child, index) => {
    if (child.children[0].attrs.radius) {
      pepc_layout_res["circle" + circle_i] = {
        x: child.attrs.x,
        y: child.attrs.y,
        type: 'circle',
      }
      circle_i++;
    }
    else if (index === 0) {
      pepc_layout_res["source"] = {
        nickname: child.children[1].attrs.text,
        x: child.attrs.x,
        y: child.attrs.y,
        type: 'rect',
      }
    }
    else if (child.children[0].attrs.width) {
      pepc_layout_res[child.children[1].attrs.text] = {
        ...pepc_layout_res[child.children[1].attrs.text],
        x: child.attrs.x,
        y: child.attrs.y,
        type: 'rect',
      }
    }
  })
  // substitute the arrows start and end references with the name  in the text of the group
  const arrows_res = [];
currentArrows.forEach((arrow) => {
  if (arrow && arrow.start && arrow.start.children[1]) {
    arrows_res.push({
      start: arrow.start.children[1]?.attrs?.text,
      children: arrow.children,
      end: arrow.end && arrow.end.children[1]?.attrs?.text,
    });
  }
});

  return {
    pePcLayout: pepc_layout_res,
    pePcConnections: arrows_res,
  }
}

export const extractJointPoints = (pepc_layout: { [key: string]: { [key: string]: string } }): { index: number; x: number; y: number }[] => {
  const jointPoints = [];
  Object.keys(pepc_layout).forEach((key) => {
    if (pepc_layout[key].type === 'circle') {
      const jointIndex = Number(key.replace('circle', ''));
      jointPoints.push({
        index: jointIndex + 1,
        x: pepc_layout[key].x,
        y: pepc_layout[key].y,
      })
    }
  })
  return jointPoints;
}
export function useWindowSize(editorContainerRef, setKonvaWidth) {
  useLayoutEffect(() => {
    function updateSize() {
      if (editorContainerRef.current) {
        const boundingEditor = editorContainerRef.current;
        setKonvaWidth(boundingEditor.clientWidth);
        // setKonvaWidth(boundingEditor.clientWidth / 2);

        //CHANGE HERE
      // } else setKonvaWidth(0.81 * window.innerWidth);
      } else setKonvaWidth(0.5 * window.innerWidth);
    }
    window.addEventListener("resize", updateSize);
    updateSize();
    return () => window.removeEventListener("resize", updateSize);
  }, [editorContainerRef.current]);
}
export const calculateStageScaleAndPosition = (
  editorRef: Stage,
  loadFromSavedObject: {
    pePcLayout: { [key: string]: { [key: string]: string } };
    pePcConnections: {
      start: string;
      children: { x: number; y: number }[];
      end: string;
    }[];
  },
  width: number,
) => {
      if(!editorRef.children ||
          Object.keys(loadFromSavedObject.pePcLayout).length === 0 ||
          loadFromSavedObject.pePcConnections.length === 0
        ) return {
        stageScale: 1,
        stageX: 0,
        stageY: 0,
      }
      // use the arrowConnections children and the pepcLayout to
      const allDiagramElements = Object.values(loadFromSavedObject.pePcLayout).map((e) => ({
        x: Number(e.x),
        y: Number(e.y)
      }))

      const connectionChildren = [];
      for (let i = 0; i < loadFromSavedObject.pePcConnections.length; i++) {
        const arrow = loadFromSavedObject.pePcConnections[i];
        connectionChildren.push(...arrow.children);
      }
      allDiagramElements.concat(connectionChildren);
      const maxX = Math.max(...allDiagramElements.map((e) => e.x));
      const maxY = Math.max(...allDiagramElements.map((e) => e.y));

      const stage = editorRef;
      const stageWidth = width;
      const stageHeight = stage.height();

      //CHANGE HERE
      // const stageScale = Math.min(stageWidth / (maxX + 100), stageHeight / (maxY + 100));
      const stageScale = Math.min(stageWidth / (maxX + 100), stageHeight / (maxY + 200));

      //CHANGE HERE
      // const stageX = (stageWidth - maxX * stageScale) / 2;
      // const stageY = (stageHeight - maxY * stageScale) / 2;
      const stageX = (stageWidth - maxX * stageScale) / 7;
      const stageY = (stageHeight - maxY * stageScale) / 7;

      return ({
        stageScale,
        stageX,
        stageY
      })
}