import React, { useEffect, useMemo, useRef, useState, useContext } from "react";
import * as htmlToImage from "html-to-image";
import "./App.css";
import "./Report.css";
import "./Print.css";
import { useLocation, useParams, useNavigate } from "react-router-dom";
import MatrixTable, { Styles } from "../../components/MatrixTable";
import { defaults_t } from "../../types";
import { useTranslation } from "react-i18next";
import axios from "axios";
import { tokens } from "../../theme";
import ArrowBackOutlinedIcon from "@mui/icons-material/ArrowBackOutlined";
import DeleteOutlinedIcon from "@mui/icons-material/DeleteOutlined";
import SendOutlinedIcon from "@mui/icons-material/SendOutlined";
import KeyboardDoubleArrowLeftOutlinedIcon from "@mui/icons-material/KeyboardDoubleArrowLeftOutlined";
import Header from "../../components/Header";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  MenuItem,
  Select,
  FormControl,
  Box,
  Typography,
  useTheme,
  Grid,
  Fade,
  Alert,
  AlertTitle,
  TextField,
  Card,
  CardContent,
  CardHeader,
  Snackbar,
} from "@mui/material";
import { AuthContext } from "../../context/authContext";
import SourceSansProRegular from "../../fonts/SourceSansPro-Regular.otf";
import SourceSansProBold from "../../fonts/SourceSansPro-Bold.otf";
import Logo from "../../images/logo.png";
import html2canvas from "html2canvas";
import { jsPDF } from "jspdf";
import { Stage } from "konva/lib/Stage";
import KonvaEditor from "../../components/KonvaEditor";
import { useWindowSize } from "../../utils/konvaUtils";
import Konva from "konva";
import DvrOutlinedIcon from "@mui/icons-material/DvrOutlined";
import LoadingScreen from "../global/LoadingScreen";
import PictureAsPdfOutlinedIcon from "@mui/icons-material/PictureAsPdfOutlined";
import * as XLSX from "xlsx";
import { Page, Text, View, Document, StyleSheet, Image, PDFDownloadLink, Font } from "@react-pdf/renderer";

function transformDictionary(input) {
  const output = {};

  for (const key in input) {
    if (input[key] && typeof input[key] === "object") {
      output[key] = transformDictionary(input[key]);
    } else if (key === "adjustedDiameter") {
      return input[key];
    }
  }

  return output;
}

const isStringImage = (value: string) => {
  return value.startsWith("data:image/png;base64");
};

function ScenarioReport(props) {
  const { currentUser, verifyUser, logout } = useContext(AuthContext);
  const [currentLicense, setCurrentLicense] = useState(null);

  const config = {
    headers: {
      "access-token": process.env.REACT_APP_ACCESS_TOKEN,
      "Content-Type": "application/json", // Adjust the content type if needed
    },
    withCredentials: true,
  };

  const verifyLicense = async () => {
    try {
      const response = await axios.get(process.env.REACT_APP_API_CALL + `licenses/email/${currentUser?.email}`, config);

      if (response.data.success === 0) {
        setCurrentLicense(0);
      } else if (response.data.data[0].time === 0) {
        setCurrentLicense(1);
      } else if (response.data.data[0].active === 1) {
        setCurrentLicense(2);
      }
    } catch (error) {
      if (error.response.data.auth === false) {
        setSessionError(true);
      } else {
        setGeneralError(true);
        console.log(error);
        logout();
      }
    }
  };

  useEffect(() => {
    setIsLoading(true);
    window.scrollTo(0, 0);
    verifyUser(currentUser);
    if (!currentUser) {
      navigate("/login");
    } else {
      verifyLicense();
      if (currentLicense !== null && currentLicense !== 2) {
        navigate("/login");
      }
      // else if (currentUser.user_type === 1 || currentUser.user_type === 2 || currentUser.user_type === 3) {
      //   navigate("/");
      // }
    }
    setIsLoading(false);
  }, [currentLicense, currentUser]);

  const { t } = useTranslation();

  const editorContainerRef = useRef<HTMLInputElement>();
  const editorRef = useRef<Stage>();

  const [konvaWidth, setKonvaWidth] = useState(0);

  useWindowSize(editorContainerRef, setKonvaWidth);
  const [currentArrows, setCurrentArrows] = useState<
    {
      start: Konva.Group;
      children: { x: number; y: number }[];
      end: Konva.Group;
    }[]
  >([]);
  const [isLoading, setIsLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);
  const [selectedScenario, setSelectedScenario] = useState("cenarioBase");
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarOpenExcel, setSnackbarOpenExcel] = useState(false);
  const [snackbarOpenDelete, setSnackbarOpenDelete] = useState(false);
  const [defaults, setDefaults] = useState<defaults_t | null>(null);
  const [project, setProject] = useState<any>(null);
  const [generalError, setGeneralError] = useState(false);
  const [sessionError, setSessionError] = useState(false);
  const [hoterwayNumber, setHoterwayNumber] = useState(0);
  const [stagnateT, setStagnateT] = useState(0);
  const [waitingTime, setWaitingTime] = useState("0");
  const [waitingTimeSeconds, setWaitingTimeSeconds] = useState(0);
  const [division, setDivision] = useState("");
  const [numberHoterway, setNumberHoterway] = useState(null);
  const [hoterwayDivs, setHoterwayDivs] = useState("");
  const [showDiv, setShowDiv] = useState(false);
  const [numberRecirculation, setNumberRecirculation] = useState(null);

  const handleClickOpenDialog = () => {
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    localStorage.removeItem("cenarioBase");
    localStorage.removeItem("cenarioRetorno");
    localStorage.removeItem("cenarioHoterway");
    setOpenDialog(false);
    handleClose();
    setSnackbarOpenDelete(true);
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleScenarioChange = (event) => {
    setSelectedScenario(event.target.value);
  };

  let number_hoterway = 0;
  let number_recirculation = 0;
  let wait_times = 0;

  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const navigate = useNavigate();

  const prop_id = (useLocation().state as { project_id: string })?.project_id as string;
  const { idScenario } = useParams();
  const params = idScenario ? idScenario : prop_id;

  const getDefaults = async () => {
    try {
      const response = await axios.get(process.env.REACT_APP_API_CALL + "defaults", config).then();
      setDefaults(response.data.data[0].default_values);
    } catch (error) {
      if (error.response.data.auth === false) {
        setSessionError(true);
      } else {
        setGeneralError(true);
        console.log(error);
      }
    }
  };

  useEffect(() => {
    getDefaults();
  }, []);

  if (project && project !== undefined) {
    if (
      !Object.keys(project.pepc_layout).every((elem) =>
        Object.keys(project.pepc_layout[elem]).some((other_elen) => other_elen.startsWith("notRecommended"))
      )
    ) {
      let pepc_layout_copy = { ...project.pepc_layout };
      Object.keys(pepc_layout_copy).forEach((layout_key) => {
        if (!("notRecommended" in Object.keys(pepc_layout_copy[layout_key]))) pepc_layout_copy[layout_key]["notRecommended"] = false;
      });
      setProject({ ...project, pepc_layout: pepc_layout_copy });
    }
  }
  const getDivMinCaudal = (children: string) => {
    const div_key = Object.keys(project.pepc_layout).find(
      (key) => (project.pepc_layout[key]["nickname"] ? project.pepc_layout[key]["nickname"] : project.pepc_layout[key]["name"]) === children
    );
    let res = 100000;
    if (div_key) {
      res = project.pepc_layout[div_key]["caudal_minimo"];
    }
    return res;
  };
  const getDivTotalCaudal = (children: string) => {
    const div_key = Object.keys(project.pepc_layout).find(
      (key) => (project.pepc_layout[key]["nickname"] ? project.pepc_layout[key]["nickname"] : project.pepc_layout[key]["name"]) === children
    );
    let res = 0;
    if (div_key) {
      if (isNaN(parseFloat(project?.pepc_layout[div_key]["caudal_total"])))
        console.log("error:" + project?.pepc_layout[div_key]["caudal_total"]);
      else res += parseFloat(project?.pepc_layout[div_key]["caudal_total"]);
    }

    return res;
  };
  const getChildren = (connectionStart: string) => {
    let res = [];
    if (connectionStart in project?.pepc_connections)
      for (let connectionEnd of Object.keys(project?.pepc_connections[connectionStart])) {
        const children = getChildren(connectionEnd);
        res = [...res, ...children];
      }
    else return [connectionStart];
    return res;
  };
  const getTubeStatsByDiamenter = (diametro: number, tipo_tubo) => {
    if (defaults === null) window.location.reload();
    if (tipo_tubo in defaults?.tipos_tubagem) {
      for (let stats of defaults?.tipos_tubagem[tipo_tubo]) {
        if (stats.diametro === diametro) return stats;
      }
    }
    return null;
  };

  const getAdjustedDiameter = (caudal, tipo_tubo) => {
    if (defaults === null) window.location.reload();
    if (defaults && tipo_tubo in defaults?.tipos_tubagem) {
      for (let stats of defaults?.tipos_tubagem[tipo_tubo]) {
        if (stats.max_caudal >= caudal) return stats;
      }
    }
    return null;
  };
  const divs_table_columns = useMemo(
    () => [
      {
        Header: t("scenario_report.columns.divs_table_columns.header"),
        columns: [
          {
            Header: t("scenario_report.columns.divs_table_columns.nickname"),
            accessor: "nickname",
          },
          {
            Header: t("scenario_report.columns.divs_table_columns.agua_estagnada"),
            accessor: "agua_estagnada",
          },
          {
            Header: t("scenario_report.columns.divs_table_columns.espera"),
            accessor: "espera",
          },
          // {
          //   Header: t("scenario_report.columns.divs_table_columns.hoterway"),
          //   accessor: "hoterway",
          // },
        ],
      },
    ],
    []
  );

  const connections_table_columns = useMemo(
    () => [
      {
        Header: t("scenario_report.columns.connections_table_columns.header"),
        columns: [
          { Header: t("scenario_report.columns.connections_table_columns.ligacao"), accessor: "ligacao" },
          {
            Header: t("scenario_report.columns.connections_table_columns.comprimento"),
            accessor: "comprimento",
          },
          {
            Header: "Diâmetro Exterior Tubagem (mm)",
            accessor: "diametro_ajustado",
          },
          {
            Header: "Diâmetro Interior Tubagem (mm)",
            accessor: "diametro_interior",
          },
        ],
      },
    ],
    []
  );

  const connections_table_columns2 = useMemo(
    () => [
      {
        Header: t("scenario_report.columns.connections_table_columns2.header"),
        columns: [
          { Header: t("scenario_report.columns.connections_table_columns2.ligacao"), accessor: "ligacao" },
          { Header: t("scenario_report.columns.connections_table_columns2.caudal"), accessor: "caudal" },
          {
            Header: t("scenario_report.columns.connections_table_columns2.agua_estagnada"),
            accessor: "agua_estagnada",
          },
          // {
          //   Header: t("scenario_report.columns.connections_table_columns2.perda_termica"),
          //   accessor: "perda_termica",
          // },
          {
            Header: t("scenario_report.columns.connections_table_columns2.coef_simultaniedade"),
            accessor: "coef_simultaniedade",
          },
          // {
          //   Header: t("scenario_report.columns.connections_table_columns2.recirculacao"),
          //   accessor: "recirculacao",
          // },
        ],
      },
    ],
    []
  );



  function getBackgroundColor(certificate_class) {
    switch (certificate_class) {
      case 0:
        return "#13a24b";
      case 1:
        return "#45a246";
      case 2:
        return "#78ac41";
      case 3:
        return "#b2c834";
      case 4:
        return "#dbdf24";
      case 5:
        return "#f5eb21";
      case 6:
        return "#dca925";
      case 7:
        return "#c33d27";
      default:
        return colors.blueAccent[700];
    }
  }

  function getCertificateText(certificate_class) {
    switch (certificate_class) {
      case 0:
        return "A+";
      case 1:
        return "A";
      case 2:
        return "B";
      case 3:
        return "B-";
      case 4:
        return "C";
      case 5:
        return "D";
      case 6:
        return "E";
      case 7:
        return "F";
      default:
        return "Erro";
    }
  }

  let expectedEnergy;

  function calculateFinalCertificate(certificate_class) {
    let consumo_pre = 0;
    let ratio = 0;
    switch (certificate_class) {
      case 0:
        // consumo_pre = project?.energy_reference * 0.125;
        consumo_pre = 150 * 0.125;
        break;
      case 1:
        // consumo_pre = project?.energy_reference * 0.375;
        consumo_pre = 150 * 0.375;
        break;
      case 2:
        // consumo_pre = project?.energy_reference * 0.625;
        consumo_pre = 150 * 0.625;
        break;
      case 3:
        // consumo_pre = project?.energy_reference * 0.875;
        consumo_pre = 150 * 0.875;
        break;
      case 4:
        // consumo_pre = project?.energy_reference * 1.25;
        consumo_pre = 150 * 1.25;
        break;
      case 5:
        // consumo_pre = project?.energy_reference * 1.75;
        consumo_pre = 150 * 1.75;
        break;
      case 6:
        // consumo_pre = project?.energy_reference * 2.25;
        consumo_pre = 150 * 2.25;
        break;
      case 7:
        // consumo_pre = project?.energy_reference * 3.0;
        consumo_pre = 150 * 3.0;
        break;
      default:
        consumo_pre = 0;
        break;
    }

    expectedEnergy = ((consumo_pre * project?.building_area) / 1000).toFixed(2);

    // ratio =
    //   ((consumo_pre * project?.building_area + parseFloat(energy_waste) * 1000) / (project?.energy_reference * project?.building_area)) *
    //   100;

    ratio = ((consumo_pre * project?.building_area + parseFloat(energy_waste) * 1000) / (150 * project?.building_area)) * 100;

    if (ratio > 0 && ratio < 25) {
      return 0;
    } else if (ratio > 25 && ratio <= 50) {
      return 1;
    } else if (ratio > 50 && ratio <= 75) {
      return 2;
    } else if (ratio > 75 && ratio <= 100) {
      return 3;
    } else if (ratio > 100 && ratio <= 150) {
      return 4;
    } else if (ratio > 150 && ratio <= 200) {
      return 5;
    } else if (ratio > 200 && ratio <= 250) {
      return 6;
    } else if (ratio > 250) {
      return 7;
    } else {
      return "Erro";
    }
  }

  function calculatePerdaTermica(insolation_width, tubo_stats, cct, ccppt, tubo_width, running_time) {
    switch (insolation_width) {
      case 0:
        return (tubo_stats.perda_termica_1 * cct * ccppt * tubo_width * running_time) / 24;
      case 1:
        return (tubo_stats.perda_termica_2 * cct * ccppt * tubo_width * running_time) / 24;
      case 2:
        return (tubo_stats.perda_termica_3 * cct * ccppt * tubo_width * running_time) / 24;
      default:
        return parseFloat((tubo_stats.perda_termica * tubo_width).toFixed(2));
    }
  }

  const calculateConnectionsAttributes = () => {
    if (!project || Object.keys(project.pepc_connections).length === 0) return [];
    const res = [];

    let agua_soma = 0;

    for (let connectionStart of Object.keys(project.pepc_connections)) {
      for (let connectionEnd of Object.keys(project.pepc_connections[connectionStart])) {
        const connectionEndChildren = getChildren(connectionEnd);
        const current_res: {
          ligacao: string;
          comprimento: number;
          coef_simultaniedade: number;
          caudal: number;
          diametro_ajustado: number;
          diametro_interior: number;
          agua_estagnada: number;
          perda_termica: number;
          recirculacao: string;
        } = {
          ligacao: "",
          comprimento: 0,
          coef_simultaniedade: 0,
          caudal: 0,
          diametro_ajustado: 0,
          diametro_interior: 0,
          agua_estagnada: 0,
          perda_termica: 0,
          recirculacao: "",
        };
        current_res.ligacao = connectionStart + " -> " + connectionEnd;
        current_res.coef_simultaniedade = 1 - 0.1 * (connectionEndChildren.length - 1);
        if (current_res.coef_simultaniedade < 0.4) current_res.coef_simultaniedade = 0.4;
        let caudal_total = 0;

        for (let child of connectionEndChildren) {
          caudal_total += getDivTotalCaudal(child);
        }

        current_res.caudal = current_res.coef_simultaniedade * caudal_total;

        let tubo_stats = getAdjustedDiameter(current_res.caudal, project.tipo_tubo);
        let tubo_width = 0;
        if (typeof project.pepc_connections[connectionStart][connectionEnd] === "number") {
          tubo_width = project.pepc_connections[connectionStart][connectionEnd];
          current_res.diametro_ajustado = tubo_stats.diametro;
        } else {
          current_res.diametro_ajustado = project.pepc_connections[connectionStart][connectionEnd].adjustedDiameter;
          tubo_stats = getTubeStatsByDiamenter(current_res.diametro_ajustado, project.tipo_tubo);
          tubo_width = project.pepc_connections[connectionStart][connectionEnd].width;
        }

        current_res.comprimento = tubo_width;

        if (tubo_stats) {
          var agua_estagnada_teorica = parseFloat((tubo_stats.agua_estagnada * tubo_width).toFixed(2));

          agua_soma += agua_estagnada_teorica;
          setStagnateT(agua_soma);

          if (project.pepc_connections[connectionStart][connectionEnd].recirculacao === 0) {
            current_res.agua_estagnada = parseFloat((tubo_stats.agua_estagnada * tubo_width).toFixed(2));

            number_recirculation += 1;
            setNumberRecirculation(number_recirculation);

            if (numberHoterway === null) {
              current_res.perda_termica = 0;
            } else {
              current_res.perda_termica = calculatePerdaTermica(
                project?.insolation_width,
                tubo_stats,
                project?.cct,
                project?.ccppt,
                tubo_width,
                project?.running_time_hoterway
              );
            }

            current_res.recirculacao = t("general.no");
          } else if (project.pepc_connections[connectionStart][connectionEnd].recirculacao === 1) {
            current_res.perda_termica = calculatePerdaTermica(
              project?.insolation_width,
              tubo_stats,
              project?.cct,
              project?.ccppt,
              tubo_width,
              project?.running_time
            );

            current_res.recirculacao = t("general.yes");
          } else {
            current_res.recirculacao = t("general.error");
          }
          if (tubo_width === 0) {
            current_res.comprimento = 0;
            current_res.coef_simultaniedade = 1;
            current_res.caudal = 0;
            current_res.diametro_ajustado = 0;
            current_res.agua_estagnada = 0;
            current_res.perda_termica = 0;
            current_res.recirculacao = "";
          }

          current_res.diametro_interior = (2 * Math.sqrt(tubo_stats.agua_estagnada / 1000 / Math.PI) * 1000).toFixed(1);

          res.push(current_res);
        }
      }
    }

    return res;
  };
  const connections_data = useMemo(() => calculateConnectionsAttributes(), [project]);

  const findPathToDiv = (start: string, end: string) => {
    let res = [];

    if (!(start in project.pepc_connections)) return [];
    if (end in project.pepc_connections[start]) return [[start, end]];
    for (let next_point of Object.keys(project.pepc_connections[start])) {
      const path_found = findPathToDiv(next_point, end);
      if (path_found.length !== 0) {
        res.push([start, next_point]);
        res = [...res, ...path_found];
      }
    }
    return res;
  };

  const calculateDivsAttributes = () => {
    const res = [];
    if (!project || Object.keys(project.pepc_connections).length === 0 || connections_data.length === 0) return [];
    for (let div of Object.keys(project.pepc_layout)) {
      const pathToDiv = findPathToDiv(
        project?.water_heating_system ?? "T0",
        project.pepc_layout[div]["nickname"] ? project.pepc_layout[div]["nickname"] : project.pepc_layout[div]["name"]
      );

      //RECUPERAR SE DER ASNEIRA
      // //console.log(
      //   project["water_heating_system"]
      //     .split(" ")
      //     .map((word) => word[0].toUpperCase())
      //     .join("")
      // );
      // //console.log(project.pepc_layout[div]["nickname"], pathToDiv);
      let agua_estagnada = 0;
      let none_div = false;
      for (let connection_to_div of pathToDiv) {
        for (let connection of connections_data) {
          if (connection.ligacao === connection_to_div[0] + " -> " + connection_to_div[1]) {
            agua_estagnada += connection.agua_estagnada;
            if (connection.comprimento === 0) none_div = true;
          }
        }
      }
      if (none_div) agua_estagnada = 0;
      const div_min_pc = getDivMinCaudal(
        project.pepc_layout[div]["nickname"] ? project.pepc_layout[div]["nickname"] : project.pepc_layout[div]["name"]
      );

      if (project.pepc_layout2[div]["hoterway"] === "1" || project.pepc_layout2[div]["hoterway"] === 1) {
        number_hoterway += 1;
        setNumberHoterway(number_hoterway);
        setHoterwayDivs((prevNames) => {
          const newNickname = project.pepc_layout2[div]["nickname"];

          // Convert the existing string into an array and check if the new nickname is already included
          const namesArray = prevNames.split(", ").filter(Boolean); // split and filter to remove empty values

          if (!namesArray.includes(newNickname)) {
            return prevNames ? `${prevNames}, ${newNickname}` : newNickname;
          }

          return prevNames; // If the value already exists, return the previous value
        });

        agua_estagnada = Math.max(agua_estagnada - 10, 0) + project?.volume_aqs;
      } else {
        agua_estagnada = agua_estagnada + project?.volume_aqs;
      }
      setHoterwayNumber(hoterwayNumber + 1);
      const wait_time = agua_estagnada / div_min_pc;
      if (wait_times < wait_time) {
        wait_times = wait_time;
        setDivision(project.pepc_layout[div]["nickname"]);
      }

      let wait_time_minutes = "" + Math.floor(wait_time);
      let wait_time_seconds = "" + Math.round((wait_time - parseInt(wait_time_minutes)) * 60);

      // Round up to the nearest minute if seconds reach 60
      if (wait_time_seconds === "60") {
        wait_time_minutes = "" + (parseInt(wait_time_minutes) + 1);
        wait_time_seconds = "00";
      }

      // Ensure two-digit format for minutes and seconds
      wait_time_minutes = wait_time_minutes.padStart(2, "0");
      wait_time_seconds = wait_time_seconds.padStart(2, "0");

      let max_wait_time_minutes = "" + Math.floor(wait_times);
      let max_wait_time_seconds = "" + Math.round((wait_times - parseInt(max_wait_time_minutes)) * 60);

      // Round up to the nearest minute if seconds reach 60
      if (max_wait_time_seconds === "60") {
        max_wait_time_minutes = "" + (parseInt(max_wait_time_minutes) + 1);
        max_wait_time_seconds = "00";
      }

      // Ensure two-digit format for minutes and seconds
      max_wait_time_minutes = max_wait_time_minutes.padStart(2, "0");
      max_wait_time_seconds = max_wait_time_seconds.padStart(2, "0");

      setWaitingTime(max_wait_time_minutes + ":" + max_wait_time_seconds);
      setWaitingTimeSeconds(parseInt(max_wait_time_minutes) * 60 + parseInt(max_wait_time_seconds));

      const current_res: {
        nome: string;
        nickname: string;
        agua_estagnada: number;
        espera: string;
        hoterway: string;
      } = {
        nome: project.pepc_layout[div]["name"],
        nickname: project.pepc_layout[div]["nickname"] ? project.pepc_layout[div]["nickname"] : project.pepc_layout[div]["name"],
        agua_estagnada: agua_estagnada,
        espera: wait_time_minutes + ":" + wait_time_seconds,
        hoterway: project.pepc_layout2[div]["hoterway"] === "1" ? t("general.yes") : t("general.no"),
      };

      res.push(current_res);
    }
    return res;
  };

  const divs_data = useMemo(() => calculateDivsAttributes(), [project]);



  useEffect(() => {
    setIsLoading(true);
    axios
      .get(process.env.REACT_APP_API_CALL + `scenario/all/${params}`, config)
      .then((response) => {
        setProject(response.data.data);
      })
      .catch((error) => {
        if (error.response.data.auth === false) {
          setSessionError(true);
        } else {
          setGeneralError(true);
          console.error(`Error: ${error}`);
        }
      });
  }, [params]);

  const getWaterWaste = () => {
    let total_agua_estagnada = 0;
    for (let connectionData of divs_data) {
      total_agua_estagnada += connectionData.agua_estagnada;
    }

    if (project?.building_type === 0) {
      return (total_agua_estagnada * project.total_habitants * project.pe_usage * 365) / 1000;
    } else if (project?.building_type === 1) {
      return (project.pe_usage * project.total_habitants * (total_agua_estagnada / project?.house_parts_count) * 365) / 1000;
    } else {
      return (project.pe_usage * project.total_habitants * (total_agua_estagnada / project?.house_parts_count) * 365) / 1000;
    }
  };

  let consumo_bomba;
  let consumo_bomba_money;
  let formattedConsumoBombaMoney;
  let perda_termica_parcial;
  let perda_termica_money;
  let formattedPerdaTermicaMoney;
  let water_cost;

  const getEnergyWaste = () => {
    let perda_termica_total = 0;
    let comprimento_total = 0;

    for (let connection of connections_data) {
      perda_termica_total += connection.perda_termica;
      comprimento_total += connection.comprimento;
    }

    const energy_bomba_recirculacao = 0.544 * project.average_power * perda_termica_total * 24 * 365 * 10 ** -6;

    consumo_bomba = (energy_bomba_recirculacao * project?.running_time) / 24;

    let min_perda_termica = 1000;

    if (project?.insolation_width === 0) {
      for (let tube_size of defaults.tipos_tubagem[project?.tipo_tubo])
        if (tube_size.perda_termica_1 < min_perda_termica) min_perda_termica = tube_size.perda_termica_1;
    } else if (project?.insolation_width === 1) {
      for (let tube_size of defaults.tipos_tubagem[project?.tipo_tubo])
        if (tube_size.perda_termica_2 < min_perda_termica) min_perda_termica = tube_size.perda_termica_2;
    } else {
      for (let tube_size of defaults.tipos_tubagem[project?.tipo_tubo])
        if (tube_size.perda_termica_3 < min_perda_termica) min_perda_termica = tube_size.perda_termica_3;
    }

    perda_termica_parcial = (perda_termica_total * 1.7).toFixed(1);
    perda_termica_money = (perda_termica_parcial * project.price_thermal * 1000).toFixed(2);
    formattedPerdaTermicaMoney = perda_termica_money.replace(".", ",").replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1.");
  };

  let water_waste = 0;
  let energy_waste;
  let money_energy;
  let energy_percentage;
  let formattedMoneyEnergy;
  if (project) {
    getEnergyWaste();

    water_waste = getWaterWaste();

    energy_waste = (parseFloat(perda_termica_parcial) + parseFloat(consumo_bomba)).toFixed(1);
    energy_percentage = (parseFloat(energy_waste) / expectedEnergy).toFixed(2);
    const total_water_consumption = (project.water_consumption * 3 * project.total_habitants * 365) / 1000;
    // energy_waste_percentage = parseFloat(((energy_waste / (energy_waste + energy_used)) * 100).toFixed(1));

    // REMOVER IF QUANDO SE APAGAREM OS PROJETOS ANTIGOS
    if (project?.water_price === undefined || project.water_price === null) {
      water_cost = (4.25 * water_waste).toFixed(2);
    } else {
      water_cost = (project.water_price * water_waste).toFixed(2);
    }

    consumo_bomba_money = (consumo_bomba * project.price_energy * 1000).toFixed(2);
    formattedConsumoBombaMoney = consumo_bomba_money.replace(".", ",").replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1.");
    // GASTOS = (desperdicio de energia * custo kwh * 1000) + (potencia bomba calor * 365 * 24 * 0,001 * custo energia)
    // CUSTO ENERGIA ELETRICA = 0,20
    // CUSTO ENERGIA TERMICA = 0,16
    // €/KWH
    money_energy = (parseFloat(perda_termica_money) + parseFloat(consumo_bomba_money)).toFixed(2).toLocaleString();
    formattedMoneyEnergy = money_energy.replace(".", ",").replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1.");

    if (consumo_bomba.toFixed(1) == 0.0) {
      consumo_bomba = consumo_bomba.toFixed(3);
    } else {
      consumo_bomba = consumo_bomba.toFixed(1);
    }
  }

  // PRINT SCREEN SETUP

  var htmlContent = "wtf!";
  var tempDiv = document.createElement("div");
  tempDiv.setAttribute("id", "teste2");
  tempDiv.innerHTML = htmlContent;

  const exportPDF = () => {
    // // Zoom out the page by setting a scale transform
    // document.getElementById("wtf").style.transform = "scale(0.8)";

    // Zoom out the page
    document.body.style.zoom = "50%"; // 50% zoom level

    // Set showDiv to true to trigger the rendering
    setShowDiv(true);

    // Use a Promise and requestAnimationFrame to wait for the browser to reflow
    const waitToReflow = () => {
      return new Promise((resolve) => {
        requestAnimationFrame(() => {
          resolve();
        });
      });
    };

    waitToReflow().then(() => {
      // Now, capture the image
      const input = document.getElementById("wtf");

      html2canvas(document.getElementById("wtf"), { logging: true }).then((canvas) => {
        htmlToImage.toPng(document.getElementById("wtf")).then(function (dataUrl) {
          window.scrollTo(0, 0);
          const fixedCanvasWidth = 210; // A4 size width in mm
          // const fixedCanvasHeight = (canvas.height * fixedCanvasWidth) / canvas.width;
          const fixedCanvasHeight = 200;

          const pdfWidth = 210; // A4 size width in mm

          //ALTERAR HEIGHT DEPOIS PARA AJUSTAR PARA TODAS AS RESOLUÇÕES
          const pdfHeight = 150;
          // const pdfHeight = (fixedCanvasHeight * pdfWidth) / fixedCanvasWidth;

          const pdf = new jsPDF({ orientation: "portrait", unit: "mm", format: "a4" });

          // Set background color
          pdf.setFillColor(colors.primary[600]);
          pdf.rect(0, 0, pdf.internal.pageSize.getWidth(), pdf.internal.pageSize.getHeight(), "F");

          // Add the image to the PDF
          pdf.addImage(dataUrl, "JPEG", 0, 0, pdfWidth, pdfHeight);

          // Save the PDF
          pdf.save(project?.scenario_name);

          // Reset the zoom
          document.body.style.zoom = "100%"; // Reset to normal zoom level

          // Now, set showDiv to false after exporting the PDF
          setShowDiv(false);
        });
      });

      // html2canvas(document.getElementById("wtf"), { logging: true, allowTaint: false, useCORS: true, scale: 2 }).then((canvas) => {
      //   window.scrollTo(0, 0);

      //   const fixedCanvasWidth = 210; // A4 size width in mm
      //   // const fixedCanvasHeight = 210; // A4 size height in mm
      //   const fixedCanvasHeight = (canvas.height * fixedCanvasWidth) / canvas.width;

      //   const pdfWidth = 210; // A4 size width in mm
      //   // const pdfHeight = 210; // A4 size height in mm
      //   const pdfHeight = (fixedCanvasHeight * pdfWidth) / fixedCanvasWidth;

      //   const pdf = new jsPDF({ orientation: "portrait", unit: "mm", format: "a4" });

      //   // Set background color
      //   pdf.setFillColor(colors.primary[600]);
      //   pdf.rect(0, 0, pdf.internal.pageSize.getWidth(), pdf.internal.pageSize.getHeight(), "F");

      //   // Add the image to the PDF
      //   pdf.addImage(canvas.toDataURL("image/jpeg", 1.0), "JPEG", 0, 0, pdfWidth, pdfHeight);

      //   // Save the PDF
      //   pdf.save(project?.scenario_name);

      //   // Now, set showDiv to false after exporting the PDF
      //   setShowDiv(false);
      // });
    });
  };

  const tableIDs = ["table_3", "table_4", "table_5"];

  const exportToExcel = (tableIDs, fileName) => {
    const wb = XLSX.utils.book_new();
    const sheetNames = ["Caracterização dos troços AQS", "Espera e água estagnada", "Detalhes Adicionais"];

    tableIDs.forEach((tableID, index) => {
      const tableId = tableID.toString();
      const table = document.getElementById(tableId);

      // Remove the first tr from the thead and store a reference
      const thead = table.querySelector("thead");
      let firstHeaderRow = null;
      if (thead) {
        firstHeaderRow = thead.querySelector("tr");
        if (firstHeaderRow) {
          thead.removeChild(firstHeaderRow);
        }
      }

      const ws = XLSX.utils.table_to_sheet(table);

      // Initialize column widths in the worksheet
      if (!ws["!cols"]) {
        ws["!cols"] = [];
      }

      // Calculate and update column widths based on content and formatting
      for (let row in ws) {
        if (ws.hasOwnProperty(row)) {
          const cell = ws[row];

          if (cell.t === "s") {
            const text = XLSX.utils.format_cell(cell);
            const column = row.match(/[A-Z]+/)[0]; // Extract the column letter
            const colIdx = column.charCodeAt(0) - "A".charCodeAt(0); // Convert to column index
            const textWidth = text.length; // You can adjust this value as needed

            // Ensure the column exists in the !cols array
            if (!ws["!cols"][colIdx]) {
              ws["!cols"][colIdx] = {};
            }

            // Update column width if the content width is greater than the current width
            if (!ws["!cols"][colIdx].wch || textWidth > ws["!cols"][colIdx].wch) {
              ws["!cols"][colIdx].wch = textWidth;
            }
          }
        }
      }

      XLSX.utils.book_append_sheet(wb, ws, sheetNames[index]);

      // Reinsert the first tr back to the thead at the beginning
      if (thead && firstHeaderRow) {
        thead.insertBefore(firstHeaderRow, thead.firstChild);
      }
    });

    XLSX.writeFile(wb, t("scenario_report.excel.tables") + " " + project?.scenario_name + ".xlsx");
  };

  const cenarioBase = JSON.parse(localStorage.getItem("cenarioBase"));
  const cenarioRetorno = JSON.parse(localStorage.getItem("cenarioRetorno"));
  const cenarioHoterway = JSON.parse(localStorage.getItem("cenarioHoterway"));

  const handleSave = () => {
    if (
      (cenarioBase && project?.id_simulation !== cenarioBase?.sim) ||
      (cenarioRetorno && project?.id_simulation !== cenarioRetorno?.sim) ||
      (cenarioHoterway && project?.id_simulation !== cenarioHoterway?.sim)
    ) {
      handleClickOpenDialog();
    } else {
      const variablesCenarioBase = {
        id: idScenario,
        sim: project?.id_simulation,
        var1_1:
          parseFloat(
            (
              (water_waste / (water_waste + ((project?.water_consumption ?? 0) * 3 * (project?.total_habitants ?? 1) * 365) / 1000)) *
              100
            ).toFixed(1)
          ) ?? 0,
        var1_2: parseFloat((water_waste ?? 0).toFixed(1)),
        var1_3:
          project?.certificate_class === 0
            ? "A+"
            : project?.certificate_class === 1
            ? "A"
            : project?.certificate_class === 2
            ? "B"
            : project?.certificate_class === 3
            ? "B-"
            : project?.certificate_class === 4
            ? "C"
            : project?.certificate_class === 5
            ? "D"
            : project?.certificate_class === 6
            ? "E"
            : project?.certificate_class === 7
            ? "F"
            : "---",
        var1_4:
          project?.building_type === 0
            ? t("scenario_report.considerations.building_type.residential")
            : project?.building_type === 1
            ? t("scenario_report.considerations.building_type.hotel")
            : "---",
        var1_5: project?.house_parts_count ?? 0,
        var1_6: project?.tipo_tubo === null ? "---" : t(`scenario_report.considerations.tipo_tubo.${project?.tipo_tubo}`),
        var1_7: parseInt(project?.total_habitants ?? "0"),
        var1_8: waitingTimeSeconds ?? 0,
        var1_9: division ?? 0,
        var1_10: parseFloat((water_waste ?? 0).toFixed(1)) * 1000,
        var1_11: divs_data,
        var1_12: project?.active_consumption,
        var1_13: project?.pe_usage ?? 0,
        var1_14: project?.price_thermal ?? 0,
        var1_15: project?.price_energy ?? 0,
        var1_16: project?.running_time ?? 0,
        var1_17: project?.volume_aqs ?? 0,
        var1_18: project?.water_consumption ?? 0,
        var1_19: project?.water_in_temp ?? 0,
        var1_20: project?.hot_water_temp ?? 0,
        var1_21: project?.average_power ?? 0,
        var1_22: project?.water_price ?? 0,
        var1_23:
          project?.insolation_width === 0
            ? t("scenario_report.considerations.insolation_width.0")
            : project?.insolation_width === 1
            ? t("scenario_report.considerations.insolation_width.1")
            : project?.insolation_width === 2
            ? t("scenario_report.considerations.insolation_width.2")
            : "---",
        var1_24:
          project?.ccppt === 1.15
            ? t("scenario_report.considerations.ccppt.1_15")
            : project?.ccppt === 1.35
            ? t("scenario_report.considerations.ccppt.1_35")
            : project?.ccppt === 1.65
            ? t("scenario_report.considerations.ccppt.1_65")
            : "---",
        var1_25:
          project?.cct === 1.68
            ? "20 mW/m.K"
            : project?.cct === 1.36
            ? "25 mW/m.K"
            : project?.cct === 1.15
            ? "30 mW/m.K"
            : project?.cct === 1
            ? "35 mW/m.K"
            : project?.cct === 0.89
            ? "40 mW/m.K"
            : project?.cct === 0.8
            ? "45 mW/m.K"
            : "---",
        var1_26: project?.building_area ?? 0,
        var1_27: expectedEnergy ?? 0,
        var1_28: editorRef?.current?.toDataURL() ?? "",
      };

      const variablesCenarioRetorno = {
        id: idScenario,
        sim: project?.id_simulation,
        var2_1: parseInt(((energy_waste / expectedEnergy + 1) * 100).toFixed(2)) ?? 0,
        var2_2: parseFloat(energy_waste ?? 0),
        var2_3: getCertificateText(calculateFinalCertificate(project?.certificate_class)) ?? "",
        var2_4: parseFloat(money_energy ?? 0),
        var2_5: waitingTimeSeconds
      };

      console.log(money_energy)
      console.log(cenarioRetorno?.var2_3)
      console.log(defaults?.preco_bateria)
      console.log(defaults?.preco_active)
      console.log((1 - parseFloat(money_energy) / (cenarioRetorno?.var2_3)) * 100)

      const variablesCenarioHoterway = {
        id: idScenario,
        sim: project?.id_simulation,
        var3_1: (((water_waste * 100) / cenarioBase?.var1_2) - 100).toFixed(1) ,
        var3_2: (((energy_waste * 100) / cenarioRetorno?.var2_2) - 100).toFixed(1) ,
        var3_3: ((project?.active_consumption) * 24 * 30) / 1000,
        var3_4: parseFloat(energy_waste),
        var3_5: hoterwayDivs ?? 0,
        var3_6: (defaults?.preco_bateria / ((cenarioRetorno?.var2_4) -  parseFloat(money_energy))).toFixed(1),
        var3_7: (defaults?.preco_active / ((cenarioRetorno?.var2_4) -  parseFloat(money_energy))).toFixed(1),
        var3_8: parseFloat(money_energy),
        var3_9: Math.abs((((energy_waste * 100) / cenarioRetorno?.var2_2) - 100)).toFixed(1),
        var3_10: numberHoterway
      };

      const variablesCenarioHoterwayIN = {
        var3_1: parseFloat(energy_waste ?? 0),
        var3_2: parseFloat(money_energy ?? 0),
        var3_3: numberHoterway ?? 0,
        var3_4: hoterwayDivs ?? 0,
        var3_5: parseFloat(defaults?.preco_bateria / ((cenarioRetorno?.var2_3 ?? 0) - money_energy)) ?? 0,
        var3_6: defaults?.preco_bateria ?? 0,
        var3_7: (1 - money_energy / (cenarioRetorno?.var2_3 ?? 0)) * 100 ?? 0,
      };

      const variablesCenarioHoterwayACTIVE = {
        var4_1: project?.active_consumption ?? 0,
        var4_2: ((project?.active_consumption ?? 0) * 24 * 30) / 1000,
        var4_3:
          defaults?.preco_active /
            ((cenarioRetorno?.var2_3 ?? 0) * 1000 * project?.price_eletric -
              ((project?.active_consumption * 24 * 30) / 1000) * 12 * project?.price_thermal) ?? 0,
        var4_4: parseFloat(money_energy ?? 0),
        var4_5: numberHoterway ?? 0,
        var4_6: hoterwayDivs ?? 0,
        var4_7: parseFloat(defaults?.preco_bateria / ((cenarioRetorno?.var2_3 ?? 0) - money_energy)) ?? 0,
        var4_8: defaults?.preco_active ?? 0,
      };

      // Save to local storage based on selected scenario

      if (selectedScenario === "cenarioHoterway") {
        // Check if cenarioRetorno exists
        if (!cenarioRetorno) {
          setSnackbarOpen(true); // Show the success alert
          return; // Exit the function without saving
        }

        // Check if cenarioRetorno has the same ID as cenarioHoterway
        if (cenarioRetorno?.id === idScenario) {
          setSnackbarOpen(true); // Show the success alert
          return; // Exit the function without saving
        } else {
          // If cenarioRetorno exists and has a different ID, proceed to save cenarioHoterway
          localStorage.setItem("cenarioHoterway", JSON.stringify(variablesCenarioHoterway));
        }
      } else if (selectedScenario === "cenarioBase") {
        // Your existing logic for cenarioBase
        if (cenarioRetorno?.id === idScenario) {
          localStorage.removeItem("cenarioRetorno");
        } else if (cenarioHoterway?.id === idScenario) {
          localStorage.removeItem("cenarioHoterway");
        }
        localStorage.setItem("cenarioBase", JSON.stringify(variablesCenarioBase));
      } else if (selectedScenario === "cenarioRetorno") {
        // When saving cenarioRetorno, also remove cenarioHoterway if it exists
        if (cenarioBase?.id === idScenario) {
          localStorage.removeItem("cenarioBase");
        }

        // Remove cenarioHoterway when saving a new cenarioRetorno
        if (cenarioHoterway) {
          localStorage.removeItem("cenarioHoterway");
        }

        // Save the new cenarioRetorno
        localStorage.setItem("cenarioRetorno", JSON.stringify(variablesCenarioRetorno));
      }

      handleClose();
      setSnackbarOpen(true); // Show the success alert
    }

  };

  const handleSnackbarClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackbarOpen(false); // Close the snackbar
  };

  const handleSnackbarCloseExcel = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackbarOpenExcel(false); // Close the snackbar
  };

  const handleSnackbarCloseDelete = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackbarOpenDelete(false); // Close the snackbar
  };

  console.log(divs_data);
  console.log(divs_table_columns);

  return (
    <>
      {isLoading || defaults === null ? (
        <LoadingScreen />
      ) : (
        <>
          <div id="all">
            <Box m="20px">
              <div
                id="wtf"
                style={{
                  display: showDiv ? "block" : "none",
                  // display: "block",
                  backgroundColor: colors.primary[600],
                  padding: "30px",
                  width: "100%" /* Ensure that the container adapts to the available space */,
                  maxWidth: "1600px" /* Set a maximum width to prevent excessive stretching */,
                  // maxHeight: "1600px" /* Set a maximum height to prevent excessive stretching */,
                }}
              >
                <Header
                  title={
                    cenarioBase?.id === idScenario
                      ? t("scenario_report.title1") +
                        " " +
                        (project?.scenario_name || "Default Scenario Name") +
                        " || [CENÁRIO BASE ESCOLHIDO]"
                      : cenarioRetorno?.id === idScenario
                      ? t("scenario_report.title1") +
                        " " +
                        (project?.scenario_name || "Default Scenario Name") +
                        " || [CENÁRIO RETORNO ESCOLHIDO]"
                      : cenarioHoterway?.id === idScenario
                      ? t("scenario_report.title1") +
                        " " +
                        (project?.scenario_name || "Default Scenario Name") +
                        " || [CENÁRIO HOTERWAY ESCOLHIDO]"
                      : t("scenario_report.title1") + " " + (project?.scenario_name || "Default Scenario Name")
                  }
                  subtitle={
                    t("scenario_report.subtitle.id_simulation") +
                    " " +
                    (project?.id_simulation || "N/A") +
                    " || " +
                    t("scenario_report.subtitle.simulation_scenario") +
                    " " +
                    (project?.client_name || "Unknown Client") +
                    " || " +
                    t("scenario_report.subtitle.date") +
                    " " +
                    (project?.date || "Unknown Date")
                  }
                />
                <Box component="main" maxWidth="100%">
                  <Box display="flex" flexDirection="column" alignItems="center" mt="30px">
                    {project && project !== undefined && isLoading === false ? (
                      <>
                        <Grid container spacing={4} justifyContent="left">
                          <Grid item xs={12}>
                            <Typography component="h1" variant="h3">
                              {t("scenario_report.title2")}
                            </Typography>
                          </Grid>
                          <Grid item xs={9} sm={9} md={12} lg={8} xl={9}>
                            {project && project.editor_image && (
                              <>
                                {isStringImage(project.editor_image.toString()) ? (
                                  <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                    <Box
                                      component="img"
                                      sx={{
                                        maxWidth: {
                                          xs: "55%",
                                        },
                                        maxHeight: {
                                          xs: "100%",
                                        },
                                      }}
                                      alt={t("scenario_report.image_error")}
                                      src={project.editor_image}
                                    />
                                  </Grid>
                                ) : (
                                  <div className="canvasContainerContainer">
                                    <div className="canvasContainer" ref={editorContainerRef}>
                                      <KonvaEditor
                                        floors_count={project?.floors_count}
                                        floorsNames={project?.floors_names}
                                        sourceTitle={project?.water_heating_system ?? "T0"}
                                        width={konvaWidth}
                                        refEditor={editorRef}
                                        pePcLayout={project?.pepc_layout}
                                        loadFromSavedObject={project?.editor_image}
                                        pePcConnections={project.pepc_connections}
                                        canEdit={false}
                                        disabled
                                        defaults={defaults}
                                        pePcConnectionsAdjustedDiameter={transformDictionary(project.pepc_connections)}
                                        pePcConnectionsRe={project.pepc_connections}
                                        project={project}
                                        setProject={(newProject) => {}}
                                        setPePcConnectionsRe={(pepcConnectionsRe) => {}}
                                        t={t}
                                        setPePcConnectionsAdjustedDiameter={(pepcConnectionsAdjustedDiameter) => {}}
                                        setPePcConnections={(pepcConnections) => {}}
                                        currentArrows={currentArrows}
                                        setCurrentArrows={setCurrentArrows}
                                      />
                                    </div>
                                  </div>
                                )}
                              </>
                            )}
                          </Grid>
                          <Grid item xs={3} sm={3} md={12} lg={4} xl={3} mt="15px">
                            <Grid container spacing={5} alignItems="center">
                              <Grid
                                container
                                spacing={2}
                                xs={12}
                                sm={12}
                                md={5}
                                lg={12}
                                xl={12}
                                paddingLeft={2}
                                marginLeft={4}
                                marginTop={1}
                                marginBottom={0}
                              >
                                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                  <Card style={{ backgroundColor: colors.blueAccent[600] }}>
                                    <CardHeader
                                      style={{ backgroundColor: colors.blueAccent[700] }}
                                      sx={{ fontSize: 18, justifyContent: "center", alignItems: "baseline", textAlign: "center" }}
                                      component={Typography}
                                      title={t("scenario_report.cards.1.title")}
                                      titleTypographyProps={{ variant: "h3" }}
                                    />
                                    <CardContent>
                                      <Box
                                        sx={{
                                          display: "flex",
                                          justifyContent: "center",
                                          alignItems: "baseline",
                                          mb: "-15px",
                                          mt: "-9px",
                                        }}
                                      >
                                        <Typography component="h2" variant="h3" color="text.primary">
                                          {waitingTime}
                                        </Typography>
                                        <Typography component="h2" variant="h3" color="text.primary">
                                          &nbsp;
                                        </Typography>
                                        <Typography variant="h6" color="text.secondary" sx={{ fontWeight: "bold" }}>
                                          (MM:SS)
                                        </Typography>
                                      </Box>
                                    </CardContent>
                                  </Card>
                                </Grid>
                              </Grid>

                              {project?.building_type === 0 && (
                                <Grid
                                  container
                                  spacing={2}
                                  xs={12}
                                  sm={12}
                                  md={5}
                                  lg={12}
                                  xl={12}
                                  paddingLeft={2}
                                  marginLeft={4}
                                  marginTop={1}
                                  marginBottom={0}
                                >
                                  <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                    <Card style={{ backgroundColor: colors.blueAccent[600] }}>
                                      <CardHeader
                                        style={{ backgroundColor: colors.blueAccent[700] }}
                                        sx={{ fontSize: 18, justifyContent: "center", alignItems: "baseline", textAlign: "center" }}
                                        component={Typography}
                                        title={t("scenario_report.cards.2.title")}
                                        titleTypographyProps={{ variant: "h3" }}
                                      />
                                      <CardContent>
                                        <Box
                                          sx={{
                                            display: "flex",
                                            justifyContent: "center",
                                            alignItems: "baseline",
                                            mb: "-15px",
                                            mt: "-9px",
                                          }}
                                        >
                                          <Typography component="h2" variant="h3" color="text.primary">
                                            {water_waste.toFixed(1) + ""}
                                          </Typography>
                                          <Typography component="h2" variant="h3" color="text.primary">
                                            &nbsp;
                                          </Typography>
                                          <Typography variant="h6" color="text.secondary" sx={{ fontWeight: "bold" }}>
                                            {"m³/" + t("scenario_report.cards.2.year")}
                                          </Typography>
                                        </Box>

                                        <br />

                                        <Typography
                                          component="h6"
                                          variant="body2"
                                          color="text.secondary"
                                          textAlign="center"
                                          sx={{
                                            display: "flex",
                                            justifyContent: "center",
                                            alignItems: "baseline",
                                            mb: "-15px",
                                          }}
                                        >
                                          {""}
                                          {(
                                            (water_waste /
                                              (water_waste + (project.water_consumption * 3 * project.total_habitants * 365) / 1000)) *
                                            100
                                          ).toFixed(1)}{" "}
                                          {t("scenario_report.cards.2.spent") +
                                            " / " +
                                            t("scenario_report.cards.2.cost") +
                                            " " +
                                            `${water_cost}` +
                                            "€/" +
                                            t("scenario_report.cards.2.year")}
                                        </Typography>
                                      </CardContent>
                                    </Card>
                                  </Grid>
                                </Grid>
                              )}

                              <Grid
                                container
                                spacing={2}
                                xs={12}
                                sm={12}
                                md={5}
                                lg={12}
                                xl={12}
                                paddingLeft={2}
                                marginLeft={4}
                                marginTop={1}
                                marginBottom={0}
                              >
                                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                  <Card style={{ backgroundColor: colors.blueAccent[600] }}>
                                    <CardHeader
                                      style={{ backgroundColor: colors.blueAccent[700] }}
                                      sx={{ fontSize: 18, justifyContent: "center", alignItems: "baseline", textAlign: "center" }}
                                      component={Typography}
                                      title={t("scenario_report.cards.3.title")}
                                      titleTypographyProps={{ variant: "h3" }}
                                    />
                                    <CardContent>
                                      <Box
                                        sx={{
                                          display: "flex",
                                          justifyContent: "center",
                                          alignItems: "baseline",
                                          mb: "-15px",
                                          mt: "-9px",
                                        }}
                                      >
                                        <Typography component="h2" variant="h3" color="text.primary">
                                          {energy_waste}
                                        </Typography>
                                        <Typography component="h2" variant="h3" color="text.primary">
                                          &nbsp;
                                        </Typography>
                                        <Typography variant="h6" color="text.secondary" sx={{ fontWeight: "bold" }}>
                                          {t("scenario_report.cards.3.mwh_year")}
                                        </Typography>
                                      </Box>
                                      <br />

                                      <Typography component="h6" variant="body2" color="text.secondary" textAlign="center">
                                        {t("scenario_report.cards.3.cost") +
                                          " " +
                                          `${formattedMoneyEnergy}` +
                                          " " +
                                          t("scenario_report.cards.3.cost_variable")}
                                      </Typography>
                                      <Typography component="h6" variant="body2" color="text.secondary" textAlign="center">
                                        {t("scenario_report.cards.3.perdas_tubagem") +
                                          " " +
                                          `${perda_termica_parcial}` +
                                          " " +
                                          t("scenario_report.cards.3.mwh_year") +
                                          " (" +
                                          `${formattedPerdaTermicaMoney}` +
                                          "€)"}
                                      </Typography>
                                      <Typography
                                        component="h6"
                                        variant="body2"
                                        color="text.secondary"
                                        textAlign="center"
                                        sx={{
                                          display: "flex",
                                          justifyContent: "center",
                                          alignItems: "baseline",
                                          mb: "-15px",
                                        }}
                                      >
                                        {t("scenario_report.cards.3.consumo_bomba") +
                                          " " +
                                          `${consumo_bomba}` +
                                          " " +
                                          t("scenario_report.cards.3.mwh_year") +
                                          " (" +
                                          `${formattedConsumoBombaMoney}` +
                                          "€)"}
                                      </Typography>
                                    </CardContent>
                                  </Card>
                                </Grid>
                              </Grid>

                              {project?.building_type === 0 && (
                                <Grid
                                  container
                                  spacing={2}
                                  xs={12}
                                  sm={12}
                                  md={12}
                                  lg={12}
                                  xl={12}
                                  paddingLeft={2}
                                  marginLeft={4}
                                  marginTop={1}
                                  marginBottom={0}
                                >
                                  <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                    <Card style={{ backgroundColor: colors.blueAccent[600] }}>
                                      <CardHeader
                                        style={{ backgroundColor: colors.blueAccent[700] }}
                                        sx={{ fontSize: 18, justifyContent: "center", alignItems: "baseline", textAlign: "center" }}
                                        component={Typography}
                                        title={t("scenario_report.cards.4.title")}
                                        titleTypographyProps={{ variant: "h3" }}
                                      />
                                      <CardContent>
                                        <Grid container spacing={2}>
                                          <Grid item xs={12} sm={12} md={12} lg={12} xl={6}>
                                            <Grid item xs={12}>
                                              <Card style={{ backgroundColor: getBackgroundColor(project?.certificate_class) }}>
                                                <CardContent>
                                                  <Box
                                                    sx={{
                                                      display: "flex",
                                                      justifyContent: "center",
                                                      alignItems: "baseline",
                                                      mt: "10px",
                                                    }}
                                                  >
                                                    <Typography component="h2" variant="h2" color="text.primary">
                                                      {getCertificateText(project?.certificate_class)}
                                                    </Typography>
                                                  </Box>
                                                </CardContent>
                                              </Card>
                                            </Grid>
                                            <Grid item xs={12}>
                                              <Typography
                                                component="h6"
                                                variant="body2"
                                                color="text.secondary"
                                                textAlign="center"
                                                sx={{
                                                  display: "flex",
                                                  justifyContent: "center",
                                                  alignItems: "baseline",
                                                  mb: "-15px",
                                                  mt: "5px",
                                                }}
                                              >
                                                {t("scenario_report.cards.4.pre_certificate")}
                                              </Typography>
                                            </Grid>
                                          </Grid>
                                          <Grid item xs={12} sm={12} md={12} lg={12} xl={6}>
                                            <Grid item xs={12}>
                                              <Card
                                                style={{
                                                  backgroundColor: getBackgroundColor(
                                                    calculateFinalCertificate(project?.certificate_class)
                                                  ),
                                                }}
                                              >
                                                <CardContent>
                                                  <Box
                                                    sx={{
                                                      display: "flex",
                                                      justifyContent: "center",
                                                      alignItems: "baseline",
                                                      mt: "10px",
                                                    }}
                                                  >
                                                    <Typography component="h2" variant="h2" color="text.primary">
                                                      {getCertificateText(calculateFinalCertificate(project?.certificate_class))}*
                                                    </Typography>
                                                  </Box>
                                                </CardContent>
                                              </Card>
                                            </Grid>
                                            <Grid item xs={12}>
                                              <Typography
                                                component="h6"
                                                variant="body2"
                                                color="text.secondary"
                                                textAlign="center"
                                                sx={{
                                                  display: "flex",
                                                  justifyContent: "center",
                                                  alignItems: "baseline",
                                                  mb: "-15px",
                                                  mt: "5px",
                                                }}
                                              >
                                                {t("scenario_report.cards.4.certificate")}
                                              </Typography>
                                            </Grid>
                                          </Grid>
                                        </Grid>
                                      </CardContent>
                                    </Card>
                                  </Grid>
                                </Grid>
                              )}
                            </Grid>
                          </Grid>
                        </Grid>

                        <Grid container spacing={4} mt="10px" justifyContent="left">
                          <Grid item xs={12}>
                            <Grid item xs={12}>
                              <Typography component="h1" variant="h3" mb="20px">
                                {t("scenario_report.considerations.title")}
                              </Typography>
                            </Grid>

                            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                              <TextField
                                fullWidth
                                multiline
                                variant="filled"
                                type="text"
                                value={
                                  project.building_type === 0
                                    ? t("scenario_report.considerations.floors_count") +
                                      (project.floors_count === null ? "---" : project.floors_count) +
                                      t("scenario_report.considerations.house_parts_count") +
                                      (project.house_parts_count === null ? "---" : project.house_parts_count) +
                                      t("scenario_report.considerations.pe_usage") +
                                      (project.pe_usage === null ? "---" : project.pe_usage) +
                                      t("scenario_report.considerations.total_habitants") +
                                      (project.total_habitants === null ? "---" : project.total_habitants) +
                                      t("scenario_report.considerations.price_thermal") +
                                      (project.price_thermal === null ? "---" : project.price_thermal + " €/kWh") +
                                      t("scenario_report.considerations.price_energy") +
                                      (project.price_energy === null ? "---" : project.price_energy + " €/kWh") +
                                      t("scenario_report.considerations.building_type.title") +
                                      (project.building_type === 0
                                        ? t("scenario_report.considerations.building_type.residential")
                                        : project.building_type === 1
                                        ? t("scenario_report.considerations.building_type.hotel")
                                        : "---") +
                                      t("scenario_report.considerations.tipo_tubo.title") +
                                      (project.tipo_tubo === null
                                        ? "---"
                                        : t(`scenario_report.considerations.tipo_tubo.${project.tipo_tubo}`)) +
                                      (numberRecirculation > 0 && numberRecirculation !== null && numberHoterway === null
                                        ? t("scenario_report.considerations.running_time.title") +
                                          (project.running_time === null
                                            ? "---"
                                            : project.running_time + t("scenario_report.considerations.running_time.variable"))
                                        : "") +
                                      (numberHoterway > 0 &&
                                      numberHoterway !== null &&
                                      numberRecirculation > 0 &&
                                      numberRecirculation !== null
                                        ? t("scenario_report.considerations.running_time_hoterway.title") +
                                          (project.running_time_hoterway === null
                                            ? "---"
                                            : project.running_time_hoterway +
                                              t("scenario_report.considerations.running_time_hoterway.variable"))
                                        : "") +
                                      t("scenario_report.considerations.volume_aqs") +
                                      (project.volume_aqs === null ? "---" : project.volume_aqs + " m3") +
                                      t("scenario_report.considerations.water_consumption.title") +
                                      (project.water_consumption === null
                                        ? "---"
                                        : project.water_consumption + t("scenario_report.considerations.water_consumption.variable")) +
                                      t("scenario_report.considerations.water_in_temp") +
                                      (project.water_in_temp === null ? "---" : project.water_in_temp + " ºC") +
                                      t("scenario_report.considerations.hot_water_temp") +
                                      (project.hot_water_temp === null ? "---" : project.hot_water_temp + " ºC") +
                                      t("scenario_report.considerations.average_power.title") +
                                      (project.average_power === null
                                        ? "---"
                                        : project.average_power + t("scenario_report.considerations.average_power.variable")) +
                                      t("scenario_report.considerations.water_price") +
                                      (project.water_price === null ? "---" : project.water_price + " €/m3") +
                                      t("scenario_report.considerations.insolation_width.title") +
                                      (project.insolation_width === 0
                                        ? t("scenario_report.considerations.insolation_width.0")
                                        : project.insolation_width === 1
                                        ? t("scenario_report.considerations.insolation_width.1")
                                        : project.insolation_width === 2
                                        ? t("scenario_report.considerations.insolation_width.2")
                                        : "---") +
                                      t("scenario_report.considerations.ccppt.title") +
                                      (project.ccppt === 1.15
                                        ? t("scenario_report.considerations.ccppt.1_15")
                                        : project.ccppt === 1.35
                                        ? t("scenario_report.considerations.ccppt.1_35")
                                        : project.ccppt === 1.65
                                        ? t("scenario_report.considerations.ccppt.1_65")
                                        : "---") +
                                      t("scenario_report.considerations.cct") +
                                      (project.cct === 1.68
                                        ? "20 mW/m.K"
                                        : project.cct === 1.36
                                        ? "25 mW/m.K"
                                        : project.cct === 1.15
                                        ? "30 mW/m.K"
                                        : project.cct === 1
                                        ? "35 mW/m.K"
                                        : project.cct === 0.89
                                        ? "40 mW/m.K"
                                        : project.cct === 0.8
                                        ? "45 mW/m.K"
                                        : "---") +
                                      t("scenario_report.considerations.active_consumption") +
                                      (project.active_consumption === null ? "---" : ": " + project.active_consumption + " MWh/ano") +
                                      t("scenario_report.considerations.building_area") +
                                      (project.building_area === null ? "---" : project.building_area + " m2") +
                                      t("scenario_report.considerations.energy_reference.title") +
                                      (expectedEnergy === 0
                                        ? "---"
                                        : expectedEnergy + t("scenario_report.considerations.energy_reference.variable")) +
                                      t("scenario_report.considerations.certificate_class") +
                                      (project.certificate_class === 0
                                        ? "A+"
                                        : project.certificate_class === 1
                                        ? "A"
                                        : project.certificate_class === 2
                                        ? "B"
                                        : project.certificate_class === 3
                                        ? "B-"
                                        : project.certificate_class === 4
                                        ? "C"
                                        : project.certificate_class === 5
                                        ? "D"
                                        : project.certificate_class === 6
                                        ? "E"
                                        : project.certificate_class === 7
                                        ? "F"
                                        : "---") +
                                      (project.running_time !== 24 ? t("scenario_report.considerations.warning") : "") +
                                      t("scenario_report.considerations.email_comercial") +
                                      (project.email_comercial === null ? "---" : project.email_comercial) +
                                      t("scenario_report.considerations.date") +
                                      (project.date === null ? "---" : project.date) +
                                      t("scenario_report.considerations.client_name") +
                                      (project.client_name === null ? "---" : project.client_name) +
                                      t("scenario_report.considerations.nif") +
                                      (project.nif === null ? "---" : project.nif) +
                                      t("scenario_report.considerations.address") +
                                      (project.address === null ? "---" : project.address) +
                                      t("scenario_report.considerations.postal_code") +
                                      (project.postal_code === null ? "---" : project.postal_code) +
                                      t("scenario_report.considerations.email") +
                                      (project.email === null ? "---" : project.email) +
                                      t("scenario_report.considerations.phone") +
                                      (project.phone === null ? "---" : project.phone)
                                    : t("scenario_report.considerations.floors_count") +
                                      (project.floors_count === null ? "---" : project.floors_count) +
                                      t("scenario_report.considerations.house_parts_count") +
                                      (project.house_parts_count === null ? "---" : project.house_parts_count) +
                                      t("scenario_report.considerations.price_thermal") +
                                      (project.price_thermal === null ? "---" : project.price_thermal + " €/kWh") +
                                      t("scenario_report.considerations.price_energy") +
                                      (project.price_energy === null ? "---" : project.price_energy + " €/kWh") +
                                      t("scenario_report.considerations.building_type.title") +
                                      (project.building_type === 0
                                        ? t("scenario_report.considerations.building_type.residential")
                                        : project.building_type === 1
                                        ? t("scenario_report.considerations.building_type.hotel")
                                        : "---") +
                                      t("scenario_report.considerations.tipo_tubo.title") +
                                      (project.tipo_tubo === null
                                        ? "---"
                                        : t(`scenario_report.considerations.tipo_tubo.${project.tipo_tubo}`)) +
                                      (numberRecirculation > 0 && numberRecirculation !== null && numberHoterway === null
                                        ? t("scenario_report.considerations.running_time.title") +
                                          (project.running_time === null
                                            ? "---"
                                            : project.running_time + t("scenario_report.considerations.running_time.variable"))
                                        : "") +
                                      (numberHoterway > 0 &&
                                      numberHoterway !== null &&
                                      numberRecirculation > 0 &&
                                      numberRecirculation !== null
                                        ? t("scenario_report.considerations.running_time_hoterway.title") +
                                          (project.running_time_hoterway === null
                                            ? "---"
                                            : project.running_time_hoterway +
                                              t("scenario_report.considerations.running_time_hoterway.variable"))
                                        : "") +
                                      t("scenario_report.considerations.volume_aqs") +
                                      (project.volume_aqs === null ? "---" : project.volume_aqs + " m3") +
                                      t("scenario_report.considerations.water_consumption.title") +
                                      (project.water_consumption === null
                                        ? "---"
                                        : project.water_consumption + t("scenario_report.considerations.water_consumption.variable")) +
                                      t("scenario_report.considerations.water_in_temp") +
                                      (project.water_in_temp === null ? "---" : project.water_in_temp + " ºC") +
                                      t("scenario_report.considerations.hot_water_temp") +
                                      (project.hot_water_temp === null ? "---" : project.hot_water_temp + " ºC") +
                                      t("scenario_report.considerations.average_power.title") +
                                      (project.average_power === null
                                        ? "---"
                                        : project.average_power + t("scenario_report.considerations.average_power.variable")) +
                                      t("scenario_report.considerations.insolation_width.title") +
                                      (project.insolation_width === 0
                                        ? t("scenario_report.considerations.insolation_width.0")
                                        : project.insolation_width === 1
                                        ? t("scenario_report.considerations.insolation_width.1")
                                        : project.insolation_width === 2
                                        ? t("scenario_report.considerations.insolation_width.2")
                                        : "---") +
                                      t("scenario_report.considerations.ccppt.title") +
                                      (project.ccppt === 1.15
                                        ? t("scenario_report.considerations.ccppt.1_15")
                                        : project.ccppt === 1.35
                                        ? t("scenario_report.considerations.ccppt.1_35")
                                        : project.ccppt === 1.65
                                        ? t("scenario_report.considerations.ccppt.1_65")
                                        : "---") +
                                      t("scenario_report.considerations.cct") +
                                      (project.cct === 1.68
                                        ? "20 mW/m.K"
                                        : project.cct === 1.36
                                        ? "25 mW/m.K"
                                        : project.cct === 1.15
                                        ? "30 mW/m.K"
                                        : project.cct === 1
                                        ? "35 mW/m.K"
                                        : project.cct === 0.89
                                        ? "40 mW/m.K"
                                        : project.cct === 0.8
                                        ? "45 mW/m.K"
                                        : "---") +
                                      t("scenario_report.considerations.active_consumption") +
                                      (project.active_consumption === null ? "---" : ": " + project.active_consumption + " MWh/ano") +
                                      (project.running_time !== 24 ? t("scenario_report.considerations.warning") : "") +
                                      t("scenario_report.considerations.email_comercial") +
                                      (project.email_comercial === null ? "---" : project.email_comercial) +
                                      t("scenario_report.considerations.date") +
                                      (project.date === null ? "---" : project.date) +
                                      t("scenario_report.considerations.client_name") +
                                      (project.client_name === null ? "---" : project.client_name) +
                                      t("scenario_report.considerations.nif") +
                                      (project.nif === null ? "---" : project.nif) +
                                      t("scenario_report.considerations.address") +
                                      (project.address === null ? "---" : project.address) +
                                      t("scenario_report.considerations.postal_code") +
                                      (project.postal_code === null ? "---" : project.postal_code) +
                                      t("scenario_report.considerations.email") +
                                      (project.email === null ? "---" : project.email) +
                                      t("scenario_report.considerations.phone") +
                                      (project.phone === null ? "---" : project.phone)
                                }
                                name="email_comercial"
                                inputProps={{ style: { fontSize: 16, paddingBottom: "8px" } }}
                                sx={{
                                  "& .MuiInputBase-input.Mui-disabled": {
                                    WebkitTextFillColor: "#FFFFFF",
                                  },
                                  "& .MuiInputLabel-standard.Mui-disabled": {
                                    WebkitTextFillColor: "#FFFFFF",
                                  },
                                  "& .MuiInputLabel-filled.Mui-disabled": {
                                    WebkitTextFillColor: "#FFFFFF",
                                  },
                                  "& .MuiFilledInput-root": {
                                    background: "#535050",
                                  },
                                }}
                                disabled
                              />
                            </Grid>
                          </Grid>

                          <Grid item xs={12} sm={12} md={12} lg={12} xl={12} marginBottom="-25px">
                            <Grid item xs={12} marginBottom="-25px">
                              <Grid item xs={12}>
                                <Typography component="h1" variant="h3" mb="20px">
                                  {t("scenario_report.notes")}
                                </Typography>
                              </Grid>
                              <TextField
                                fullWidth
                                multiline
                                variant="filled"
                                type="text"
                                value={
                                  (project.obs === null || project.obs === undefined || project.obs === "" ? "" : project.obs + " || ") +
                                  (project.notes === null || project.notes === undefined ? "" : project.notes)
                                }
                                name="notes"
                                inputProps={{ style: { fontSize: 16, paddingBottom: "8px" } }}
                                sx={{
                                  "& .MuiInputBase-input.Mui-disabled": {
                                    WebkitTextFillColor: "#FFFFFF",
                                  },
                                  "& .MuiInputLabel-standard.Mui-disabled": {
                                    WebkitTextFillColor: "#FFFFFF",
                                  },
                                  "& .MuiInputLabel-filled.Mui-disabled": {
                                    WebkitTextFillColor: "#FFFFFF",
                                  },
                                  "& .MuiFilledInput-root": {
                                    background: "#535050",
                                  },
                                }}
                                disabled
                              />
                              {project?.building_type === 0 && (
                                <Grid item xs={12} mt={"30px"}>
                                  <Typography component="h4" variant="h6" mb="20px">
                                    {t("scenario_report.building_note")}
                                  </Typography>
                                </Grid>
                              )}
                            </Grid>
                          </Grid>
                        </Grid>
                      </>
                    ) : (
                      // COLOCAR {window.location.reload()} caso dê asneira
                      <></>
                    )}
                  </Box>
                </Box>
              </div>
              <Header
                title={
                  cenarioBase?.id === idScenario
                    ? t("scenario_report.title1") +
                      " " +
                      (project?.scenario_name || "Default Scenario Name") +
                      " || [CENÁRIO BASE ESCOLHIDO]"
                    : cenarioRetorno?.id === idScenario
                    ? t("scenario_report.title1") +
                      " " +
                      (project?.scenario_name || "Default Scenario Name") +
                      " || [CENÁRIO RETORNO ESCOLHIDO]"
                    : cenarioHoterway?.id === idScenario
                    ? t("scenario_report.title1") +
                      " " +
                      (project?.scenario_name || "Default Scenario Name") +
                      " || [CENÁRIO HOTERWAY ESCOLHIDO]"
                    : t("scenario_report.title1") + " " + (project?.scenario_name || "Default Scenario Name")
                }
                subtitle={
                  t("scenario_report.subtitle.id_simulation") +
                  " " +
                  project?.id_simulation +
                  " || " +
                  t("scenario_report.subtitle.simulation_scenario") +
                  " " +
                  project?.client_name +
                  " || " +
                  t("scenario_report.subtitle.date") +
                  " " +
                  project?.date
                }
              />

              <Grid container spacing={2}>
                <Grid item xs={12} sm={6} md={6} lg={3.5} xl={1.5} justifyContent="flex-start">
                  <Box
                    onClick={() => navigate("/simulations/" + project?.id_simulation)}
                    p="5px 7px 5px 5px"
                    display="flex"
                    justifyContent="center"
                    borderRadius="4px"
                    alignItems="center"
                    backgroundColor={colors.blueAccent[600]}
                    sx={{
                      cursor: "pointer",
                      textDecoration: "none",
                    }}
                  >
                    <KeyboardDoubleArrowLeftOutlinedIcon
                      sx={{
                        textDecoration: "none",
                        color: colors.primary[100],
                        justifyContent: "center",
                      }}
                    />
                    <Typography
                      justifyContent="center"
                      color={colors.primary[100]}
                      sx={{
                        ml: "5px",
                        textDecoration: "none",
                        fontWeight: "bold",
                        justifyContent: "center",
                        marginTop: "1px",
                      }}
                    >
                      Voltar à Simulação
                    </Typography>
                  </Box>
                </Grid>

                <Grid item xs={12} sm={6} md={6} lg={3.5} xl={1.5} justifyContent="flex-start">
                  <Box
                    onClick={() => navigate("/scenarios/" + idScenario)}
                    p="5px 7px 5px 5px"
                    display="flex"
                    justifyContent="center"
                    borderRadius="4px"
                    alignItems="center"
                    backgroundColor={colors.blueAccent[600]}
                    sx={{
                      cursor: "pointer",
                      textDecoration: "none",
                    }}
                  >
                    <ArrowBackOutlinedIcon
                      sx={{
                        textDecoration: "none",
                        color: colors.primary[100],
                        justifyContent: "center",
                      }}
                    />
                    <Typography
                      justifyContent="center"
                      color={colors.primary[100]}
                      sx={{
                        ml: "5px",
                        textDecoration: "none",
                        fontWeight: "bold",
                        justifyContent: "center",
                        marginTop: "1px",
                      }}
                    >
                      Voltar ao Cenário
                    </Typography>
                  </Box>
                </Grid>

                <Grid container spacing={2} mt="15px" display="flex" justifyContent="flex-end">
                  <Grid item xs={12} sm={6} md={6} lg={3.5} xl={1.5}>
                    <Box
                      onClick={() => {
                        handleClickOpen();
                        handleSnackbarClose();
                        handleSnackbarCloseExcel();
                        handleSnackbarCloseDelete();
                      }}
                      m="0 auto"
                      p="5px 7px 5px 5px"
                      display="flex"
                      justifyContent="center"
                      borderRadius="4px"
                      alignItems="center"
                      backgroundColor={colors.blueAccent[600]}
                      sx={{
                        cursor: "pointer",
                        textDecoration: "none",
                      }}
                    >
                      <DvrOutlinedIcon
                        sx={{
                          textDecoration: "none",
                          color: colors.primary[100],
                          justifyContent: "center",
                        }}
                      />
                      <Typography
                        justifyContent="center"
                        color={colors.primary[100]}
                        sx={{
                          ml: "5px",
                          textDecoration: "none",
                          fontWeight: "bold",
                          justifyContent: "center",
                          marginTop: "1px",
                        }}
                      >
                        {"Atribuir Cenário"}
                      </Typography>
                    </Box>
                  </Grid>

                  {cenarioBase?.id === idScenario ? (
                    <Grid item xs={12} sm={6} md={6} lg={3.5} xl={1.5}>
                      <Box
                        onClick={() => exportToExcel(tableIDs)}
                        m="0 auto"
                        p="5px 7px 5px 5px"
                        display="flex"
                        justifyContent="center"
                        borderRadius="4px"
                        alignItems="center"
                        backgroundColor={colors.greenAccent[600]}
                        sx={{
                          cursor: "pointer",
                          textDecoration: "none",
                        }}
                      >
                        <DvrOutlinedIcon
                          sx={{
                            textDecoration: "none",
                            color: colors.primary[100],
                            justifyContent: "center",
                          }}
                        />
                        <Typography
                          justifyContent="center"
                          color={colors.primary[100]}
                          sx={{
                            ml: "5px",
                            textDecoration: "none",
                            fontWeight: "bold",
                            justifyContent: "center",
                            marginTop: "1px",
                          }}
                        >
                          {t("scenario_report.buttons.export_tables")}
                        </Typography>
                      </Box>
                    </Grid>
                  ) : (
                    <Grid item xs={12} sm={6} md={6} lg={3.5} xl={1.5}>
                      <Box
                        onClick={() => setSnackbarOpenExcel(true)}
                        m="0 auto"
                        p="5px 7px 5px 5px"
                        display="flex"
                        justifyContent="center"
                        borderRadius="4px"
                        alignItems="center"
                        backgroundColor={colors.redAccent[600]}
                        sx={{
                          cursor: "not-allowed",
                          textDecoration: "none",
                        }}
                      >
                        <DvrOutlinedIcon
                          sx={{
                            textDecoration: "none",
                            color: colors.primary[100],
                            justifyContent: "center",
                          }}
                        />
                        <Typography
                          justifyContent="center"
                          color={colors.primary[100]}
                          sx={{
                            ml: "5px",
                            textDecoration: "none",
                            fontWeight: "bold",
                            justifyContent: "center",
                            marginTop: "1px",
                          }}
                        >
                          {t("scenario_report.buttons.export_tables")}
                        </Typography>
                      </Box>
                    </Grid>
                  )}

                  {/* <Grid item xs={12} sm={6} md={6} lg={3.5} xl={2}>
                    <Box
                      onClick={() => {
                        exportPDF();
                      }}
                      m="0 auto"
                      p="5px 7px 5px 5px"
                      display="flex"
                      justifyContent="center"
                      borderRadius="4px"
                      alignItems="center"
                      backgroundColor={colors.redAccent[600]}
                      sx={{
                        cursor: "pointer",
                        textDecoration: "none",
                      }}
                    >
                      <PictureAsPdfOutlinedIcon
                        sx={{
                          textDecoration: "none",
                          color: colors.primary[100],
                          justifyContent: "center",
                        }}
                      />
                      <Typography
                        justifyContent="center"
                        color={colors.primary[100]}
                        sx={{
                          ml: "5px",
                          textDecoration: "none",
                          fontWeight: "bold",
                          justifyContent: "center",
                          marginTop: "1px",
                        }}
                      >
                        {t("scenario_report.buttons.export_report")}
                      </Typography>
                    </Box>
                  </Grid> */}
                </Grid>
              </Grid>

              <Box component="main" maxWidth="100%">
                <Box display="flex" flexDirection="column" alignItems="center" mt="30px">
                  <Snackbar
                    open={snackbarOpen}
                    autoHideDuration={5000} // Auto-hide after 3 seconds
                    onClose={handleSnackbarClose}
                    anchorOrigin={{ vertical: "top", horizontal: "center" }} // Set position to top center
                  >
                    <Alert
                      onClose={handleSnackbarClose}
                      severity={
                        (selectedScenario === "cenarioHoterway" && cenarioRetorno?.id === idScenario) ||
                        (selectedScenario === "cenarioHoterway" && !cenarioRetorno)
                          ? "error"
                          : "success"
                      }
                      variant="filled"
                      sx={{ width: "100%", color: "white", fontWeight: "bold", fontSize: "18px" }}
                    >
                      {selectedScenario === "cenarioBase" && "Cenário Base atribuído com sucesso!"}
                      {selectedScenario === "cenarioRetorno" && "Cenário Retorno atribuído com sucesso!"}
                      {selectedScenario === "cenarioHoterway" &&
                        cenarioRetorno?.id !== idScenario &&
                        cenarioRetorno &&
                        "Cenário Hoterway atribuído com sucesso!"}
                      {selectedScenario === "cenarioHoterway" &&
                        cenarioRetorno?.id === idScenario &&
                        "Não pode substituir um cenário Retorno por um cenário Hoterway."}
                      {selectedScenario === "cenarioHoterway" && !cenarioRetorno && "Tem de atribuir primeiro um cenário de retorno!"}
                    </Alert>
                  </Snackbar>
                  <Snackbar
                    open={snackbarOpenExcel}
                    autoHideDuration={5000} // Auto-hide after 5 seconds
                    onClose={handleSnackbarCloseExcel}
                    anchorOrigin={{ vertical: "top", horizontal: "center" }} // Set position to top center
                  >
                    <Alert
                      onClose={handleSnackbarCloseExcel}
                      severity="error"
                      variant="filled"
                      sx={{ width: "100%", color: "white", fontWeight: "bold", fontSize: "18px" }}
                    >
                      Só pode exportar as tabelas do Cenário Base!
                    </Alert>
                  </Snackbar>
                  <Snackbar
                    open={snackbarOpenDelete}
                    autoHideDuration={5000} // Auto-hide after 5 seconds
                    onClose={handleSnackbarCloseDelete}
                    anchorOrigin={{ vertical: "top", horizontal: "center" }} // Set position to top center
                  >
                    <Alert
                      onClose={handleSnackbarCloseDelete}
                      severity="success"
                      variant="filled"
                      sx={{ width: "100%", color: "white", fontWeight: "bold", fontSize: "18px" }}
                    >
                      Cenário atribuídos apagados com sucesso!
                    </Alert>
                  </Snackbar>
                  <Dialog open={open} onClose={handleClose}>
                    <DialogTitle>Atribua o cenário que deseja considerar no relatório</DialogTitle>
                    <DialogContent>
                      <FormControl fullWidth>
                        <Select value={selectedScenario} onChange={handleScenarioChange}>
                          <MenuItem value="cenarioBase">Cenário Base</MenuItem>
                          <MenuItem value="cenarioRetorno">Cenário Retorno</MenuItem>
                          <MenuItem value="cenarioHoterway">Cenário Hoterway</MenuItem>
                        </Select>
                      </FormControl>
                    </DialogContent>
                    <DialogActions>
                      <Button onClick={handleClose}>Cancelar</Button>
                      <Button onClick={handleSave} variant="contained">
                        Atribuir
                      </Button>
                    </DialogActions>
                  </Dialog>
                  <Dialog
                    open={openDialog}
                    onClose={handleCloseDialog}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                  >
                    <DialogTitle id="alert-dialog-title">{"AVISO!"}</DialogTitle>
                    <DialogContent>
                      <DialogContentText id="alert-dialog-description">
                        Ainda possui cenários guardados da simulação {cenarioBase?.sim || cenarioRetorno?.sim || cenarioHoterway?.sim}.
                        Pretende apagar os cenários atribuídos e recomeçar, ou voltar para a simulação{" "}
                        {cenarioBase?.sim || cenarioRetorno?.sim || cenarioHoterway?.sim}?
                      </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                      <Button variant="contained" color="error" startIcon={<DeleteOutlinedIcon />} onClick={handleCloseDialog}>
                        Apagar atribuições anteriores
                      </Button>
                      <Button
                        variant="contained"
                        endIcon={<SendOutlinedIcon />}
                        onClick={() => {
                          const sim = cenarioBase?.sim || cenarioRetorno?.sim || cenarioHoterway?.sim;
                          if (sim) {
                            navigate(`/simulations/${sim}`);
                          }
                        }}
                        autoFocus
                      >
                        Ir para Simulação {cenarioBase?.sim || cenarioRetorno?.sim || cenarioHoterway?.sim}
                      </Button>
                    </DialogActions>
                  </Dialog>
                  <Fade
                    in={generalError}
                    timeout={{ enter: 500, exit: 500 }}
                    unmountOnExit={true}
                    addEndListener={() => {
                      window.scrollTo(0, 0);
                      setTimeout(() => {
                        setGeneralError(false);
                      }, 3000);
                    }}
                  >
                    <Alert variant="outlined" sx={{ fontSize: "16px" }} severity="error">
                      <AlertTitle sx={{ fontSize: "16px" }}>{t("scenario_report.alert_fields.general_error.title")}</AlertTitle>
                      {t("scenario_report.alert_fields.general_error.description")}
                    </Alert>
                  </Fade>

                  <Fade
                    in={sessionError}
                    timeout={{ enter: 500, exit: 500 }}
                    unmountOnExit={true}
                    addEndListener={() => {
                      window.scrollTo(0, 0);
                      setTimeout(() => {
                        setSessionError(false);
                        navigate("/login");
                        logout();
                      }, 3000);
                    }}
                  >
                    <Alert variant="outlined" sx={{ fontSize: "16px" }} severity="error">
                      <AlertTitle sx={{ fontSize: "16px" }}>{t("scenario_report.alert_fields.session_error.title")}</AlertTitle>
                      {t("scenario_report.alert_fields.session_error.description")}
                    </Alert>
                  </Fade>

                  {project && project !== undefined && isLoading === false ? (
                    <>
                      <div id="cenas">
                        <Grid container spacing={4} justifyContent="left">
                          <Grid item xs={12}>
                            <Typography component="h1" variant="h3">
                              {t("scenario_report.title2")}
                            </Typography>
                          </Grid>
                          <Grid item xs={12} sm={12} md={9} lg={9} xl={9}>
                            {project && project.editor_image && (
                              <>
                                {isStringImage(project.editor_image.toString()) ? (
                                  <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                    <Box
                                      component="img"
                                      sx={{
                                        maxWidth: {
                                          xs: "55%",
                                        },
                                        maxHeight: {
                                          xs: "100%",
                                        },
                                      }}
                                      alt={t("scenario_report.image_error")}
                                      src={project.editor_image}
                                    />
                                  </Grid>
                                ) : (
                                  <div className="canvasContainerContainer">
                                    <div className="canvasContainer" ref={editorContainerRef}>
                                      <KonvaEditor
                                        floors_count={project?.floors_count}
                                        floorsNames={project?.floors_names}
                                        sourceTitle={project?.water_heating_system ?? "T0"}
                                        width={konvaWidth}
                                        refEditor={editorRef}
                                        pePcLayout={project?.pepc_layout}
                                        loadFromSavedObject={project?.editor_image}
                                        pePcConnections={project.pepc_connections}
                                        canEdit={false}
                                        disabled
                                        defaults={defaults}
                                        pePcConnectionsAdjustedDiameter={transformDictionary(project.pepc_connections)}
                                        pePcConnectionsRe={project.pepc_connections}
                                        project={project}
                                        setProject={(newProject) => {}}
                                        setPePcConnectionsRe={(pepcConnectionsRe) => {}}
                                        t={t}
                                        setPePcConnectionsAdjustedDiameter={(pepcConnectionsAdjustedDiameter) => {}}
                                        setPePcConnections={(pepcConnections) => {}}
                                        currentArrows={currentArrows}
                                        setCurrentArrows={setCurrentArrows}
                                      />
                                    </div>
                                  </div>
                                )}
                              </>
                            )}
                          </Grid>
                          <Grid item xs={3} sm={3} md={12} lg={4} xl={3} mt="15px">
                            <Grid container spacing={5} alignItems="center">
                              <Grid
                                container
                                spacing={2}
                                xs={12}
                                sm={12}
                                md={5}
                                lg={12}
                                xl={12}
                                paddingLeft={2}
                                marginLeft={4}
                                marginTop={1}
                                marginBottom={0}
                              >
                                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                  <Card style={{ backgroundColor: colors.blueAccent[600] }}>
                                    <CardHeader
                                      style={{ backgroundColor: colors.blueAccent[700] }}
                                      sx={{ fontSize: 18, justifyContent: "center", alignItems: "baseline", textAlign: "center" }}
                                      component={Typography}
                                      title={t("scenario_report.cards.1.title")}
                                      titleTypographyProps={{ variant: "h3" }}
                                    />
                                    <CardContent>
                                      <Box
                                        sx={{
                                          display: "flex",
                                          justifyContent: "center",
                                          alignItems: "baseline",
                                          mb: "-15px",
                                          mt: "-9px",
                                        }}
                                      >
                                        <Typography component="h2" variant="h3" color="text.primary">
                                          {waitingTime}
                                        </Typography>
                                        <Typography component="h2" variant="h3" color="text.primary">
                                          &nbsp;
                                        </Typography>
                                        <Typography variant="h6" color="text.secondary" sx={{ fontWeight: "bold" }}>
                                          (MM:SS)
                                        </Typography>
                                      </Box>
                                    </CardContent>
                                  </Card>
                                </Grid>
                              </Grid>

                              {project?.building_type === 0 && (
                                <Grid
                                  container
                                  spacing={2}
                                  xs={12}
                                  sm={12}
                                  md={5}
                                  lg={12}
                                  xl={12}
                                  paddingLeft={2}
                                  marginLeft={4}
                                  marginTop={1}
                                  marginBottom={0}
                                >
                                  <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                    <Card style={{ backgroundColor: colors.blueAccent[600] }}>
                                      <CardHeader
                                        style={{ backgroundColor: colors.blueAccent[700] }}
                                        sx={{ fontSize: 18, justifyContent: "center", alignItems: "baseline", textAlign: "center" }}
                                        component={Typography}
                                        title={t("scenario_report.cards.2.title")}
                                        titleTypographyProps={{ variant: "h3" }}
                                      />
                                      <CardContent>
                                        <Box
                                          sx={{
                                            display: "flex",
                                            justifyContent: "center",
                                            alignItems: "baseline",
                                            mb: "-15px",
                                            mt: "-9px",
                                          }}
                                        >
                                          <Typography component="h2" variant="h3" color="text.primary">
                                            {water_waste.toFixed(1) + ""}
                                          </Typography>
                                          <Typography component="h2" variant="h3" color="text.primary">
                                            &nbsp;
                                          </Typography>
                                          <Typography variant="h6" color="text.secondary" sx={{ fontWeight: "bold" }}>
                                            {"m³/" + t("scenario_report.cards.2.year")}
                                          </Typography>
                                        </Box>

                                        <br />

                                        <Typography
                                          component="h6"
                                          variant="body2"
                                          color="text.secondary"
                                          textAlign="center"
                                          sx={{
                                            display: "flex",
                                            justifyContent: "center",
                                            alignItems: "baseline",
                                            mb: "-15px",
                                          }}
                                        >
                                          {""}
                                          {(
                                            (water_waste /
                                              (water_waste + (project.water_consumption * 3 * project.total_habitants * 365) / 1000)) *
                                            100
                                          ).toFixed(1)}{" "}
                                          {t("scenario_report.cards.2.spent") +
                                            " / " +
                                            t("scenario_report.cards.2.cost") +
                                            " " +
                                            `${water_cost}` +
                                            "€/" +
                                            t("scenario_report.cards.2.year")}
                                        </Typography>
                                      </CardContent>
                                    </Card>
                                  </Grid>
                                </Grid>
                              )}

                              <Grid
                                container
                                spacing={2}
                                xs={12}
                                sm={12}
                                md={5}
                                lg={12}
                                xl={12}
                                paddingLeft={2}
                                marginLeft={4}
                                marginTop={1}
                                marginBottom={0}
                              >
                                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                  <Card style={{ backgroundColor: colors.blueAccent[600] }}>
                                    <CardHeader
                                      style={{ backgroundColor: colors.blueAccent[700] }}
                                      sx={{ fontSize: 18, justifyContent: "center", alignItems: "baseline", textAlign: "center" }}
                                      component={Typography}
                                      title={t("scenario_report.cards.3.title")}
                                      titleTypographyProps={{ variant: "h3" }}
                                    />
                                    <CardContent>
                                      <Box
                                        sx={{
                                          display: "flex",
                                          justifyContent: "center",
                                          alignItems: "baseline",
                                          mb: "-15px",
                                          mt: "-9px",
                                        }}
                                      >
                                        <Typography component="h2" variant="h3" color="text.primary">
                                          {energy_waste}
                                        </Typography>
                                        <Typography component="h2" variant="h3" color="text.primary">
                                          &nbsp;
                                        </Typography>
                                        <Typography variant="h6" color="text.secondary" sx={{ fontWeight: "bold" }}>
                                          {t("scenario_report.cards.3.mwh_year")}
                                        </Typography>
                                      </Box>
                                      <br />

                                      <Typography component="h6" variant="body2" color="text.secondary" textAlign="center">
                                        {t("scenario_report.cards.3.cost") +
                                          " " +
                                          `${formattedMoneyEnergy}` +
                                          " " +
                                          t("scenario_report.cards.3.cost_variable")}
                                      </Typography>
                                      <Typography component="h6" variant="body2" color="text.secondary" textAlign="center">
                                        {t("scenario_report.cards.3.perdas_tubagem") +
                                          " " +
                                          `${perda_termica_parcial}` +
                                          " " +
                                          t("scenario_report.cards.3.mwh_year") +
                                          " (" +
                                          `${formattedPerdaTermicaMoney}` +
                                          "€)"}
                                      </Typography>
                                      <Typography
                                        component="h6"
                                        variant="body2"
                                        color="text.secondary"
                                        textAlign="center"
                                        sx={{
                                          display: "flex",
                                          justifyContent: "center",
                                          alignItems: "baseline",
                                          mb: "-15px",
                                        }}
                                      >
                                        {t("scenario_report.cards.3.consumo_bomba") +
                                          " " +
                                          `${consumo_bomba}` +
                                          " " +
                                          t("scenario_report.cards.3.mwh_year") +
                                          " (" +
                                          `${formattedConsumoBombaMoney}` +
                                          "€)"}
                                      </Typography>
                                    </CardContent>
                                  </Card>
                                </Grid>
                              </Grid>

                              {project?.building_type === 0 && (
                                <Grid
                                  container
                                  spacing={2}
                                  xs={12}
                                  sm={12}
                                  md={12}
                                  lg={12}
                                  xl={12}
                                  paddingLeft={2}
                                  marginLeft={4}
                                  marginTop={1}
                                  marginBottom={0}
                                >
                                  <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                    <Card style={{ backgroundColor: colors.blueAccent[600] }}>
                                      <CardHeader
                                        style={{ backgroundColor: colors.blueAccent[700] }}
                                        sx={{ fontSize: 18, justifyContent: "center", alignItems: "baseline", textAlign: "center" }}
                                        component={Typography}
                                        title={t("scenario_report.cards.4.title")}
                                        titleTypographyProps={{ variant: "h3" }}
                                      />
                                      <CardContent>
                                        <Grid container spacing={2}>
                                          <Grid item xs={12} sm={12} md={12} lg={12} xl={6}>
                                            <Grid item xs={12}>
                                              <Card style={{ backgroundColor: getBackgroundColor(project?.certificate_class) }}>
                                                <CardContent>
                                                  <Box
                                                    sx={{
                                                      display: "flex",
                                                      justifyContent: "center",
                                                      alignItems: "baseline",
                                                      mt: "10px",
                                                    }}
                                                  >
                                                    <Typography component="h2" variant="h2" color="text.primary">
                                                      {getCertificateText(project?.certificate_class)}
                                                    </Typography>
                                                  </Box>
                                                </CardContent>
                                              </Card>
                                            </Grid>
                                            <Grid item xs={12}>
                                              <Typography
                                                component="h6"
                                                variant="body2"
                                                color="text.secondary"
                                                textAlign="center"
                                                sx={{
                                                  display: "flex",
                                                  justifyContent: "center",
                                                  alignItems: "baseline",
                                                  mb: "-15px",
                                                  mt: "5px",
                                                }}
                                              >
                                                {t("scenario_report.cards.4.pre_certificate")}
                                              </Typography>
                                            </Grid>
                                          </Grid>
                                          <Grid item xs={12} sm={12} md={12} lg={12} xl={6}>
                                            <Grid item xs={12}>
                                              <Card
                                                style={{
                                                  backgroundColor: getBackgroundColor(
                                                    calculateFinalCertificate(project?.certificate_class)
                                                  ),
                                                }}
                                              >
                                                <CardContent>
                                                  <Box
                                                    sx={{
                                                      display: "flex",
                                                      justifyContent: "center",
                                                      alignItems: "baseline",
                                                      mt: "10px",
                                                    }}
                                                  >
                                                    <Typography component="h2" variant="h2" color="text.primary">
                                                      {getCertificateText(calculateFinalCertificate(project?.certificate_class))}*
                                                    </Typography>
                                                  </Box>
                                                </CardContent>
                                              </Card>
                                            </Grid>
                                            <Grid item xs={12}>
                                              <Typography
                                                component="h6"
                                                variant="body2"
                                                color="text.secondary"
                                                textAlign="center"
                                                sx={{
                                                  display: "flex",
                                                  justifyContent: "center",
                                                  alignItems: "baseline",
                                                  mb: "-15px",
                                                  mt: "5px",
                                                }}
                                              >
                                                {t("scenario_report.cards.4.certificate")}
                                              </Typography>
                                            </Grid>
                                          </Grid>
                                        </Grid>
                                      </CardContent>
                                    </Card>
                                  </Grid>
                                </Grid>
                              )}
                            </Grid>
                          </Grid>
                        </Grid>

                        <Grid container spacing={4} mt="10px" justifyContent="left">
                          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                            <Grid item xs={12}>
                              <Typography component="h1" variant="h3" mb="20px">
                                {t("scenario_report.considerations.title")}
                              </Typography>
                            </Grid>

                            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                              <TextField
                                fullWidth
                                multiline
                                variant="filled"
                                type="text"
                                value={
                                  project.building_type === 0
                                    ? t("scenario_report.considerations.house_parts_count") +
                                      (project.house_parts_count === null ? "---" : project.house_parts_count) +
                                      t("scenario_report.considerations.pe_usage") +
                                      (project.pe_usage === null ? "---" : project.pe_usage) +
                                      t("scenario_report.considerations.total_habitants") +
                                      (project.total_habitants === null ? "---" : project.total_habitants) +
                                      t("scenario_report.considerations.price_thermal") +
                                      (project.price_thermal === null ? "---" : project.price_thermal + " €/kWh") +
                                      t("scenario_report.considerations.price_energy") +
                                      (project.price_energy === null ? "---" : project.price_energy + " €/kWh") +
                                      t("scenario_report.considerations.building_type.title") +
                                      (project.building_type === 0
                                        ? t("scenario_report.considerations.building_type.residential")
                                        : project.building_type === 1
                                        ? t("scenario_report.considerations.building_type.hotel")
                                        : "---") +
                                      t("scenario_report.considerations.tipo_tubo.title") +
                                      (project.tipo_tubo === null
                                        ? "---"
                                        : t(`scenario_report.considerations.tipo_tubo.${project.tipo_tubo}`)) +
                                      (numberRecirculation > 0 && numberRecirculation !== null && numberHoterway === null
                                        ? t("scenario_report.considerations.running_time.title") +
                                          (project.running_time === null
                                            ? "---"
                                            : project.running_time + t("scenario_report.considerations.running_time.variable"))
                                        : "") +
                                      (numberHoterway > 0 &&
                                      numberHoterway !== null &&
                                      numberRecirculation > 0 &&
                                      numberRecirculation !== null
                                        ? t("scenario_report.considerations.running_time_hoterway.title") +
                                          (project.running_time_hoterway === null
                                            ? "---"
                                            : project.running_time_hoterway +
                                              t("scenario_report.considerations.running_time_hoterway.variable"))
                                        : "") +
                                      t("scenario_report.considerations.volume_aqs") +
                                      (project.volume_aqs === null ? "---" : project.volume_aqs + " m3") +
                                      t("scenario_report.considerations.water_consumption.title") +
                                      (project.water_consumption === null
                                        ? "---"
                                        : project.water_consumption + t("scenario_report.considerations.water_consumption.variable")) +
                                      t("scenario_report.considerations.water_in_temp") +
                                      (project.water_in_temp === null ? "---" : project.water_in_temp + " ºC") +
                                      t("scenario_report.considerations.hot_water_temp") +
                                      (project.hot_water_temp === null ? "---" : project.hot_water_temp + " ºC") +
                                      t("scenario_report.considerations.average_power.title") +
                                      (project.average_power === null
                                        ? "---"
                                        : project.average_power + t("scenario_report.considerations.average_power.variable")) +
                                      t("scenario_report.considerations.water_price") +
                                      (project.water_price === null ? "---" : project.water_price + " €/m3") +
                                      t("scenario_report.considerations.insolation_width.title") +
                                      (project.insolation_width === 0
                                        ? t("scenario_report.considerations.insolation_width.0")
                                        : project.insolation_width === 1
                                        ? t("scenario_report.considerations.insolation_width.1")
                                        : project.insolation_width === 2
                                        ? t("scenario_report.considerations.insolation_width.2")
                                        : "---") +
                                      t("scenario_report.considerations.ccppt.title") +
                                      (project.ccppt === 1.15
                                        ? t("scenario_report.considerations.ccppt.1_15")
                                        : project.ccppt === 1.35
                                        ? t("scenario_report.considerations.ccppt.1_35")
                                        : project.ccppt === 1.65
                                        ? t("scenario_report.considerations.ccppt.1_65")
                                        : "---") +
                                      t("scenario_report.considerations.cct") +
                                      (project.cct === 1.68
                                        ? "20 mW/m.K"
                                        : project.cct === 1.36
                                        ? "25 mW/m.K"
                                        : project.cct === 1.15
                                        ? "30 mW/m.K"
                                        : project.cct === 1
                                        ? "35 mW/m.K"
                                        : project.cct === 0.89
                                        ? "40 mW/m.K"
                                        : project.cct === 0.8
                                        ? "45 mW/m.K"
                                        : "---") +
                                      t("scenario_report.considerations.active_consumption") +
                                      (project.active_consumption === null ? "---" : ": " + project.active_consumption + " MWh/ano") +
                                      t("scenario_report.considerations.building_area") +
                                      (project.building_area === null ? "---" : project.building_area + " m2") +
                                      t("scenario_report.considerations.energy_reference.title") +
                                      (expectedEnergy === 0
                                        ? "---"
                                        : expectedEnergy + t("scenario_report.considerations.energy_reference.variable")) +
                                      t("scenario_report.considerations.certificate_class") +
                                      (project.certificate_class === 0
                                        ? "A+"
                                        : project.certificate_class === 1
                                        ? "A"
                                        : project.certificate_class === 2
                                        ? "B"
                                        : project.certificate_class === 3
                                        ? "B-"
                                        : project.certificate_class === 4
                                        ? "C"
                                        : project.certificate_class === 5
                                        ? "D"
                                        : project.certificate_class === 6
                                        ? "E"
                                        : project.certificate_class === 7
                                        ? "F"
                                        : "---") +
                                      (project.running_time !== 24 ? t("scenario_report.considerations.warning") : "") +
                                      t("scenario_report.considerations.email_comercial") +
                                      (project.email_comercial === null ? "---" : project.email_comercial) +
                                      t("scenario_report.considerations.date") +
                                      (project.date === null ? "---" : project.date) +
                                      t("scenario_report.considerations.client_name") +
                                      (project.client_name === null ? "---" : project.client_name) +
                                      t("scenario_report.considerations.nif") +
                                      (project.nif === null ? "---" : project.nif) +
                                      t("scenario_report.considerations.address") +
                                      (project.address === null ? "---" : project.address) +
                                      t("scenario_report.considerations.postal_code") +
                                      (project.postal_code === null ? "---" : project.postal_code) +
                                      t("scenario_report.considerations.email") +
                                      (project.email === null ? "---" : project.email) +
                                      t("scenario_report.considerations.phone") +
                                      (project.phone === null ? "---" : project.phone)
                                    : t("scenario_report.considerations.house_parts_count") +
                                      (project.house_parts_count === null ? "---" : project.house_parts_count) +
                                      t("scenario_report.considerations.price_thermal") +
                                      (project.price_thermal === null ? "---" : project.price_thermal + " €/kWh") +
                                      t("scenario_report.considerations.price_energy") +
                                      (project.price_energy === null ? "---" : project.price_energy + " €/kWh") +
                                      t("scenario_report.considerations.building_type.title") +
                                      (project.building_type === 0
                                        ? t("scenario_report.considerations.building_type.residential")
                                        : project.building_type === 1
                                        ? t("scenario_report.considerations.building_type.hotel")
                                        : "---") +
                                      t("scenario_report.considerations.tipo_tubo.title") +
                                      (project.tipo_tubo === null
                                        ? "---"
                                        : t(`scenario_report.considerations.tipo_tubo.${project.tipo_tubo}`)) +
                                      (numberRecirculation > 0 && numberRecirculation !== null && numberHoterway === null
                                        ? t("scenario_report.considerations.running_time.title") +
                                          (project.running_time === null
                                            ? "---"
                                            : project.running_time + t("scenario_report.considerations.running_time.variable"))
                                        : "") +
                                      (numberHoterway > 0 &&
                                      numberHoterway !== null &&
                                      numberRecirculation > 0 &&
                                      numberRecirculation !== null
                                        ? t("scenario_report.considerations.running_time_hoterway.title") +
                                          (project.running_time_hoterway === null
                                            ? "---"
                                            : project.running_time_hoterway +
                                              t("scenario_report.considerations.running_time_hoterway.variable"))
                                        : "") +
                                      t("scenario_report.considerations.volume_aqs") +
                                      (project.volume_aqs === null ? "---" : project.volume_aqs + " m3") +
                                      t("scenario_report.considerations.water_consumption.title") +
                                      (project.water_consumption === null
                                        ? "---"
                                        : project.water_consumption + t("scenario_report.considerations.water_consumption.variable")) +
                                      t("scenario_report.considerations.water_in_temp") +
                                      (project.water_in_temp === null ? "---" : project.water_in_temp + " ºC") +
                                      t("scenario_report.considerations.hot_water_temp") +
                                      (project.hot_water_temp === null ? "---" : project.hot_water_temp + " ºC") +
                                      t("scenario_report.considerations.average_power.title") +
                                      (project.average_power === null
                                        ? "---"
                                        : project.average_power + t("scenario_report.considerations.average_power.variable")) +
                                      t("scenario_report.considerations.insolation_width.title") +
                                      (project.insolation_width === 0
                                        ? t("scenario_report.considerations.insolation_width.0")
                                        : project.insolation_width === 1
                                        ? t("scenario_report.considerations.insolation_width.1")
                                        : project.insolation_width === 2
                                        ? t("scenario_report.considerations.insolation_width.2")
                                        : "---") +
                                      t("scenario_report.considerations.ccppt.title") +
                                      (project.ccppt === 1.15
                                        ? t("scenario_report.considerations.ccppt.1_15")
                                        : project.ccppt === 1.35
                                        ? t("scenario_report.considerations.ccppt.1_35")
                                        : project.ccppt === 1.65
                                        ? t("scenario_report.considerations.ccppt.1_65")
                                        : "---") +
                                      t("scenario_report.considerations.cct") +
                                      (project.cct === 1.68
                                        ? "20 mW/m.K"
                                        : project.cct === 1.36
                                        ? "25 mW/m.K"
                                        : project.cct === 1.15
                                        ? "30 mW/m.K"
                                        : project.cct === 1
                                        ? "35 mW/m.K"
                                        : project.cct === 0.89
                                        ? "40 mW/m.K"
                                        : project.cct === 0.8
                                        ? "45 mW/m.K"
                                        : "---") +
                                      t("scenario_report.considerations.active_consumption") +
                                      (project.active_consumption === null ? "---" : ": " + project.active_consumption + " MWh/ano") +
                                      (project.running_time !== 24 ? t("scenario_report.considerations.warning") : "") +
                                      t("scenario_report.considerations.email_comercial") +
                                      (project.email_comercial === null ? "---" : project.email_comercial) +
                                      t("scenario_report.considerations.date") +
                                      (project.date === null ? "---" : project.date) +
                                      t("scenario_report.considerations.client_name") +
                                      (project.client_name === null ? "---" : project.client_name) +
                                      t("scenario_report.considerations.nif") +
                                      (project.nif === null ? "---" : project.nif) +
                                      t("scenario_report.considerations.address") +
                                      (project.address === null ? "---" : project.address) +
                                      t("scenario_report.considerations.postal_code") +
                                      (project.postal_code === null ? "---" : project.postal_code) +
                                      t("scenario_report.considerations.email") +
                                      (project.email === null ? "---" : project.email) +
                                      t("scenario_report.considerations.phone") +
                                      (project.phone === null ? "---" : project.phone)
                                }
                                name="email_comercial"
                                inputProps={{ style: { fontSize: 16, paddingBottom: "8px" } }}
                                sx={{
                                  "& .MuiInputBase-input.Mui-disabled": {
                                    WebkitTextFillColor: "#FFFFFF",
                                  },
                                  "& .MuiInputLabel-standard.Mui-disabled": {
                                    WebkitTextFillColor: "#FFFFFF",
                                  },
                                  "& .MuiInputLabel-filled.Mui-disabled": {
                                    WebkitTextFillColor: "#FFFFFF",
                                  },
                                  "& .MuiFilledInput-root": {
                                    background: "#535050",
                                  },
                                }}
                                disabled
                              />
                            </Grid>
                          </Grid>

                          <Grid item xs={12} sm={12} md={12} lg={12} xl={12} marginBottom="-25px">
                            <Grid item xs={12} marginBottom="-25px">
                              <Grid item xs={12}>
                                <Typography component="h1" variant="h3" mb="20px">
                                  {t("scenario_report.notes")}
                                </Typography>
                              </Grid>
                              <TextField
                                fullWidth
                                multiline
                                variant="filled"
                                type="text"
                                value={
                                  (project.obs === null || project.obs === undefined || project.obs === "" ? "" : project.obs + " || ") +
                                  (project.notes === null || project.notes === undefined ? "" : project.notes)
                                }
                                name="notes"
                                inputProps={{ style: { fontSize: 16, paddingBottom: "8px" } }}
                                sx={{
                                  "& .MuiInputBase-input.Mui-disabled": {
                                    WebkitTextFillColor: "#FFFFFF",
                                  },
                                  "& .MuiInputLabel-standard.Mui-disabled": {
                                    WebkitTextFillColor: "#FFFFFF",
                                  },
                                  "& .MuiInputLabel-filled.Mui-disabled": {
                                    WebkitTextFillColor: "#FFFFFF",
                                  },
                                  "& .MuiFilledInput-root": {
                                    background: "#535050",
                                  },
                                }}
                                disabled
                              />
                              {project?.building_type === 0 && (
                                <Grid item xs={12} mt={"30px"}>
                                  <Typography component="h4" variant="h6" mb="20px">
                                    {t("scenario_report.building_note")}
                                  </Typography>
                                </Grid>
                              )}
                            </Grid>
                          </Grid>
                        </Grid>
                      </div>

                      <Grid container spacing={3} mt="10px" justifyContent="left">
                        <Grid item xs={12}>
                          <Typography component="h1" variant="h3" mt="25px">
                            {t("scenario_report.title3")}
                          </Typography>
                        </Grid>
                        <Grid item xs={12} sm={12} md={6} lg={6} xl={3}>
                          {Object.keys(project.pepc_connections).length !== 0 && (
                            <div className="reportMatrix" id="table_3">
                              <Styles>
                                <MatrixTable columns={connections_table_columns} data={connections_data} />
                              </Styles>
                            </div>
                          )}
                        </Grid>

                        <Grid item xs={12} sm={12} md={6} lg={6} xl={3}>
                          {Object.keys(project.pepc_connections).length !== 0 && (
                            <div className="reportMatrix" id="table_4">
                              <Styles>
                                <MatrixTable columns={divs_table_columns} data={divs_data} />
                              </Styles>
                            </div>
                          )}
                        </Grid>

                        <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                          {Object.keys(project.pepc_connections).length !== 0 && (
                            <div className="reportMatrix" id="table_5">
                              <Styles>
                                <MatrixTable columns={connections_table_columns2} data={connections_data} />
                              </Styles>
                            </div>
                          )}
                        </Grid>
                      </Grid>
                    </>
                  ) : (
                    // COLOCAR {window.location.reload()} caso dê asneira
                    <></>
                  )}
                </Box>
              </Box>
            </Box>
          </div>
        </>
      )}
    </>
  );
}

export default ScenarioReport;
